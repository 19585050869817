import { InfoCircleOutlined, RobotOutlined } from "@ant-design/icons";
import { Descriptions, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Section } from "@app/components/ui/Section";
import { CandidateDef } from "@app/types/candidate.types";

export const AISummary = ({
  summary,
  summaryLabel,
  limitConsTo = Infinity,
}: {
  summary: CandidateDef["AIDescriptionSummary"];
  summaryLabel: string;
  limitConsTo?: number;
}) => {
  const { t } = useTranslation();

  if (!summary) {
    return null;
  }

  return (
    <Section
      title={
        <>
          <Tooltip title={t("ai-summary-tooltip")}>
            <span>
              {t("ai-summary")}
              <InfoCircleOutlined style={{ marginLeft: 8 }} />
            </span>
          </Tooltip>
        </>
      }
      Icon={RobotOutlined}
    >
      <Descriptions
        bordered
        size="small"
        column={1}
        items={[
          {
            label: summaryLabel,
            children: (
              <div>
                {summary.prime_candidate_score_reason && (
                  <div>{summary.prime_candidate_score_reason}</div>
                )}
              </div>
            ),
          },
          ...(summary["pro-arguments"] && summary["pro-arguments"].length > 0
            ? [
                {
                  label: t("ai-summary-pro"),
                  children: (
                    <ul
                      style={{
                        margin: 0,
                        paddingLeft: 16,
                      }}
                    >
                      {summary["pro-arguments"]?.map((argument, index) => (
                        <li key={index}>{argument}</li>
                      ))}
                    </ul>
                  ),
                },
                {
                  label: t("ai-summary-con"),
                  children: (
                    <ul
                      style={{
                        margin: 0,
                        paddingLeft: 16,
                      }}
                    >
                      {summary["con-arguments"]?.slice(0, limitConsTo).map((argument, index) => (
                        <li key={index}>{argument}</li>
                      ))}
                    </ul>
                  ),
                },
              ]
            : []),
        ]}
      />
    </Section>
  );
};
