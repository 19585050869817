import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Descriptions, Flex, Row, Spin, Tag, Tooltip, Typography } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuestionAnswer } from "@app/api/job-answers.api";
import { useJobQuestions } from "@app/api/job-questions.api";
import { isAnswerMatchingKnockoutQuestion } from "@app/helpers/job-questions.helper";
import { JobAnswerDef } from "@app/types/job-answers.types";
import {
  EJobQuestionAnswerType,
  EJobQuestionType,
  JobQuestionDef,
} from "@app/types/job-questions.types";

export const ScreeningQuestions = ({
  workerProfileId,
  jobAdId,
  showQuestionType,
  showQuestionValidation,
  onQuestionLoaded,
  onEdit,
}: {
  workerProfileId: string;
  jobAdId: string;
  showQuestionType?: boolean;
  showQuestionValidation?: boolean;
  onQuestionLoaded?: (question: JobQuestionDef, answerIsWrong: boolean) => void;
  onEdit?: (question: JobQuestionDef) => void;
}) => {
  const { t, i18n } = useTranslation();

  const questionsQuery = useJobQuestions({
    lang: i18n.language,
    jobAdId,
  });

  return (
    <Flex vertical gap="small">
      <Flex gap="small" align="center">
        <QuestionCircleOutlined style={{ fontSize: 20 }} />
        <Typography.Text strong>
          {t("Screening Questions")}{" "}
          {questionsQuery.data && (
            <Typography.Text type="secondary">({questionsQuery.data.length})</Typography.Text>
          )}
        </Typography.Text>
      </Flex>
      {questionsQuery.data?.length !== 0 && (
        <Descriptions
          bordered
          size="small"
          column={1}
          layout="vertical"
          items={questionsQuery.data?.map((question) => ({
            key: question.id,
            label: (
              <Flex wrap gap="small" align="center">
                {question.title}
                {onEdit && (
                  <Button size="small" icon={<EditOutlined />} onClick={() => onEdit(question)} />
                )}
                {showQuestionType && (
                  <Tag
                    style={{
                      marginLeft: "auto",
                      marginRight: 0,
                      textTransform: "capitalize",
                    }}
                  >
                    {question.answerType === EJobQuestionAnswerType.OPTION && (
                      <>{question.answerChoice}-</>
                    )}
                    {question.answerType}
                  </Tag>
                )}
                {question.type !== EJobQuestionType.OPTIONAL && (
                  <Tag
                    color={
                      showQuestionValidation && question.type === EJobQuestionType.KNOCKOUT
                        ? "red"
                        : undefined
                    }
                    style={{
                      marginLeft: showQuestionType ? undefined : "auto",
                      marginRight: 0,
                    }}
                  >
                    {question.type === EJobQuestionType.KNOCKOUT
                      ? t("job-question-type-knockout")
                      : t("job-question-type-required")}
                  </Tag>
                )}
              </Flex>
            ),
            children: (
              <Answer
                showQuestionValidation={showQuestionValidation}
                question={question}
                answerType={question.answerType}
                workerProfileId={workerProfileId}
                onQuestionLoaded={onQuestionLoaded}
              />
            ),
          }))}
        />
      )}
    </Flex>
  );
};

const Answer = ({
  question,
  answerType,
  workerProfileId,
  showQuestionValidation,
  onQuestionLoaded,
}: {
  question: JobQuestionDef;
  answerType: string;
  workerProfileId: string;
  showQuestionValidation?: boolean;
  onQuestionLoaded?: (question: JobQuestionDef, answerIsWrong: boolean) => void;
}) => {
  const { t, i18n } = useTranslation();

  const answerQuery = useQuestionAnswer({
    lang: i18n.language,
    questionId: question.id,
    workerProfileId,
  });

  const getAnswerText = (answer: JobAnswerDef) => {
    if (answerType === EJobQuestionAnswerType.OPTION) {
      if (answer.options.length === 0) {
        return t("chat-no-answer");
      }
      return (
        <>
          {answer.options.map((option) => (
            <div key={option.id}> {option.text}</div>
          ))}
        </>
      );
    }
    return answer.answer;
  };

  const answer = answerQuery.data;

  const requiredNotAnswered =
    !answerQuery.isLoading && question.type === EJobQuestionType.REQUIRED && !answer;
  const knockoutFailed =
    !answerQuery.isLoading &&
    question.type === EJobQuestionType.KNOCKOUT &&
    (!answer || !isAnswerMatchingKnockoutQuestion(answer, question));

  const somethingWrong = requiredNotAnswered || knockoutFailed;

  useEffect(() => {
    onQuestionLoaded?.(question, somethingWrong);
  }, [somethingWrong]);

  if (answerQuery.isLoading) {
    return <Spin />;
  }

  return (
    <div>
      {showQuestionValidation && somethingWrong && (
        <div>
          {requiredNotAnswered ? (
            <Typography.Text type="danger">No answer for required question</Typography.Text>
          ) : (
            <Tooltip
              title={
                <div>
                  {t("knockout-expected")}:
                  {question.answerType === EJobQuestionAnswerType.NUMBER && (
                    <div>
                      {t(question.goodNumberAnswerType ?? "")} {question.goodNumberAnswerValue}
                    </div>
                  )}
                  {question.answerType === EJobQuestionAnswerType.OPTION && (
                    <div>
                      {question.options
                        .filter((option) => option.isGoodAnswer)
                        .map((option) => (
                          <div key={option.id}>{option.text}</div>
                        ))}
                    </div>
                  )}
                </div>
              }
            >
              <Typography.Text type="danger">{t("knockout-unexpected")}</Typography.Text>
            </Tooltip>
          )}
        </div>
      )}
      {!answerQuery.data && (
        <Typography.Text type="secondary">{t("chat-no-answer")}</Typography.Text>
      )}
      {answerQuery.data && (
        <Row align="middle" justify="space-between" style={{ width: "100%" }}>
          {getAnswerText(answerQuery.data)}
          {showQuestionValidation && answerQuery.data.isFromAI && (
            <Tag color="purple" style={{ marginRight: 0 }}>
              {t("job-question-from-ai")}
            </Tag>
          )}
        </Row>
      )}
    </div>
  );
};
