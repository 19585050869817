import { useQuery } from "@tanstack/react-query";
import {
  CertificateDef,
  CreateCertificateDef,
  CreateHighlightDef,
  CreateTimelineDef,
  EditCertificateDef,
  EditTimelineDef,
  EditUserDef,
  EditUserStatsDef,
  GetUserRelevantJobsDef,
  GetUsersSAParamsDef,
  HighlightDef,
  HighlightWithPaginationDef,
  UserJobsWithPaginationDef,
  UserStatsResponseDef,
  UsersSAResponseDef,
  UsersSAWithPaginationDef,
} from "@app/features/super-admin/types/super-admin.user.types";
import { TimelineDef } from "@app/types/worker-profile.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

// USERS
export const getUsersSA = (params: GetUsersSAParamsDef) => {
  return superAdminApi.get<UsersSAWithPaginationDef>("/super_admin/users", {
    params,
  });
};
const getUserSA = ({ id, ...params }: { id: string; lang?: string }) => {
  return superAdminApi.get<UsersSAResponseDef>(`/super_admin/users/${id}`, {
    params,
  });
};
export const updateUserSA = (payload: Partial<EditUserDef>) => {
  return superAdminApi.put<UsersSAResponseDef>(`/worker_profiles/info/${payload.id}`, payload);
};

// TIMELINE
export const postUserTimelineSA = (workerProfileId: string, data: CreateTimelineDef) => {
  return superAdminApi.post<TimelineDef>("/crafthunts/timelines", {
    workerProfileId,
    ...data,
  });
};

export const editUserTimelineSA = (id: string, workerProfileId: string, data: EditTimelineDef) => {
  return superAdminApi.put<TimelineDef>(`/crafthunts/timelines/${id}`, {
    workerProfileId,
    ...data,
  });
};

export const deleteUserTimelineSA = (id: string, workerProfileId: string) => {
  return superAdminApi.delete(`/crafthunts/timelines/${id}`, {
    params: {
      workerProfileId,
    },
  });
};

// CERTIFICATES
export const postUserCertificateSA = (workerProfileId: string, data: CreateCertificateDef) => {
  return superAdminApi.post<CertificateDef>("/profile_certificates", {
    workerProfileId,
    ...data,
  });
};
export const editUserCertificateSA = (
  id: string,
  workerProfileId: string,
  data: EditCertificateDef
) => {
  return superAdminApi.put<CertificateDef>(`/profile_certificates/${id}`, {
    workerProfileId,
    ...data,
  });
};
export const deleteUserCertificateSA = (id: string, workerProfileId: string) => {
  return superAdminApi.delete(`/profile_certificates/${id}`, {
    params: {
      workerProfileId,
    },
  });
};
// HIGHLIGHTS
export const postUserHighlightSA = (workerProfileId: string, data: CreateHighlightDef) => {
  return superAdminApi.post<HighlightDef>("/crafthunts/images", {
    profileId: workerProfileId,
    ...data,
  });
};
export const deleteUserHighlightSA = (id: string, workerProfileId: string) => {
  return superAdminApi.delete(`/crafthunts/images/${id}`, {
    params: {
      workerProfileId,
    },
  });
};
// STATS
export const getUserStatsSA = (workerProfileId: string) => {
  return superAdminApi.get<UserStatsResponseDef>("/crafthunts/stats", {
    params: { workerProfileId },
  });
};
export const editUserStatsSA = (data: EditUserStatsDef) => {
  return superAdminApi.put<UserStatsResponseDef>(`/crafthunts/stats/${data.id}`, data);
};

export const userRelevantJobsQueryKey = [...queryKeysSA.lists(), "relevant-jobs"] as const;

const getUserRelevantJobsSA = (params: GetUserRelevantJobsDef) => {
  return superAdminApi.get<UserJobsWithPaginationDef>("/crafthunts/job_ads", {
    params,
  });
};

export const useUserRelevantJobsSA = (params: GetUserRelevantJobsDef) =>
  useQuery({
    queryKey: [...userRelevantJobsQueryKey, params] as const,
    queryFn: ({ queryKey }) => getUserRelevantJobsSA(queryKey[3]).then(({ data }) => data),
    refetchInterval: 60000,
  });

export const useUserSA = (params: { userId?: string | null; lang?: string }) =>
  useQuery({
    enabled: Boolean(params.userId),
    queryKey: [...queryKeysSA.all, "user", params] as const,
    queryFn: ({ queryKey }) =>
      getUserSA({
        id: queryKey[2].userId ?? "",
        lang: params.lang,
      }).then(({ data }) => data),
  });

export const useUsersSA = (params: GetUsersSAParamsDef) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "users", params] as const,
    queryFn: ({ queryKey }) => getUsersSA(queryKey[3]).then(({ data }) => data),
  });

export const useUserCertificatesSA = (params: { workerProfileId?: string }) =>
  useQuery({
    enabled: Boolean(params.workerProfileId),
    queryKey: [...queryKeysSA.all, "certificates", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<CertificateDef[]>("/profile_certificates", {
          params: queryKey[2],
        })
        .then(({ data }) => data),
  });

export const useUserStatsSA = (params: { workerProfileId?: string }) =>
  useQuery({
    enabled: Boolean(params.workerProfileId),
    queryKey: [...queryKeysSA.all, "stats", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<UserStatsResponseDef>("/crafthunts/stats", {
          params: queryKey[2],
        })
        .then(({ data }) => data),
  });

export const useUserHighlightsSA = (params: { workerProfileId?: string }) =>
  useQuery({
    enabled: Boolean(params.workerProfileId),
    queryKey: [...queryKeysSA.all, "highlights", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<HighlightWithPaginationDef>("/crafthunts/images", {
          params: queryKey[2],
        })
        .then(({ data }) => data),
  });

export const useUserTimelineSA = (params: { workerProfileId?: string }) =>
  useQuery({
    enabled: Boolean(params.workerProfileId),
    queryKey: [...queryKeysSA.all, "timeline", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<TimelineDef[]>("/crafthunts/timelines", {
          params: queryKey[2],
        })
        .then(({ data }) => data),
  });
