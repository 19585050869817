import { Collapse, Image, Modal, Space } from "antd";
import { useState } from "react";
import { MdDescription, MdImage, MdPictureAsPdf } from "react-icons/md";
import { downloadFile } from "@app/helpers/file.helper";
import { ProfileDocumentDef } from "@app/types/candidate.types";
import { DocumentItem } from "./components/DocumentItem";
import { GenerateCV } from "./components/GenerateCV/GenerateCV";

type DocumentsProps = {
  documents: ProfileDocumentDef[];
  candidateUserId: string;
};

export const Documents = ({ documents, candidateUserId }: DocumentsProps) => {
  const [pdfFile, setPdfFile] = useState<ProfileDocumentDef | null>(null);

  const docs = documents.map((media) => {
    const fileName = (media.filename ?? "").toLowerCase();
    const isImage =
      fileName.endsWith("jpeg") ||
      fileName.endsWith("jpg") ||
      fileName.endsWith("png") ||
      fileName.endsWith("gif") ||
      fileName.endsWith("webp");
    const isPdf = fileName.endsWith("pdf");

    return {
      ...media,
      mediaType: isImage ? ("image" as const) : isPdf ? ("pdf" as const) : ("other" as const),
      icon: isImage ? MdImage : isPdf ? MdPictureAsPdf : MdDescription,
      originalMedia: media,
    };
  });

  return (
    <div>
      <div>
        <GenerateCV candidateUserId={candidateUserId} />
      </div>

      {docs.length > 0 && (
        <Space size="middle" direction="vertical" style={{ width: "100%" }}>
          <Collapse
            size="small"
            ghost
            defaultActiveKey={docs
              .filter((media) => media.mediaType !== "other")
              .map((media) => media.id)}
            items={docs.map((media) => ({
              key: media.id,
              collapsible: media.mediaType === "other" ? "disabled" : undefined,
              label: (
                <DocumentItem
                  doc={media.originalMedia}
                  icon={media.icon}
                  onDownload={() => downloadFile(media.url, media.filename)}
                  onPreview={media.mediaType === "pdf" ? () => setPdfFile(media) : undefined}
                />
              ),
              styles: {
                header: {
                  paddingLeft: 0,
                  paddingRight: 0,
                  alignItems: "center",
                },
                body: {
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                },
              },
              children: {
                image: (
                  <Image
                    wrapperStyle={{
                      width: "100%",
                    }}
                    src={media.url}
                  />
                ),
                pdf: (
                  <object
                    width="100%"
                    style={{
                      height: "auto",
                      aspectRatio: "1/1.5",
                    }}
                    data={media.url}
                    type="application/pdf"
                  />
                ),
                other: null,
              }[media.mediaType],
            }))}
          />
        </Space>
      )}

      <Modal
        open={Boolean(pdfFile)}
        width="100%"
        footer={null}
        style={{ top: 20 }}
        onCancel={() => setPdfFile(null)}
        title={pdfFile?.filename}
      >
        {pdfFile && (
          <object
            width="100%"
            style={{
              height: "80dvh",
            }}
            data={pdfFile.url}
            type="application/pdf"
          />
        )}
      </Modal>
    </div>
  );
};
