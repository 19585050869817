import {
  ArrowRightOutlined,
  CloseCircleOutlined,
  FilePdfOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Alert, App, Button, Col, Flex, Row, Space, Spin, Splitter, Typography } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import { updateCandidate } from "@app/api/candidates.api";
import { Documents } from "@app/components/pages/Candidates/components/CandidateDrawer/components/Documents/Documents";
import { HeaderContact } from "@app/components/pages/Candidates/components/CandidateDrawer/components/HeaderContact/HeaderContact";
import { AISummary } from "@app/components/ui/AISummary";
import { Section } from "@app/components/ui/Section";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { queryKeysSA } from "@app/features/super-admin/api/queryKeys";
import { useCandidateSA } from "@app/features/super-admin/api/super-admin.candidate.api";
import { useJobsSA } from "@app/features/super-admin/api/super-admin.job.api";
import { ApprovalDecisionModal } from "@app/features/super-admin/components/SuperAdminStuff/components/ApplicationModal/ApprovalDecisionModal";
import {
  CompanyCommentForm,
  UserCommentForm,
} from "@app/features/super-admin/components/SuperAdminStuff/components/ApplicationModal/CommentForms";
import { useApplicationModal } from "@app/features/super-admin/components/SuperAdminStuff/components/ApplicationModal/useApplicationModal";
import type { ApprovalState } from "@app/features/super-admin/components/SuperAdminStuff/components/Approvals/Approvals";
import { useJobModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Jobs/JobModal/JobModal";
import { USER_MODAL_TAB_KEYS } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/UserModal";
import { useUserModalGlobal } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/UserModalGlobal";
import { CandidateSADef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { ECandidateApprovalAction, ECandidateStatus } from "@app/types/candidate.types";
import { EJobAdStatus } from "@app/types/job-ads.types";
import { JobQuestionDef } from "@app/types/job-questions.types";
import { Certificates } from "./Certificates";
import { EditAnswerModal } from "./EditAnswerModal";
import { JobCard } from "./JobCard";

export const InfoTab = ({ candidateId }: { candidateId?: string | null }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setJobId } = useJobModal();
  const { setApplicationId } = useApplicationModal();
  const { openUserModal } = useUserModalGlobal();
  const app = App.useApp();
  const [failedQuestionIds, setFailedQuestionIds] = useState<string[]>([]);
  const [questionToEdit, setQuestionToEdit] = useState<JobQuestionDef | null>(null);
  const [approvalState, setApprovalState] = useState<ApprovalState>({
    candidate: null as null | CandidateSADef,
    action: ECandidateApprovalAction.APPROVE,
  });
  const [sizes, setSizes] = useLocalStorage<(number | string)[]>("sa-approval-details-splitter", [
    "70%",
    "30%",
  ]);

  const onApprove = () => {
    queryClient.invalidateQueries({
      queryKey: queryKeysSA.all,
    });
  };

  const candidateQuery = useCandidateSA({
    id: candidateId,
  });

  const jobsQuery = useJobsSA(
    {
      status: EJobAdStatus.PUBLISHED,
      companyId: candidateQuery.data?.crafthuntCompany.id,
      limit: 100,
    },
    {
      enabled: Boolean(candidateQuery.data),
    }
  );

  const jobOptions = useMemo(() => {
    if (!jobsQuery.data) {
      return [];
    }
    const currentJobIdsSet = new Set(candidateQuery.data?.jobAds.map((job) => job.id));
    return jobsQuery.data.data
      .filter((job) => !currentJobIdsSet.has(job.id))
      .map((item) => ({
        value: item.id,
        searchLabel: `${item.title} ${item.address?.city ?? ""}`,
        label: (
          <span>
            {item.title} {item.address?.city ? `(${item.address.city})` : ""}
          </span>
        ),
      }));
  }, [jobsQuery?.data?.count, candidateQuery.data?.jobAds?.length]);

  const needsApproval = candidateQuery.data?.status === ECandidateStatus.NEEDS_APPROVAL;
  const deletedUser = Boolean(candidateQuery.data?.workerProfile.deletedAt);

  const saveCandidate = async (newJobId: string) => {
    if (!candidateQuery.data) {
      return;
    }
    await updateCandidate(candidateQuery.data.id, {
      jobAdsId: candidateQuery.data.jobAds.map((job) => job.id).concat(newJobId),
    });
    app.message.success(t("job-ad-assigned"));
    candidateQuery.refetch();
  };

  const unassignJob = async (jobId: string) => {
    if (!candidateQuery.data) {
      return;
    }
    await updateCandidate(candidateQuery.data.id, {
      jobAdsId: candidateQuery.data.jobAds.filter((job) => job.id !== jobId).map((job) => job.id),
    });
    candidateQuery.refetch();
  };

  const closeApprovalModal = () => {
    setApprovalState((state) => ({ ...state, candidate: null }));
  };

  const onQuestionLoaded = (question: JobQuestionDef, answerIsWrong: boolean) => {
    if (needsApproval) {
      setFailedQuestionIds((ids) =>
        answerIsWrong ? [...ids, question.id] : ids.filter((id) => id !== question.id)
      );
    }
  };

  if (candidateQuery.isLoading) {
    return (
      <Flex justify="center" align="center" style={{ height: "50dvh" }}>
        <Spin />
      </Flex>
    );
  }

  return (
    <>
      <Splitter
        onResize={setSizes}
        style={{
          gap: "16px",
        }}
      >
        <Splitter.Panel size={sizes?.[0]} min="20%" max="70%">
          {candidateQuery.isError && <Alert message="Sorry, something went wrong." type="error" />}
          {candidateQuery.data && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Flex wrap gap="small" justify="space-between" align="center">
                {needsApproval && (
                  <Flex gap="small">
                    <Button
                      size="large"
                      disabled={deletedUser}
                      onClick={() => {
                        setApprovalState({
                          candidate: candidateQuery.data,
                          action: ECandidateApprovalAction.APPROVE,
                        });
                      }}
                    >
                      ✅ Approve
                    </Button>
                    <Button
                      size="large"
                      disabled={deletedUser}
                      onClick={() => {
                        setApprovalState({
                          candidate: candidateQuery.data,
                          action: ECandidateApprovalAction.REJECT,
                        });
                      }}
                    >
                      ❌ Reject
                    </Button>
                  </Flex>
                )}

                <Flex gap="large" wrap>
                  {candidateQuery.data.workerProfile.phone && (
                    <HeaderContact
                      icon={PhoneOutlined}
                      href={`tel:${candidateQuery.data.workerProfile.phone}`}
                      label={candidateQuery.data.workerProfile.phone}
                    />
                  )}
                  {candidateQuery.data.workerProfile.email && (
                    <HeaderContact
                      icon={MailOutlined}
                      href={`mailto:${candidateQuery.data.workerProfile.email}`}
                      label={candidateQuery.data.workerProfile.email}
                    />
                  )}
                </Flex>
              </Flex>
              <div
                style={{
                  overflow: "hidden",
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <CompanyCommentForm companyId={candidateQuery.data.crafthuntCompany.id} />
                  </Col>
                  <Col span={12}>
                    <UserCommentForm userId={candidateQuery.data.workerProfile.id} />
                  </Col>
                </Row>
                <Flex vertical gap="middle">
                  <Certificates workerProfileId={candidateQuery.data.workerProfile.id} />
                  <AISummary
                    summaryLabel={t("ai-summary-prime")}
                    summary={candidateQuery.data.AIDescriptionSummary}
                  />
                </Flex>
              </div>
              <Space direction="vertical" style={{ width: "100%", marginTop: 8 }}>
                <Flex gap="small" align="center">
                  <UnorderedListOutlined style={{ fontSize: 20 }} />
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    Job applications{" "}
                    {candidateQuery.data && <>({candidateQuery.data.jobAds.length})</>}
                  </Typography.Title>
                  <Col span={8}>
                    <SelectAutocomplete
                      wrapOptions
                      dropdownStyle={{
                        maxWidth: "600px",
                      }}
                      placeholder={
                        <Flex align="center" gap="small">
                          <PlusCircleOutlined />
                          {t("assign-job-ad")}
                        </Flex>
                      }
                      options={jobOptions}
                      value={null}
                      onChange={saveCandidate}
                      filterOption={(input, option) =>
                        !input.trim() ||
                        (option as (typeof jobOptions)[number]).searchLabel
                          .toLowerCase()
                          .includes(input.trim().toLowerCase())
                      }
                    />
                  </Col>
                </Flex>
                {!candidateQuery.data.jobAds.length && (
                  <Alert showIcon type="info" message={t("no-ads-assigned")} />
                )}
                {candidateQuery.data.jobAds.map((jobAd) => (
                  <JobCard
                    key={jobAd.id}
                    jobId={jobAd.id}
                    title={jobAd.title}
                    candidate={candidateQuery.data}
                    onJobOpen={() => setJobId(jobAd.id)}
                    onUnassign={() => unassignJob(jobAd.id)}
                    onQuestionLoaded={onQuestionLoaded}
                    onQuestionEdit={setQuestionToEdit}
                  />
                ))}
              </Space>
            </Space>
          )}
        </Splitter.Panel>
        <Splitter.Panel size={sizes?.[1]} min="20%" max="70%">
          <Section title="Documents" Icon={FilePdfOutlined}>
            {candidateQuery.data?.workerProfile.medias && (
              <Documents
                candidateUserId={candidateQuery.data?.workerProfile.id}
                documents={candidateQuery.data.workerProfile.medias}
              />
            )}
          </Section>
        </Splitter.Panel>
      </Splitter>
      <ApprovalDecisionModal
        key={"approval" + approvalState.candidate?.id}
        {...approvalState}
        showApprovalWarning={failedQuestionIds.length > 0}
        onSuccess={() => {
          app.modal.confirm({
            title: "Do you want to recommend this candidate to another company?",
            okType: "primary",
            okButtonProps: {
              icon: <ArrowRightOutlined />,
            },
            okText: "Recommend",
            cancelText: "No thanks",
            cancelButtonProps: {
              icon: <CloseCircleOutlined />,
            },
            onOk() {
              if (approvalState.candidate) {
                openUserModal({
                  userId: approvalState.candidate.workerProfile.id,
                  tab: USER_MODAL_TAB_KEYS.RECOMMENDATIONS,
                });
              }
            },
          });

          if (approvalState.action === ECandidateApprovalAction.REJECT) {
            setApplicationId(null);
          }

          closeApprovalModal();
        }}
        onCancel={closeApprovalModal}
        onSettled={onApprove}
      />
      {candidateQuery.data && questionToEdit && (
        <EditAnswerModal
          workerProfileId={candidateQuery.data.workerProfile.id}
          question={questionToEdit}
          onClose={() => setQuestionToEdit(null)}
        />
      )}
    </>
  );
};
