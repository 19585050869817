import { Col, Row, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import { useWorkerProfile } from "@app/api/worker-profile.api";
import {
  ECraftsmanStatTypes,
  EStatUnits,
} from "@app/features/super-admin/types/super-admin.user.types";
import { convertLangForBackend, getLangName } from "@app/helpers/language.helper";
import { CandidateDef } from "@app/types/candidate.types";
import { ETagCategory } from "@app/types/tags.types";

type Props = {
  candidate: CandidateDef;
};

export const KeyData = ({ candidate }: Props) => {
  const { t, i18n } = useTranslation();

  const spokenLangs =
    candidate.workerProfile.stats.languages
      .filter(Boolean)
      .filter((langCode) => langCode !== "-")
      .map((langCode) => getLangName(i18n.language, langCode))
      .join(", ") || "";

  const workerProfileQuery = useWorkerProfile(
    {
      idOrSlug: candidate.workerProfile.slug || candidate.workerProfile.anonymousSlug,
      lang: convertLangForBackend(i18n.language),
    },
    {
      enabled: !candidate.workerProfile.deletedAt,
    }
  );

  const degreeCertificates =
    workerProfileQuery.data?.certificates?.filter(
      (certificate) => certificate.type?.category === ETagCategory.DEGREE
    ) ?? [];
  const drivingLicenses =
    workerProfileQuery.data?.certificates?.filter(
      (certificate) => certificate.type?.category === ETagCategory.DRIVING_LICENCE
    ) ?? [];

  const keyData = [
    {
      title: ECraftsmanStatTypes.SPOKEN_LANGUAGES_STAT,
      value: spokenLangs,
    },
    {
      title: "degree",
      value: degreeCertificates.map((c) => t(c.title)).join(", "),
    },
    {
      title: "driver-license",
      value: drivingLicenses.map((c) => t(c.title)).join(", "),
    },
    {
      title: ECraftsmanStatTypes.EMPLOYED_IN_CONSTRUCTION_STAT,
      value: candidate.workerProfile.stats.workExperience,
    },
    {
      title: ECraftsmanStatTypes.AVG_TIME_IN_COMPANY_STAT,
      value: candidate.workerProfile.stats.companyExperience,
    },
    {
      title: ECraftsmanStatTypes.WORKED_PROJ_STAT,
      value: candidate.workerProfile.stats.totalFinishedProject,
    },
    {
      title: ECraftsmanStatTypes.AGE_STAT,
      value: workerProfileQuery.data?.age,
      unit: EStatUnits.YEAR_UNIT,
    },
  ].filter((item) => item.value && item.value !== "0");

  return (
    <div
      style={{
        overflow: "hidden",
        marginTop: "16px",
      }}
    >
      <Row gutter={[16, 12]}>
        {keyData.map((item, index) => (
          <Col key={index} span={8}>
            <Statistic
              title={
                <span
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {t(item.title)}
                </span>
              }
              valueStyle={{
                fontSize: "16px",
              }}
              value={item.value}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};
