import { superAdminApi } from "./super-admin.api";

export const createScreeningAnswerSA = (params: {
  workerProfileId: string;
  jobQuestionId: string;
  answer?: string;
  optionIds?: string[];
}) => {
  return superAdminApi.post(`/super_admin/job_question_answers`, params);
};

export const updateScreeningAnswerSA = (params: {
  id: string;
  workerProfileId: string;
  answer?: string;
  optionIds?: string[];
}) => {
  return superAdminApi.put(`/super_admin/job_question_answers/${params.id}`, params);
};
