import { useQuery } from "@tanstack/react-query";
import { TagSimilarityDef } from "@app/features/super-admin/types/super-admin.tag-similarity.types";
import { ETagType } from "@app/types/tags.types";
import { queryKeysSA } from "./queryKeys";
import { superAdminApi } from "./super-admin.api";

export const useTagSimilaritiesSA = (
  params?: {
    mainTagId?: string;
    similarTagId?: string;
    similarTagType?: ETagType;
    mainTagType?: ETagType;
  },
  options?: { enabled?: boolean }
) =>
  useQuery({
    queryKey: [...queryKeysSA.lists(), "tag-similarities", params] as const,
    queryFn: ({ queryKey }) =>
      superAdminApi
        .get<TagSimilarityDef[]>("crafthunts/tag_similarities", {
          params: queryKey[3],
        })
        .then(({ data }) => data),
    enabled: options?.enabled,
  });

export const createTagSimilaritySA = (params: {
  mainTagId: string;
  similarTagId: string;
  weight: number;
}) => {
  return superAdminApi.put(`crafthunts/tag_similarities`, params);
};

export const updateTagSimilaritySA = (params: { id: string; weight: number }) => {
  return superAdminApi.put(`crafthunts/tag_similarities/${params.id}`, params);
};

export const deleteTagSimilaritySA = (params: { id: string }) => {
  return superAdminApi.delete(`crafthunts/tag_similarities/${params.id}`);
};
