import {
  CheckCircleOutlined,
  CheckOutlined,
  CheckSquareOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  FormOutlined,
  LikeOutlined,
  NumberOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Card,
  Col,
  Form,
  FormInstance,
  FormListOperation,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@app/components/ui/Button/Button";
import { InlineSwitch } from "@app/components/ui/InlineSwitch/InlineSwitch";
import {
  ANSWER_CHOICE_MAP,
  ANSWER_TYPE_MAP,
  EJobQuestionAnswerTypeCombined,
  EJobQuestionGoodNumberAnswerType,
  EJobQuestionOptionType,
  EJobQuestionType,
  JobQuestionFormDef,
  JobQuestionOptionDef,
  JobQuestionsFormDef,
} from "@app/types/job-questions.types";
import styles from "./JobQuestionForm.module.scss";
import { KnockoutNumberAnswer } from "./components/KnockoutNumberAnswer";
import { QuestionOptions } from "./components/QuestionOptions/QuestionOptions";
import { TypeSelect } from "./components/TypeSelect";

export const ANSWER_TYPE_MAP_ICON = {
  [EJobQuestionAnswerTypeCombined.TEXT]: FormOutlined,
  [EJobQuestionAnswerTypeCombined.BOOLEAN]: LikeOutlined,
  [EJobQuestionAnswerTypeCombined.NUMBER]: NumberOutlined,
  [EJobQuestionAnswerTypeCombined.OPTION_SINGLE]: CheckCircleOutlined,
  [EJobQuestionAnswerTypeCombined.OPTION_MULTI]: CheckSquareOutlined,
};

const INITIAL_DATA = {
  documentsRequired: false,
  type: EJobQuestionType.OPTIONAL,
  answerType: ANSWER_TYPE_MAP[EJobQuestionAnswerTypeCombined.TEXT],
  answerChoice: ANSWER_CHOICE_MAP[EJobQuestionAnswerTypeCombined.TEXT],
  answerTypeCombined: EJobQuestionAnswerTypeCombined.TEXT,
};

export const JOB_QUESTION_FORM = "questions-form";

type JobQuestionFormProps = {
  form: FormInstance<JobQuestionsFormDef>;
  isJobAdPublished: boolean;
};

export const JobQuestionForm = ({ form, isJobAdPublished }: JobQuestionFormProps) => {
  const { t } = useTranslation();
  const lastCard = useRef<HTMLDivElement>(null);

  const handleOnAdd = (add: FormListOperation["add"]) => () => {
    add(INITIAL_DATA);
    setTimeout(() => {
      lastCard.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleOnDuplicate = (add: FormListOperation["add"], index: number) => async () => {
    const {
      title,
      type,
      goodNumberAnswerType,
      goodNumberAnswerValue,
      answerType,
      answerChoice,
      answerTypeCombined,
      options,
    } = form.getFieldValue(["questions", index]) as JobQuestionsFormDef["questions"][0];
    add(
      {
        title,
        type,
        goodNumberAnswerType,
        goodNumberAnswerValue,
        answerType,
        answerChoice,
        answerTypeCombined,
        options: options?.map(
          (option) =>
            ({
              ...option,
              id: undefined,
            } as JobQuestionOptionDef)
        ),
      },
      index + 1
    );
  };

  const validateFields = () => {
    setTimeout(() => {
      form.validateFields();
    }, 0);
  };

  const handleOnRemove = (remove: FormListOperation["remove"], index: number) => () => {
    remove(index);
    validateFields();
  };

  const handleOnChangeAnswerTypeCombined =
    (index: number) => (value: EJobQuestionAnswerTypeCombined) => {
      if (typeof value === "string") {
        form.setFieldValue(["questions", index, "answerType"], ANSWER_TYPE_MAP[value]);
        form.setFieldValue(["questions", index, "answerChoice"], ANSWER_CHOICE_MAP[value]);

        if (value === EJobQuestionAnswerTypeCombined.BOOLEAN) {
          form.setFieldValue(
            ["questions", index, "options"],
            [
              { text: t("Yes"), type: EJobQuestionOptionType.BOOLEAN_TRUE },
              { text: t("No"), type: EJobQuestionOptionType.BOOLEAN_FALSE },
            ]
          );
        } else if (
          value === EJobQuestionAnswerTypeCombined.OPTION_SINGLE ||
          value === EJobQuestionAnswerTypeCombined.OPTION_MULTI
        ) {
          const existingOptions = form.getFieldValue([
            "questions",
            index,
            "options",
          ]) as JobQuestionFormDef["options"];
          form.setFieldValue(
            ["questions", index, "options"],
            existingOptions?.map((option) => ({
              ...option,
              type: EJobQuestionOptionType.TEXT,
              isGoodAnswer: false,
            }))
          );
        } else if (value === EJobQuestionAnswerTypeCombined.NUMBER) {
          form.setFieldValue(
            ["questions", index, "goodNumberAnswerType"],
            EJobQuestionGoodNumberAnswerType.MORE_THAN
          );
        } else if (value === EJobQuestionAnswerTypeCombined.TEXT) {
          if (form.getFieldValue(["questions", index, "type"]) === EJobQuestionType.KNOCKOUT) {
            form.setFieldValue(["questions", index, "type"], EJobQuestionType.REQUIRED);
          }
        }
      }
    };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={INITIAL_DATA}
      name={JOB_QUESTION_FORM}
      data-hs-do-not-collect="true"
    >
      <div className={styles.header}>
        <Typography.Title level={3} className={styles.alignCenter}>
          <span>{t("Screening Questions")}</span> <Tag className={styles.tag}>{t("Optional")}</Tag>
        </Typography.Title>
        <Typography.Text>
          {t("Applicants will be asked these questions when applying")}
        </Typography.Text>
        {isJobAdPublished && (
          <div>
            <Typography.Text type="danger">
              {t("Be careful to change questions of published job ads")}
            </Typography.Text>
          </div>
        )}
        <Alert
          message={t("We recommend 2-4 questions")}
          description={t(
            "Focus on the most relevant questions to keep candidates motivated throughout the process."
          )}
          type="info"
          className={styles.alert}
        />
      </div>
      <Form.Item name="documentsRequired" valuePropName="checked">
        <InlineSwitch
          label={t("Require applicants to upload documents")}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Form.Item>
      <Form.List name="questions">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Card
                key={key}
                className={styles.card}
                title={`${t("Question")} ${name + 1}`}
                extra={
                  <Space>
                    <TypeSelect form={form} fieldName={name} />
                    <Button
                      key="duplicate"
                      onClick={handleOnDuplicate(add, name)}
                      type="text"
                      size="small"
                      icon={<CopyOutlined />}
                    />
                    <Button
                      key="remove"
                      onClick={handleOnRemove(remove, name)}
                      danger
                      type="link"
                      size="small"
                      icon={<DeleteOutlined />}
                    />
                  </Space>
                }
              >
                {/* Hidden fields */}
                <Form.Item hidden name={[name, "id"]} noStyle>
                  <Input />
                </Form.Item>
                <Form.Item hidden name={[name, "answerType"]} noStyle>
                  <Input />
                </Form.Item>
                <Form.Item hidden name={[name, "answerChoice"]} noStyle>
                  <Input />
                </Form.Item>
                {/* Visible fields */}
                <Row gutter={16}>
                  <Col span={24} sm={14}>
                    <Form.Item
                      {...restField}
                      name={[name, "title"]}
                      label={t("Question")}
                      rules={[
                        { required: true, whitespace: true, message: t("Required") },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.resolve();
                            }
                            const otherQuestions = [
                              ...getFieldValue("questions"),
                            ] as JobQuestionsFormDef["questions"];
                            // remove existing item from options
                            otherQuestions.splice(name, 1);
                            // check if other questions are identical in title
                            if (otherQuestions.find((others) => others.title === value)) {
                              return Promise.reject(new Error(t("No duplicate questions")));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        autoFocus
                        size="large"
                        placeholder={t("question-placeholder")}
                        onBlur={validateFields}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} sm={10}>
                    <Form.Item
                      {...restField}
                      label={t("Answer Type")}
                      name={[name, "answerTypeCombined"]}
                      rules={[
                        { required: true, message: t("Required") },
                        ({ getFieldValue }) => ({
                          validator(_rule, value) {
                            if (
                              (value === EJobQuestionAnswerTypeCombined.OPTION_SINGLE ||
                                value === EJobQuestionAnswerTypeCombined.OPTION_MULTI) &&
                              !getFieldValue(["questions", name, "options"])?.length
                            ) {
                              return Promise.reject(new Error(t("Add an option")));
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Select size="large" onChange={handleOnChangeAnswerTypeCombined(name)}>
                        {Object.values(EJobQuestionAnswerTypeCombined).map((item) => {
                          const OptionIcon = ANSWER_TYPE_MAP_ICON[item];
                          return (
                            <Select.Option key={item} value={item} label={t(item)}>
                              <Space>
                                <OptionIcon />
                                {t(item)}
                              </Space>
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <KnockoutNumberAnswer form={form} fieldName={name} />
                <QuestionOptions fieldName={name} form={form} />
              </Card>
            ))}
            <div ref={lastCard}>
              <Form.Item>
                <Button type="default" onClick={handleOnAdd(add)} icon={<PlusOutlined />}>
                  {t("Add question")}
                </Button>
              </Form.Item>
            </div>
          </>
        )}
      </Form.List>
    </Form>
  );
};
