import { DeleteOutlined, DislikeOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox, Col, Flex, Form, FormInstance, Input, InputRef, Row } from "antd";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ANSWER_TYPE_MAP_ICON } from "@app/components/pages/JobAdDetails/components/JobQuestionForm/JobQuestionForm";
import { Button } from "@app/components/ui/Button/Button";
import {
  ANSWER_CHOICE_MAP,
  EJobQuestionAnswerChoice,
  EJobQuestionAnswerType,
  EJobQuestionAnswerTypeCombined,
  EJobQuestionOptionType,
  EJobQuestionType,
  JobQuestionFormDef,
  JobQuestionOptionDef,
  JobQuestionsFormDef,
} from "@app/types/job-questions.types";
import styles from "./QuestionOptions.module.scss";

type QuestionOptionsProps = {
  fieldName: number;
  form: FormInstance<JobQuestionsFormDef>;
};

export const QuestionOptions = ({ fieldName, form, ...rest }: QuestionOptionsProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<InputRef>(null);
  const type = Form.useWatch(["questions", fieldName, "type"], form);
  const answerType = Form.useWatch(["questions", fieldName, "answerType"], form);
  const answerTypeCombined = Form.useWatch(["questions", fieldName, "answerTypeCombined"], form);
  const isBooleanType = answerTypeCombined === EJobQuestionAnswerTypeCombined.BOOLEAN;
  const OptionIcon = ANSWER_TYPE_MAP_ICON[answerTypeCombined];

  const showRemoveAndAdd = !isBooleanType;

  const getIcon = (key: number) => {
    if (isBooleanType && key === 1) {
      return <DislikeOutlined />;
    }
    return <OptionIcon />;
  };

  const handleOnAdd = (
    add: (defaultValue?: JobQuestionOptionDef, insertIndex?: number | undefined) => void
  ) => {
    add({
      type: EJobQuestionOptionType.TEXT,
      text: "",
    });
    form.validateFields();
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 0);
  };

  // Only show question options, when type is OPTION
  if (answerType !== EJobQuestionAnswerType.OPTION) {
    return null;
  }

  const onChange = (key: number) => () => {
    if (ANSWER_CHOICE_MAP[answerTypeCombined] !== EJobQuestionAnswerChoice.SINGLE) {
      return;
    }

    const questions: JobQuestionFormDef[] = form.getFieldValue("questions");
    form.setFieldValue(
      "questions",
      questions.map((question, index) => {
        if (index === fieldName) {
          return {
            ...question,
            options: question.options.map((option, optionIndex) => {
              if (optionIndex !== key) {
                return {
                  ...option,
                  isGoodAnswer: false,
                };
              }
              return option;
            }),
          };
        }
        return question;
      })
    );
  };

  return (
    <Form.List name={[fieldName, "options"]} {...rest}>
      {(fields, { add, remove }) => (
        <Row gutter={[8, 8]}>
          {fields.map(({ key, name, ...restField }) => (
            <Col key={key} span={24}>
              {/* Hidden fields */}
              <Form.Item hidden name={[name, "id"]} noStyle>
                <Input />
              </Form.Item>
              <Form.Item hidden name={[name, "type"]} noStyle>
                <Input />
              </Form.Item>
              {/* Visible fields */}
              <Flex align="start" gap="small">
                <Form.Item
                  {...restField}
                  style={{
                    flex: 1,
                  }}
                  name={[name, "text"]}
                  shouldUpdate
                  rules={[
                    { required: true, whitespace: true, message: t("Required") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        const otherOptions = [
                          ...getFieldValue(["questions", fieldName, "options"]),
                        ] as JobQuestionsFormDef["questions"][0]["options"];
                        // remove existing item from options
                        otherOptions.splice(name, 1);
                        // check if other options are identical in text
                        if (otherOptions.find((others) => others.text === value)) {
                          return Promise.reject(new Error(t("No duplicate options")));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t("Enter...")}
                    className={styles.input}
                    prefix={getIcon(key)}
                    ref={inputRef}
                    suffix={
                      showRemoveAndAdd && (
                        <Button
                          key="remove"
                          onClick={() => {
                            remove(name);
                            form.validateFields();
                          }}
                          danger
                          type="link"
                          size="small"
                          icon={<DeleteOutlined />}
                        />
                      )
                    }
                    onKeyUp={(e) => {
                      if (showRemoveAndAdd && e.key === "Enter") {
                        handleOnAdd(add);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "isGoodAnswer"]}
                  valuePropName="checked"
                  hidden={type !== EJobQuestionType.KNOCKOUT}
                >
                  <Checkbox
                    style={{
                      marginLeft: "auto",
                      minHeight: "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onChange={onChange(key)}
                  >
                    {t("is-preferred-answer")}
                  </Checkbox>
                </Form.Item>
              </Flex>
            </Col>
          ))}
          {showRemoveAndAdd && (
            <Col span={24}>
              <Form.Item>
                <Button
                  block
                  type="default"
                  className={styles.button}
                  onClick={() => handleOnAdd(add)}
                  icon={<PlusOutlined />}
                >
                  {t("Add an option")}
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
    </Form.List>
  );
};
