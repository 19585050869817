import { useQuery } from "@tanstack/react-query";
import { WorkerProfileDef } from "@app/types/worker-profile.types";
import { api } from "./api";

export const getWorkerProfile = async (params: { idOrSlug: string; lang: string }) => {
  const response = await api.get<WorkerProfileDef>(`/crafthunts/slugs/${params.idOrSlug}`, {
    params: { lang: params.lang, type: "wp" },
  });
  return response.data;
};

export const useWorkerProfile = (
  params: { idOrSlug: string; lang: string },
  options = {
    enabled: true,
  }
) => {
  return useQuery({
    enabled: options.enabled,
    queryKey: ["worker-profile", params] as const,
    queryFn: ({ queryKey }) => getWorkerProfile(queryKey[1]),
  });
};
