import { useQuery } from "@tanstack/react-query";
import { CandidateInterviewDef, ECandidateInterviewType } from "@app/types/interviews.types";
import { api } from "./api";

const url = "/crafthunts/candidate_interviews";

export const useInterviews = (
  params: { candidateId?: string },
  options?: { enabled?: boolean }
) => {
  return useQuery({
    enabled: options?.enabled,
    queryKey: ["interviews", params.candidateId],
    queryFn: () =>
      api
        .get<CandidateInterviewDef[]>(url, {
          params: {
            candidateId: params.candidateId,
          },
        })
        .then((response) => response.data),
  });
};

export const postInterview = async (payload: {
  candidateId: string;
  date: string;
  type: ECandidateInterviewType;
}) => {
  const response = await api.post<CandidateInterviewDef>(url, payload);
  return response.data;
};

export const updateInterview = async (payload: {
  id: string;
  date: string;
  type: ECandidateInterviewType;
}) => {
  const response = await api.put<CandidateInterviewDef>(`${url}/${payload.id}`, payload);
  return response.data;
};

export const deleteInterview = async (id: string) => {
  await api.delete(`${url}/${id}`);
};
