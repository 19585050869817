import { Col, Input, Row } from "antd";
import orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";
import { Label } from "@app/components/ui/Label/Label";
import { store } from "@app/store/store";
import { CandidateDef, UpdateCandidateDef } from "@app/types/candidate.types";
import { InternalNote } from "./InternalNote";
import styles from "./Notes.module.scss";

type Props = {
  candidate: CandidateDef;
};

export const Notes = ({ candidate }: Props) => {
  const { t } = useTranslation();
  const { dispatch } = store;

  const handleUpdateCandidate = async (payload: UpdateCandidateDef) => {
    await dispatch.candidates.updateCandidateAction({
      candidateId: candidate.id,
      candidateData: payload,
    });
  };

  const approvalWithNote = orderBy(
    candidate.candidateApprovals ?? [],
    (approval) => approval.createdAt,
    "desc"
  ).find((approval) => approval.description);

  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <InternalNote candidate={candidate} updateCandidate={handleUpdateCandidate} />
        </Col>
        {approvalWithNote && (
          <Col span={12}>
            <div>
              <Label>{t("approval-note-title")}</Label>
              <Input.TextArea
                disabled
                className={styles.interviewerNote}
                defaultValue={approvalWithNote.description}
                autoSize={{
                  minRows: 1,
                  maxRows: 6,
                }}
                style={{
                  fontSize: "14px",
                }}
              />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
