import { Col, Row, Space, Table, TableColumnsType, Tag, Typography } from "antd";
import { TablePaginationConfig } from "antd/es/table/interface";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactMoment from "react-moment";
import { useSessionStorage } from "react-use";
import { DateFormats } from "@app/constants/date.constants";
import { useCandidatesSA } from "@app/features/super-admin/api/super-admin.candidate.api";
import { useApplicationModal } from "@app/features/super-admin/components/SuperAdminStuff/components/ApplicationModal/useApplicationModal";
import { useCompanyModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Companies/components/CompanyModal";
import { CompanyFilter } from "@app/features/super-admin/components/SuperAdminStuff/components/Filters/CompanyFilter/CompanyFilter";
import { UserFilter } from "@app/features/super-admin/components/SuperAdminStuff/components/Filters/UserFilter/UserFilter";
import { useJobModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Jobs/JobModal/JobModal";
import { useUserModalGlobal } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/UserModalGlobal";
import { SUPER_ADMIN_PAGE_SIZE } from "@app/features/super-admin/constants/super-admin.constants";
import { CandidateSADef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { ECandidateApprovalAction, ECandidateStatus } from "@app/types/candidate.types";

type Props = {
  selectedUser?: UserFormDef;
  isActive: boolean;
  onCountChange: (count: number) => void;
};

export type ApprovalState = {
  candidate: CandidateSADef | null;
  action: ECandidateApprovalAction;
};

export const Approvals = ({ selectedUser, isActive, onCountChange }: Props) => {
  const { t } = useTranslation();
  const { openUserModal } = useUserModalGlobal();
  const { setCompanyId } = useCompanyModal();
  const { setJobId } = useJobModal();
  const { setApplicationId } = useApplicationModal();

  const [page, setPage] = useSessionStorage<number>("sa-approvals-page", 0);
  const [companyIdForFilter, setCompanyIdForFilter] = useSessionStorage<string | undefined>(
    "sa-approvals-companyId"
  );
  const [userIdForFilter, setUserIdForFilter] = useSessionStorage<string | undefined>(
    "sa-approvals-userId",
    selectedUser?.id
  );
  const showFilters = !selectedUser;

  const candidatesQuery = useCandidatesSA(
    {
      limit: SUPER_ADMIN_PAGE_SIZE,
      offset: page,
      companyId: companyIdForFilter,
      workerProfileId: userIdForFilter,
      status: ECandidateStatus.NEEDS_APPROVAL,
    },
    {
      refetchInterval: 60000,
    }
  );

  useEffect(() => {
    onCountChange(candidatesQuery.data?.count || 0);
  }, [candidatesQuery.data?.count]);

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number" && typeof pagination.pageSize === "number") {
      setPage(pagination.current - 1);
    }
  };

  const generatedData = (data: CandidateSADef[]) => {
    return data?.map((item) => ({
      key: item.id,
      isScrapedJob: (
        <Tag color={item.crafthuntCompany.isClaimed ? "green" : "red"}>
          {item.crafthuntCompany.isClaimed ? t("No") : t("Yes")}
        </Tag>
      ),
      companyName: (
        <a onClick={() => setCompanyId(item.crafthuntCompany.id)}>{item.crafthuntCompany.name}</a>
      ),
      jobAds: item.jobAds,
      jobName:
        item.jobAds.length === 1 ? (
          <a onClick={() => setJobId(item.jobAds[0].id)}>{item.jobAds[0].title}</a>
        ) : item.jobAds.length > 1 ? (
          ` ${item.jobAds.length} ${t("Job ads")}`
        ) : (
          ""
        ),
      candidateName: !item.workerProfile.deletedAt ? (
        <a
          onClick={() => {
            openUserModal({ userId: item.workerProfile.id });
          }}
        >
          {[item.workerProfile.firstName, item.workerProfile.lastName].join(" ")}
        </a>
      ) : (
        [item.workerProfile.firstName, item.workerProfile.lastName].join(" ")
      ),
      createdAt: (
        <ReactMoment withTitle titleFormat={DateFormats.FULL_DATE_TIME} fromNow>
          {item.createdAt}
        </ReactMoment>
      ),
      operation: (
        <Space size="middle">
          <a
            onClick={() => {
              setApplicationId(item.id);
            }}
          >
            Review
          </a>
        </Space>
      ),
    }));
  };

  if (!isActive) {
    return null;
  }

  const columns: TableColumnsType<ReturnType<typeof generatedData>[number]> = [
    {
      title: "Scraped",
      dataIndex: "isScrapedJob",
      key: "isScrapedJob",
      ellipsis: true,
      width: 100,
    },
    {
      title: t("Company name"),
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: 250,
    },
    { title: t("Job ads"), dataIndex: "jobName", key: "jobName", ellipsis: true },
    {
      title: t("candidate-name-label"),
      dataIndex: "candidateName",
      key: "candidateName",
      ellipsis: true,
      width: 300,
    },
    { title: t("Created at"), dataIndex: "createdAt", key: "createdAt", width: 200 },
    {
      title: t("Actions"),
      dataIndex: "operation",
      key: "operation",
      fixed: "right",
      width: 125,
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {showFilters && (
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            <CompanyFilter defaultValue={companyIdForFilter} onChange={setCompanyIdForFilter} />
          </Col>
          <Col span={12}>
            <UserFilter
              defaultValue={userIdForFilter}
              value={userIdForFilter}
              onChange={setUserIdForFilter}
            />
          </Col>
        </Row>
      )}
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={generatedData(candidatesQuery.data?.data ?? [])}
        loading={candidatesQuery.isLoading}
        onChange={handlePageChange}
        scroll={{ x: 1250 }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Typography.Text>{t("Job ads")}</Typography.Text>
              <ul>
                {record.jobAds.map((job) => (
                  <li key={job.id}>
                    <a onClick={() => setJobId(job.id)}>{job.title}</a>
                  </li>
                ))}
              </ul>
            </>
          ),
          rowExpandable: (record) => record.jobAds.length > 1,
        }}
        pagination={{
          current: page + 1,
          total: candidatesQuery.data?.count,
          showTotal: (total) => `${t("Total")} ${total} ${t("applications-label")}`,
          pageSize: SUPER_ADMIN_PAGE_SIZE,
          showSizeChanger: false,
          position: ["bottomLeft"],
        }}
      />
    </Space>
  );
};
