import { Calendar, DatePicker } from "antd";
import type { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";

export const MomentDatePicker = DatePicker.generatePicker<Moment>({
  ...momentGenerateConfig,
});

export const MomentCalendar = Calendar.generateCalendar<Moment>({
  ...momentGenerateConfig,
});
