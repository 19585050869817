import { Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { sendChatMessage } from "@app/api/chat-messages.api";
import { CandidateStatusIcon } from "@app/components/pages/Candidates/components/CandidateStatusIcon/CandidateStatusIcon";
import { FirstMessageModal } from "@app/components/pages/Candidates/components/FirstMessageModal/FirstMessageModal";
import { HiringModal } from "@app/components/pages/Candidates/components/HiringModal/HiringModal";
import { InterviewModal } from "@app/components/pages/Candidates/components/InterviewModal/InterviewModal";
import { RejectionModal } from "@app/components/pages/Candidates/components/RejectionModal/RejectionModal";
import { triggerEventAcceptRecommendedCandidate } from "@app/components/pages/Candidates/helpers/candidates-analytics.helper";
import { Label } from "@app/components/ui/Label/Label";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { RootState } from "@app/store/store";
import {
  CandidateDef,
  CandidateRejectionFormDef,
  ECandidateRejectionReason,
  ECandidateStatus,
  ECandidateStatusType,
  UpdateCandidateDef,
} from "@app/types/candidate.types";
import styles from "./StatusSelect.module.scss";

type StatusSelectProps = {
  candidate: CandidateDef;
  updateCandidate: (payload: UpdateCandidateDef) => Promise<void>;
};

export const StatusSelect = ({ candidate, updateCandidate }: StatusSelectProps) => {
  const { t } = useTranslation();
  const candidateStatuses = useSelector((state: RootState) => state.candidates.candidateStatuses);
  const [rejectedCandidateIsFromRecommended, setRejectedCandidateIsFromRecommended] =
    useState(false);
  const [showModal, setShowModal] = useState<
    null | "rejection" | "message" | "hiring" | "interview"
  >(null);
  const [errorSendFirstMessage, setErrorSendFirstMessage] = useState(false);
  const [currentStatusId, setCurrentStatusId] = useState<string>(
    candidate.companyCandidateStatus.id
  );
  const [previousStatusId, setPreviousStatusId] = useState<string>(
    candidate.companyCandidateStatus.id
  );

  const getStatusById = (statusId: string) => {
    return candidateStatuses.find((status) => status.id == statusId);
  };

  const handleOnModalCancel = () => {
    setCurrentStatusId(previousStatusId);
    setShowModal(null);
  };

  const handleOnHire = () => {
    saveCandidate(currentStatusId);
    setShowModal(null);
  };

  const handleOnReject = (data: CandidateRejectionFormDef) => {
    saveCandidate(
      currentStatusId,
      data.rejectionMessage,
      data.rejectionReason,
      data.rejectionReasonDescription,
      !rejectedCandidateIsFromRecommended
    );
    setShowModal(null);
  };

  const handleOnInterview = () => {
    saveCandidate(currentStatusId);
    setShowModal(null);
  };

  const handleSendFirstMessage = async (message: string) => {
    try {
      await saveCandidate(currentStatusId);
      await sendChatMessage(message, candidate.id);
      triggerEventAcceptRecommendedCandidate(candidate.id);
      setShowModal(null);
    } catch (error) {
      console.error(error);
      setErrorSendFirstMessage(true);
    }
  };

  const saveCandidate = async (
    newStatus: string,
    rejectionMessage?: string,
    rejectionReason?: ECandidateRejectionReason,
    rejectionReasonDescription?: string,
    handleRejection?: boolean
  ) => {
    await updateCandidate({
      companyCandidateStatusId: newStatus,
      rejectionMessage,
      rejectionReason,
      rejectionReasonDescription,
      handleRejection,
    });
  };

  const handleStatusChange = async (newStatus: string) => {
    const candidateStatus = getStatusById(newStatus);
    const currentStatus = getStatusById(currentStatusId);

    if (!candidateStatus || currentStatusId === newStatus) {
      return;
    }

    setPreviousStatusId(currentStatusId);
    setCurrentStatusId(newStatus);

    if (
      currentStatus?.status === ECandidateStatus.RECOMMENDED &&
      candidateStatus.status !== ECandidateStatus.REJECTED
    ) {
      setShowModal("message");
    } else if (candidateStatus.status === ECandidateStatus.REJECTED) {
      setRejectedCandidateIsFromRecommended(currentStatus?.status === ECandidateStatus.RECOMMENDED);
      setShowModal("rejection");
    } else if (candidateStatus.status === ECandidateStatus.HIRED) {
      setShowModal("hiring");
    } else if (candidateStatus.type === ECandidateStatusType.INTERVIEW) {
      setShowModal("interview");
    } else {
      saveCandidate(newStatus);
    }
  };

  useEffect(() => {
    setCurrentStatusId(candidate.companyCandidateStatus.id);
  }, [candidate.companyCandidateStatus.id]);

  return (
    <>
      <Label htmlFor="candidate-selected-status">{t("company-candidate-status-title")}</Label>
      <SelectAutocomplete
        id="candidate-selected-status"
        value={currentStatusId}
        onChange={handleStatusChange}
        defaultActiveFirstOption
        sortAlphabetically={false}
      >
        {candidateStatuses
          .filter(
            (status) =>
              getStatusById(currentStatusId)?.status === ECandidateStatus.RECOMMENDED ||
              status.status !== ECandidateStatus.RECOMMENDED
          )
          .map((candidateStatus) => {
            const label =
              candidateStatus.status === ECandidateStatus.CUSTOM
                ? candidateStatus.name
                : t(candidateStatus.name);
            return (
              <Select.Option key={candidateStatus.id} value={candidateStatus.id} label={label}>
                <div className={styles.option}>
                  <CandidateStatusIcon candidateStatusName={candidateStatus.name} />
                  <span>{label}</span>
                </div>
              </Select.Option>
            );
          })}
      </SelectAutocomplete>
      <FirstMessageModal
        open={showModal === "message"}
        onCancel={handleOnModalCancel}
        onSend={handleSendFirstMessage}
        hasError={errorSendFirstMessage}
      />
      <RejectionModal
        open={showModal === "rejection"}
        isFromRecommendedStatus={rejectedCandidateIsFromRecommended}
        onCancel={handleOnModalCancel}
        onReject={handleOnReject}
      />
      <HiringModal
        open={showModal === "hiring"}
        onCancel={handleOnModalCancel}
        onHire={handleOnHire}
      />
      <InterviewModal
        candidateId={candidate.id}
        open={showModal === "interview"}
        onCancel={handleOnModalCancel}
        onOk={handleOnInterview}
      />
    </>
  );
};
