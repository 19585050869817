import { Button, Checkbox, Form, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { RootState } from "@app/store/store";

export const JobBenefits = () => {
  const { t } = useTranslation();
  const benefits = useSelector((state: RootState) => state.companyData.benefits);
  const companyBenefits = useSelector((state: RootState) =>
    state.companyData.companyBenefits.filter((item) => item.isActive)
  );

  if (!companyBenefits?.length) {
    return (
      <Space direction="vertical" size="large">
        <Space direction="vertical" size="small">
          <Typography.Text>{t("no-company-benefits")}</Typography.Text>
          <Typography.Text type="secondary">{t("no-company-benefits-description")}</Typography.Text>
        </Space>

        <Link to={RoutePaths.EMPLOYEE_BENEFITS}>
          <Button type={"primary"}>{t("no-company-benefits-action")}</Button>
        </Link>
      </Space>
    );
  }

  return (
    <Form.Item name="jobBenefitIds">
      <Checkbox.Group>
        <Space direction="vertical">
          {companyBenefits.map((compBenefit) => {
            const benefit = benefits.find((b) => b.id === compBenefit.benefitId);

            return (
              <Checkbox key={compBenefit.id} value={compBenefit.id}>
                {t(benefit?.title ?? "")}
              </Checkbox>
            );
          })}
        </Space>
      </Checkbox.Group>
    </Form.Item>
  );
};
