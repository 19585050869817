import { SolutionOutlined } from "@ant-design/icons";
import { Avatar, Flex, Tag, Timeline, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { MdPlace } from "react-icons/md";
import { useWorkerProfile } from "@app/api/worker-profile.api";
import { Section } from "@app/components/ui/Section";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { getTradeName } from "@app/helpers/tag.helper";
import { CandidateDef } from "@app/types/candidate.types";
import { ETagCategory } from "@app/types/tags.types";

export const CandidateTimeline = ({ candidate }: { candidate: CandidateDef }) => {
  const { t, i18n } = useTranslation();

  const workerProfileQuery = useWorkerProfile(
    {
      idOrSlug: candidate.workerProfile.slug || candidate.workerProfile.anonymousSlug,
      lang: convertLangForBackend(i18n.language),
    },
    {
      enabled: !candidate.workerProfile.deletedAt,
    }
  );

  const timeline = workerProfileQuery.data?.timeline;

  if (!timeline || timeline.length === 0) {
    return null;
  }

  return (
    <Section Icon={SolutionOutlined} title={t("profile-work-experience-header")}>
      <Timeline
        style={{
          padding: "12px 0 0 12px",
        }}
        items={timeline.map((item) => {
          const dates = [item.startDate, item.endDate]
            .filter(Boolean)
            .map((date) => moment(date).format("MMMM YYYY"))
            .join(" - ");

          return {
            dot: item.companyInfo?.logo ? (
              <Avatar src={item.companyInfo.logo} />
            ) : (
              <Avatar>{item.companyInfo?.name[0]}</Avatar>
            ),
            children: (
              <Flex vertical>
                {item.jobTitle && <Typography.Text strong>{t(item.jobTitle.name)}</Typography.Text>}
                <Typography.Text type="secondary">
                  {item.companyInfo?.name || t("timeline-anonymous-company")}
                </Typography.Text>
                {item.address && (
                  <Flex align="center">
                    <MdPlace className="size-4" />
                    <div>
                      {[item.address.city, item.address.country].filter(Boolean).join(", ")}
                    </div>
                  </Flex>
                )}
                {dates && <Typography.Text>{dates}</Typography.Text>}
                {item.description && (
                  <Typography.Text style={{ whiteSpace: "pre-line", fontSize: "14px" }}>
                    {item.description}
                  </Typography.Text>
                )}
                {item.trades && item.trades.length > 0 && (
                  <Flex
                    wrap
                    style={{
                      marginTop: "4px",
                    }}
                  >
                    {item.trades.map((trade) => (
                      <Tag key={trade.id} color="orange">
                        {t(getTradeName(trade.name, item.jobTitle?.category as ETagCategory))}
                      </Tag>
                    ))}
                  </Flex>
                )}
              </Flex>
            ),
          };
        })}
      />
    </Section>
  );
};
