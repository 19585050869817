import { Button, App } from "antd";
import { PropsWithChildren } from "react";
import { getMagicLink } from "@app/features/super-admin/api/super-admin.magic.api";
import { useCopy } from "@app/hooks/useCopy";

const LINK_KEY = "link";

export const MagicLinkButton = ({
  userId,
  style,
  children,
}: PropsWithChildren<{ userId: string; style?: React.CSSProperties }>) => {
  const { message, modal } = App.useApp();
  const { copyToClipboard } = useCopy();

  const generateShortMagicLink = async () => {
    try {
      message.loading({ content: "Preparing magic link", key: LINK_KEY, duration: 0 });
      const magicLink = await getMagicLink(userId);
      message.destroy(LINK_KEY);
      copyToClipboard(magicLink.data);
    } catch {
      modal.error({
        title: "Something went wrong",
      });
      message.destroy(LINK_KEY);
    }
  };

  return (
    <Button style={style} onClick={() => generateShortMagicLink()} type="link">
      {children}
    </Button>
  );
};
