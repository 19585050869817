import { Card, Space, Alert, theme } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdVerified } from "react-icons/md";
import { useSelector } from "react-redux";
import { AddLinkToWebsiteModal } from "@app/components/pages/Dashboard/AddLinkToWebsiteModal/AddLinkToWebsiteModal";
import { useLiveURL } from "@app/hooks/useLiveUrl";
import { RootState } from "@app/store/store";
import { ApprovalStatusEnum, EEmbeddedCrafthuntLinkStatus } from "@app/types/company-info.types";

export const AlertDashboardCard = () => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const liveURL = useLiveURL();
  const existingCompanyInfo = useSelector((state: RootState) => state.companyInfo.companyInfo);
  const [addLinkToWebsiteModalOpen, setAddLinkToWebsiteModalOpen] = useState(false);

  const approvalStatus = existingCompanyInfo.approvalStatus;

  const closeModals = () => {
    setAddLinkToWebsiteModalOpen(false);
  };

  return (
    <Card title={t("alert-dashboard-title")} bordered={false}>
      <Space direction="vertical" size="large">
        {existingCompanyInfo.embeddedCrafthuntLinkStatus === EEmbeddedCrafthuntLinkStatus.NONE && (
          <Alert
            message={t("Add your profile to your website")}
            description={
              <Space direction="vertical" size="small" style={{ width: "100%" }}>
                <p>{t("Add a link to your Crafthunt profile to your company website.")}</p>
                <a
                  rel="noreferrer"
                  onClick={() => setAddLinkToWebsiteModalOpen(true)}
                  style={{ color: token.colorPrimary }}
                >
                  {t("resolve-issue-button")}
                </a>
              </Space>
            }
            type="warning"
            showIcon
          />
        )}
        {existingCompanyInfo.embeddedCrafthuntLinkStatus ===
          EEmbeddedCrafthuntLinkStatus.HAS_CRAFTHUNT_LINK && (
          <Alert
            message={t("backlink-without-company-alert-title")}
            description={
              <Space direction="vertical" size="small" style={{ width: "100%" }}>
                <p>{t("backlink-without-company-alert-description")}</p>
                <a
                  rel="noreferrer"
                  onClick={() => setAddLinkToWebsiteModalOpen(true)}
                  style={{ color: token.colorPrimary }}
                >
                  {t("resolve-issue-button")}
                </a>
              </Space>
            }
            type="warning"
            showIcon
          />
        )}
        {approvalStatus === ApprovalStatusEnum.APPROVED && (
          <Alert
            icon={<MdVerified />}
            message={t("Approved")}
            description={
              <Space direction="vertical" size="small" style={{ width: "100%" }}>
                <p>
                  {t(
                    "Congrats! Your company has been approved and it's now publicly available in the app."
                  )}
                </p>
                <a
                  href={liveURL}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: token.colorPrimary }}
                >
                  {t("Link to your Crafthunt profile")}
                </a>
              </Space>
            }
            type="success"
            showIcon
          />
        )}
      </Space>
      <AddLinkToWebsiteModal close={closeModals} open={addLinkToWebsiteModalOpen} />
    </Card>
  );
};
