import { EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import { IconType } from "react-icons";
import { MdDownload } from "react-icons/md";
import { Icon } from "@app/components/ui/Icon/Icon";
import { DateFormats } from "@app/constants/date.constants";
import { ProfileDocumentDef } from "@app/types/candidate.types";
import styles from "./DocumentItem.module.scss";

type DocumentItemProps = {
  doc: ProfileDocumentDef;
  icon: IconType;
  onDownload: () => void;
  onPreview?: () => void;
};

export const DocumentItem = ({ onDownload, doc, icon, onPreview }: DocumentItemProps) => {
  return (
    <div className={styles.listItem}>
      <div className={styles.listItemInfo}>
        <Icon icon={icon} size={24} />
        <div className={styles.listItemText}>
          <a
            href={doc.url}
            target="_blank"
            className={styles.listItemLink}
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {doc.filename}
          </a>
          <div className={styles.listItemDate}>
            {moment(doc.createdAt).format(DateFormats.FULL_DATE_TIME)}
          </div>
        </div>
      </div>

      {onPreview && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onPreview();
          }}
          className={styles.listItemButton}
          style={{
            marginLeft: "auto",
          }}
          icon={
            <EyeOutlined
              style={{
                fontSize: "18px",
              }}
            />
          }
          type="text"
        />
      )}
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onDownload();
        }}
        className={styles.listItemButton}
        icon={<Icon icon={MdDownload} size={18} />}
        type="text"
      />
    </div>
  );
};
