import { EditOutlined, FilePdfOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Drawer, Flex, Space, Splitter, Tabs } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdMail, MdPhone } from "react-icons/md";
import { useLocalStorage } from "react-use";
import { useInterviews } from "@app/api/interviews.api";
import { HeaderContact } from "@app/components/pages/Candidates/components/CandidateDrawer/components/HeaderContact/HeaderContact";
import { StatusSelect } from "@app/components/pages/Candidates/components/CandidateDrawer/components/StatusSelect/StatusSelect";
import { CandidateUrgencyTag } from "@app/components/pages/Candidates/components/CandidatesKanban/components/CandidateCardContent/components/CandidateUrgencyTag";
import { AISummary } from "@app/components/ui/AISummary";
import { Section } from "@app/components/ui/Section";
import { DateFormats } from "@app/constants/date.constants";
import { store } from "@app/store/store";
import { CandidateDef, ECandidateStatusType, UpdateCandidateDef } from "@app/types/candidate.types";
import styles from "./CandidateDrawer.module.scss";
import { CandidateTimeline } from "./components/CandidateTimeline/CandidateTimeline";
import { ChatTab } from "./components/ChatTab/ChatTab";
import { Documents } from "./components/Documents/Documents";
import { HeaderInfo } from "./components/HeaderInfo";
import { JobsList } from "./components/JobsList/JobsList";
import { KeyData } from "./components/KeyData";
import { Notes } from "./components/Notes/Notes";

type CandidateDrawerProps = {
  defaultTab?: CANDIDATE_DRAWER_TAB_KEYS;
  open: boolean;
  candidate: CandidateDef;
  companyCandidateStatusType?: ECandidateStatusType;
  onClose: () => void;
  onInterviewEdit: () => void;
};

export enum CANDIDATE_DRAWER_TAB_KEYS {
  INFO = "info",
  CHAT = "chat",
}

export const CandidateDrawer = ({
  defaultTab = CANDIDATE_DRAWER_TAB_KEYS.INFO,
  open,
  candidate,
  companyCandidateStatusType,
  onClose,
  onInterviewEdit,
}: CandidateDrawerProps) => {
  const { t } = useTranslation();
  const { dispatch } = store;
  const [activeTab, setActiveTab] = useState(defaultTab);

  const [sizes, setSizes] = useLocalStorage<(number | string)[]>("candidate-details-drawer-sizes", [
    "70%",
    "30%",
  ]);
  const handleChange = async (payload: UpdateCandidateDef) => {
    await handleUpdateCandidate(payload);
  };

  const handleUpdateCandidate = async (payload: UpdateCandidateDef) => {
    await dispatch.candidates.updateCandidateAction({
      candidateId: candidate.id,
      candidateData: payload,
    });
  };

  useEffect(() => {
    if (open) {
      setActiveTab(defaultTab);
    }
  }, [open, defaultTab]);

  const interviewQuery = useInterviews(
    {
      candidateId: candidate.id,
    },
    {
      enabled: companyCandidateStatusType === ECandidateStatusType.INTERVIEW,
    }
  );
  const interview = interviewQuery.data?.[0];

  const showDocsPanel = candidate.revealIdentity && !candidate.workerProfile.deletedAt;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={showDocsPanel ? "1280px" : "1080px"}
      styles={{
        body: {
          paddingTop: 0,
        },
      }}
      title={<HeaderInfo candidate={candidate} />}
    >
      <Tabs
        activeKey={activeTab}
        onChange={(tabKey) => setActiveTab(tabKey as CANDIDATE_DRAWER_TAB_KEYS)}
        className={styles.fullHeightTabs}
        tabBarExtraContent={<CandidateUrgencyTag showTooltip candidate={candidate} />}
        items={[
          {
            key: CANDIDATE_DRAWER_TAB_KEYS.INFO,
            label: t("Info"),
            children: (
              <Splitter
                onResize={setSizes}
                style={{
                  gap: "4px",
                }}
              >
                <Splitter.Panel
                  size={sizes?.[0]}
                  min="20%"
                  max="70%"
                  style={{
                    paddingRight: "12px",
                  }}
                >
                  <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <Flex align="center" gap="middle">
                      <div
                        style={{
                          minWidth: 0,
                        }}
                      >
                        <StatusSelect
                          key={candidate.id + candidate.companyCandidateStatus.id}
                          candidate={candidate}
                          updateCandidate={handleChange}
                        />
                      </div>
                      {(candidate.workerProfile.phone || candidate.workerProfile.email) && (
                        <Space
                          direction="vertical"
                          align="end"
                          style={{
                            marginLeft: "auto",
                          }}
                        >
                          {candidate.workerProfile.phone && (
                            <HeaderContact
                              icon={MdPhone}
                              href={`tel:${candidate.workerProfile.phone}`}
                              label={candidate.workerProfile.phone}
                            />
                          )}
                          {candidate.workerProfile.email &&
                            !candidate.workerProfile.email
                              .toLowerCase()
                              .includes("indeedemail.com") && (
                              <HeaderContact
                                icon={MdMail}
                                href={`mailto:${candidate.workerProfile.email}`}
                                label={candidate.workerProfile.email}
                              />
                            )}
                        </Space>
                      )}
                    </Flex>

                    {interview && (
                      <Alert
                        showIcon
                        type="info"
                        action={<Button icon={<EditOutlined />} onClick={onInterviewEdit} />}
                        message={
                          <>
                            {t("interview-scheduled-on")}{" "}
                            <b>{moment(interview.date).format(DateFormats.FULL_DATE_TIME)}</b>
                            <div>
                              {t("interview-type")}: <b>{t(`interview-type-${interview.type}`)}</b>
                            </div>
                          </>
                        }
                      />
                    )}

                    <Section title={t("information")} Icon={InfoCircleOutlined}>
                      <Notes candidate={candidate} />
                      <KeyData candidate={candidate} />
                    </Section>

                    {candidate.AIDescriptionSummary && (
                      <AISummary
                        summaryLabel={t("ai-summary-short-info")}
                        summary={candidate.AIDescriptionSummary}
                        limitConsTo={3}
                      />
                    )}

                    <JobsList candidate={candidate} updateCandidate={handleChange} />

                    <CandidateTimeline candidate={candidate} />
                  </Space>
                </Splitter.Panel>
                {showDocsPanel && (
                  <Splitter.Panel
                    size={sizes?.[1]}
                    min="20%"
                    max="70%"
                    style={{
                      paddingLeft: "12px",
                    }}
                  >
                    <Section title={t("Documents")} Icon={FilePdfOutlined}>
                      <Documents
                        documents={candidate.workerProfile.medias}
                        candidateUserId={candidate.workerProfileId}
                      />
                    </Section>
                  </Splitter.Panel>
                )}
              </Splitter>
            ),
          },
          {
            key: CANDIDATE_DRAWER_TAB_KEYS.CHAT,
            label: t("chat"),
            children: <ChatTab key={candidate.id} candidateId={candidate.id} />,
          },
        ]}
      />
    </Drawer>
  );
};
