import RefIcon from "@ant-design/icons/lib/icons/AccountBookFilled";
import { Space, Flex, Typography } from "antd";
import { PropsWithChildren, ReactNode } from "react";

export const Section = ({
  title,
  extraTitleContent,
  Icon,
  children,
}: PropsWithChildren<{
  title: ReactNode;
  extraTitleContent?: ReactNode;
  Icon: typeof RefIcon;
}>) => {
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Flex gap="small" align="center">
        <Icon style={{ fontSize: 20 }} />
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          {title}
        </Typography.Title>
        {extraTitleContent && (
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {extraTitleContent}
          </div>
        )}
      </Flex>
      {children}
    </Space>
  );
};
