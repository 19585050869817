import { useQuery } from "@tanstack/react-query";
import { JobAnswerApiResponse } from "@app/types/job-answers.types";
import { api } from "./api";

const JOB_ANSWERS_BASE_URL = "/crafthunts/job_questions_answers";

export const getQuestionAnswer = async (questionId: string, lang: string, userId: string) => {
  const res = await api.get<JobAnswerApiResponse>(JOB_ANSWERS_BASE_URL, {
    params: { lang, jobQuestionId: questionId, workerProfileId: userId },
  });
  return res.data[0];
};

export const useQuestionAnswer = (params: {
  lang: string;
  questionId: string;
  workerProfileId: string;
}) => {
  return useQuery({
    queryKey: [JOB_ANSWERS_BASE_URL, params] as const,
    queryFn: ({ queryKey }) =>
      api
        .get<JobAnswerApiResponse>(JOB_ANSWERS_BASE_URL, {
          params: queryKey[1],
        })
        .then(({ data }) => data.find((x) => x.jobQuestionId === params.questionId) ?? null),
  });
};
