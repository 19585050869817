import Icon from "@ant-design/icons";
import { Avatar, Flex, Space, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { MdStar } from "react-icons/md";
import defaultProfilePicture from "@app/assets/profileImagePlaceholder.png";
import { useGetCandidateName } from "@app/components/pages/Candidates/hooks/useGetCandidateName";
import { VerifiedBadge } from "@app/components/ui/VerifiedBadge";
import { Colors } from "@app/constants/colors.constants";
import { ENV } from "@app/constants/env";
import { getTradeName } from "@app/helpers/tag.helper";
import { CandidateDef } from "@app/types/candidate.types";

type Props = {
  candidate: CandidateDef;
};

export const HeaderInfo = ({ candidate }: Props) => {
  const { t } = useTranslation();

  const { getCandidateName } = useGetCandidateName();

  const isUserDeleted = !!candidate.workerProfile.deletedAt;

  return (
    <Flex align="center" gap="small">
      <Space>
        <Avatar
          size={36}
          src={(!isUserDeleted && candidate.workerProfile.picture) || defaultProfilePicture}
        />
        <Flex vertical>
          {candidate.workerProfile.deletedAt ? (
            getCandidateName(candidate)
          ) : (
            <a
              rel="noreferrer"
              href={`${ENV.WEB_URL}/craftsmen/${
                candidate.workerProfile.slug || candidate.workerProfile.anonymousSlug
              }`}
              target="_blank"
            >
              {getCandidateName(candidate)}
            </a>
          )}
          <Flex wrap gap="small" align="center">
            {candidate.revealIdentity && candidate.workerProfile.jobTitle && (
              <Typography.Text type="secondary" style={{ fontSize: 14, fontWeight: 500 }}>
                {t(candidate.workerProfile.jobTitle.name)}
              </Typography.Text>
            )}
            <Flex
              wrap
              style={{
                position: "relative",
                top: "1px",
              }}
            >
              {candidate.workerProfile.profileTrades.map((trade) => (
                <Tag key={trade.id} color="orange">
                  {t(getTradeName(trade.name, candidate.workerProfile.jobTitle?.category))}
                </Tag>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Space>
      <Flex
        vertical
        gap="4px"
        align="flex-end"
        justify="center"
        style={{
          marginLeft: "auto",
          flexShrink: 0,
        }}
      >
        {candidate.workerProfile.isVerified && (
          <Flex align="center" gap="4px">
            <VerifiedBadge />
            <Typography.Text style={{ fontSize: 14, fontWeight: 400 }}>
              {t("verified-by-crafthunt")}
            </Typography.Text>
          </Flex>
        )}
        {candidate.isCrafthuntRecommended && (
          <Flex align="center" gap="4px">
            <Icon component={MdStar} style={{ color: Colors.primary }} />
            <Typography.Text style={{ fontSize: 14, fontWeight: 400 }}>
              {t("recommended-by-crafthunt")}
            </Typography.Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
