import { CameraOutlined, PhoneOutlined, TeamOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Modal, Form, Radio, Alert, Flex, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { postInterview, updateInterview, useInterviews } from "@app/api/interviews.api";
import { MomentCalendar, MomentDatePicker } from "@app/components/ui/DatePicker";
import { ECandidateInterviewType } from "@app/types/interviews.types";

import styles from "./InterviewModal.module.scss";

type Props = {
  open: boolean;
  candidateId?: string;
  onOk: () => void;
  onCancel: () => void;
};

export const InterviewModal = ({ open, candidateId, onOk, onCancel }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const interviewQuery = useInterviews(
    {
      candidateId,
    },
    {
      enabled: open,
    }
  );
  const interview = interviewQuery.data?.[0];

  const onSuccess = () => {
    interviewQuery.refetch();
    onOk();
  };

  const createInterview = useMutation({
    mutationFn: postInterview,
    onSuccess,
  });
  const updateInterviewMutation = useMutation({
    mutationFn: updateInterview,
    onSuccess,
  });

  const onFinish = (values: typeof initialValues) => {
    const finalDate = values.date.clone();
    const interviewTime = values.time;
    if (finalDate && interviewTime) {
      const hours = interviewTime.hour();
      const minutes = interviewTime.minute();
      finalDate.set({ hours, minutes });
    }

    const interviewData = {
      date: finalDate.toISOString(),
      type: values.type,
    };

    if (interview) {
      updateInterviewMutation.mutate({
        id: interview.id,
        ...interviewData,
      });
    } else {
      createInterview.mutate({
        candidateId: candidateId ?? "",
        ...interviewData,
      });
    }
  };

  const initialValues = {
    date: interview?.date ? moment(interview.date) : moment().add(1, "hour").startOf("hour"),
    time: interview?.date ? moment(interview.date) : moment().add(1, "hour").startOf("hour"),
    type: interview?.type ?? ECandidateInterviewType.VIDEO_CALL,
  };

  return (
    <Modal
      centered
      destroyOnClose
      loading={interviewQuery.isLoading}
      open={open}
      width={400}
      title={interview ? t("update-interview") : t("schedule-interview")}
      okText={interview ? t("update-interview") : t("schedule-interview")}
      okType="primary"
      cancelText={t("Cancel")}
      onOk={form.submit}
      onCancel={onCancel}
      closable={createInterview.isIdle && updateInterviewMutation.isIdle}
      confirmLoading={createInterview.isPending || updateInterviewMutation.isPending}
    >
      {interviewQuery.isError && <Alert showIcon type="error" message={t("unknown-error")} />}
      {interviewQuery.data && (
        <Form<typeof initialValues>
          clearOnDestroy
          name={`interview-form-${candidateId}`}
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={onFinish}
        >
          <Typography.Text type="secondary">{t("interview-schedule-description")}</Typography.Text>
          <Form.Item name="date">
            <MomentCalendar
              mode="month"
              fullscreen={false}
              className={styles.calendar}
              disabledDate={(current) => current && current < moment().startOf("day")}
            />
          </Form.Item>

          <Form.Item name="time" label={t("interview-time-label")}>
            <MomentDatePicker.TimePicker
              allowClear={false}
              format="HH:mm"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item name="type" label={t("interview-type-label")}>
            <Radio.Group
              block
              optionType="button"
              buttonStyle="solid"
              options={[
                {
                  label: t(`interview-type-${ECandidateInterviewType.VIDEO_CALL}`),
                  value: ECandidateInterviewType.VIDEO_CALL,
                  icon: <CameraOutlined />,
                },
                {
                  label: t(`interview-type-${ECandidateInterviewType.PHONE_CALL}`),
                  value: ECandidateInterviewType.PHONE_CALL,
                  icon: <PhoneOutlined />,
                },
                {
                  label: t(`interview-type-${ECandidateInterviewType.ON_SITE}`),
                  value: ECandidateInterviewType.ON_SITE,
                  icon: <TeamOutlined />,
                },
              ].map((option) => ({
                style: {
                  display: "flex",
                  padding: "0",
                  alignItems: "center",
                  justifyContent: "center",
                },
                label: (
                  <Flex
                    align="center"
                    style={{
                      gap: "4px",
                    }}
                  >
                    {option.icon}
                    {option.label}
                  </Flex>
                ),
                value: option.value,
              }))}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
