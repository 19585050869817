import { JobAnswerDef } from "@app/types/job-answers.types";
import {
  ANSWER_TYPE_MAP,
  EJobQuestionAnswerChoice,
  EJobQuestionAnswerType,
  EJobQuestionAnswerTypeCombined,
  EJobQuestionGoodNumberAnswerType,
  EJobQuestionOptionType,
  EJobQuestionType,
  JobQuestionDef,
  JobQuestionFormDef,
} from "@app/types/job-questions.types";
import { getKeys } from "./util.helper";

export const mapResponseToFormData = (item: JobQuestionDef): JobQuestionFormDef => {
  let answerTypeCombined = EJobQuestionAnswerTypeCombined.TEXT;

  if (item.answerType === EJobQuestionAnswerType.OPTION) {
    if (item.answerChoice === EJobQuestionAnswerChoice.MULTI) {
      answerTypeCombined = EJobQuestionAnswerTypeCombined.OPTION_MULTI;
    } else if (item.options.find((option) => option.type === EJobQuestionOptionType.BOOLEAN_TRUE)) {
      answerTypeCombined = EJobQuestionAnswerTypeCombined.BOOLEAN;
    } else {
      answerTypeCombined = EJobQuestionAnswerTypeCombined.OPTION_SINGLE;
    }
  } else {
    const foundAnswerTypeCombined = getKeys(ANSWER_TYPE_MAP).find(
      (key) => ANSWER_TYPE_MAP[key] === item.answerType
    );

    answerTypeCombined = foundAnswerTypeCombined || EJobQuestionAnswerTypeCombined.TEXT;
  }

  return {
    ...item,
    answerTypeCombined,
  };
};

export const mapFormDataToApi = (
  question: JobQuestionFormDef
): Omit<JobQuestionDef, "id"> & {
  id?: string | undefined;
} => {
  const answerType = question.answerType;
  const isKnockout = question.type === EJobQuestionType.KNOCKOUT;

  return {
    ...question,
    goodNumberAnswerType:
      isKnockout && answerType === EJobQuestionAnswerType.NUMBER
        ? question.goodNumberAnswerType
        : null,
    goodNumberAnswerValue:
      isKnockout && answerType === EJobQuestionAnswerType.NUMBER
        ? question.goodNumberAnswerValue
        : null,
    options:
      answerType === EJobQuestionAnswerType.OPTION
        ? question.options?.map((option) => {
            return {
              ...option,
              isGoodAnswer: isKnockout ? option.isGoodAnswer : false,
            };
          })
        : [],
  };
};

export const isAnswerMatchingKnockoutQuestion = (
  answerData: JobAnswerDef,
  question: JobQuestionDef
): boolean => {
  if (question.answerType === EJobQuestionAnswerType.NUMBER) {
    if (question.goodNumberAnswerValue == null) {
      return false;
    } else if (question.goodNumberAnswerType === EJobQuestionGoodNumberAnswerType.MORE_THAN) {
      return question.goodNumberAnswerValue < Number(answerData.answer);
    } else if (question.goodNumberAnswerType === EJobQuestionGoodNumberAnswerType.LESS_THAN) {
      return question.goodNumberAnswerValue > Number(answerData.answer);
    }
  } else if (question.answerType === EJobQuestionAnswerType.OPTION) {
    const goodAnswerOptions = question.options.filter((option) => option.isGoodAnswer);

    return (
      goodAnswerOptions.length === answerData.options.length &&
      goodAnswerOptions.every((option) => {
        return answerData.options.some((answerOption) => answerOption.id === option.id);
      })
    );
  }

  return true;
};
