import { Tag, Tooltip } from "antd";
import { useAdminsSA } from "@app/features/super-admin/api/super-admin.admin.api";
import { EJobRelevanceFeedbackType } from "@app/features/super-admin/types/super-admin.job-relevance-feedback.types";
import { JobRelevanceFeedbackDef } from "@app/features/super-admin/types/super-admin.user.types";

type JobRelevanceFeedbackProps = {
  jobRelevanceFeedback: JobRelevanceFeedbackDef | null | undefined;
};

export const JobRelevanceFeedback = ({ jobRelevanceFeedback }: JobRelevanceFeedbackProps) => {
  const adminsQuery = useAdminsSA();
  const admin = adminsQuery.data?.find(
    (admin) => admin.id === jobRelevanceFeedback?.createdBySuperAdminId
  );

  const feedbackName =
    jobRelevanceFeedback?.type === EJobRelevanceFeedbackType.RELEVANT
      ? "Interested"
      : "Not interested";

  const feedbackColor =
    jobRelevanceFeedback?.type === EJobRelevanceFeedbackType.RELEVANT ? "green" : "red";

  if (!jobRelevanceFeedback) return <>-</>;

  return (
    <Tooltip
      title={
        <>
          {!!jobRelevanceFeedback.description && (
            <>
              {jobRelevanceFeedback.description}
              <br />
              <br />
            </>
          )}
          From: {admin?.firstName || "User"}
        </>
      }
    >
      <Tag
        style={{
          display: "block",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
          width: "max-content",
          margin: 0,
          color: feedbackColor,
        }}
      >
        {feedbackName}
      </Tag>
    </Tooltip>
  );
};
