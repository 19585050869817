import { createModel } from "@rematch/core";
import axios from "axios";
import { getCompanyInfo, putCompanyInfo, updateApprovalStatus } from "@app/api/company-info.api";
import {
  ApprovalStatusEnum,
  CompanyInfoType,
  EEmbeddedCrafthuntLinkStatus,
  UpdateApprovalStatus,
} from "@app/types/company-info.types";
import { RootModel } from "./models";

export const companyInfo = createModel<RootModel>()({
  state: {
    companyInfo: {
      id: "",
      name: "",
      lang: "",
      description: "",
      logo: null,
      coverImage: null,
      address: {
        street: "",
        city: "",
        postalCode: "",
        country: "",
      },
      coordinates: { lat: 0, lng: 0 },
      approvalStatus: ApprovalStatusEnum.NOT_STARTED,
      hasPaidForJobAds: false,
      hasIntegrations: false,
      companyIndustriesId: [],
      enabledAdvertisements: [],
      embeddedCrafthuntLinkStatus: EEmbeddedCrafthuntLinkStatus.NONE,
      externalLinks: [],
    } as CompanyInfoType,
    slug: "",
  },
  reducers: {
    updateCompanyInfo: (state, payload: CompanyInfoType) => {
      state.companyInfo = payload;
      return state;
    },
    fetchedCompanyInfo: (state, payload: CompanyInfoType) => {
      state.companyInfo = payload;
      return state;
    },
    fetchedSlug: (state, payload: string) => {
      state.slug = payload;
      return state;
    },
  },
  effects: (dispatch) => ({
    getCompanyInfo: async (payload: string) => {
      try {
        const result = await getCompanyInfo(payload);
        dispatch.companyInfo.fetchedSlug(result.slug);
        dispatch.companyInfo.fetchedCompanyInfo(result.company);
        return result;
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 401) {
          // Token has expired
          dispatch.auth.logout();
        }
        throw e;
      }
    },
    updateCompanyInfoAction: async (payload: { info: CompanyInfoType }) => {
      // Api call goes here
      const result = await putCompanyInfo(payload);
      dispatch.companyInfo.updateCompanyInfo(result);
    },
    updateApprovalStatusAction: async (payload: UpdateApprovalStatus) => {
      // Api call goes here
      const result = await updateApprovalStatus(payload);
      dispatch.companyInfo.updateCompanyInfo(result);
    },
  }),
});
