import { ProfileDocumentDef } from "@app/types/candidate.types";
import {
  OrderByRequestDef,
  PaginationRequestDef,
  PaginationResponseDef,
} from "@app/types/pagination.types";

export enum ECVParserStatus {
  QUEUED = "queued",
  PROCESSING = "processing",
  COMPLETED = "completed",
  FAILED = "failed",
}

export type GetCVParserProcessSAParamsDef = PaginationRequestDef &
  OrderByRequestDef & {
    workerProfileId: string;
  };

export interface CVParserProcessSADef {
  id: string;
  status: ECVParserStatus;
  lang: string;
  medias: ProfileDocumentDef[];
  processStartedAt: string;
  workerProfileId: string;
  version: number;
  createdAt: string;
  updatedAt: string;
}

export type CVParserProcessSAWithPaginationDef = PaginationResponseDef<CVParserProcessSADef>;
