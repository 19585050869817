import { useQuery } from "@tanstack/react-query";
import { mapFormDataToApi, mapResponseToFormData } from "@app/helpers/job-questions.helper";
import {
  BulkUpdateJobQuestionsDef,
  JobQuestionApiResponse,
  JobQuestionFormDef,
} from "@app/types/job-questions.types";
import { api } from "./api";

const JOB_QUESTIONS_BASE_URL = "/crafthunts/job_questions";

export const getCandidateQuestions = async (lang: string, userId: string, companyId?: string) => {
  const res = await api.get<JobQuestionApiResponse>(JOB_QUESTIONS_BASE_URL, {
    params: { lang, workerProfileId: userId, companyId },
  });
  return res.data;
};

export const useJobQuestions = (params: {
  lang: string;
  userId?: string;
  companyId?: string;
  jobAdId?: string;
}) => {
  return useQuery({
    queryKey: [JOB_QUESTIONS_BASE_URL, params] as const,
    queryFn: ({ queryKey }) =>
      api
        .get<JobQuestionApiResponse>(JOB_QUESTIONS_BASE_URL, {
          params: queryKey[1],
        })
        .then(({ data }) => data),
  });
};

export const getJobQuestionsByJobAdId = async (jobAdId: string): Promise<JobQuestionFormDef[]> => {
  const response = await api.get<JobQuestionApiResponse>(JOB_QUESTIONS_BASE_URL, {
    params: { jobAdId },
  });
  return response.data.map((item) => mapResponseToFormData(item));
};

export const bulkUpdateJobQuestions = async (
  payload: BulkUpdateJobQuestionsDef
): Promise<JobQuestionFormDef[]> => {
  const response = await api.post<JobQuestionApiResponse>(`${JOB_QUESTIONS_BASE_URL}/bulk`, {
    ...payload,
    items: payload.items.map((item) => mapFormDataToApi(item)),
  });
  return response.data.map((item) => mapResponseToFormData(item));
};
