export enum ECandidateInterviewType {
  ON_SITE = "on-site",
  PHONE_CALL = "phone-call",
  VIDEO_CALL = "video-call",
}

export type CandidateInterviewDef = {
  id: string;
  candidateId: string;
  date: string;
  type: ECandidateInterviewType;
};
