import { Input, Modal } from "antd";
import { useState } from "react";

type JobRelevanceFeedbackNoteModalProps = {
  onCancel: () => void;
  onOk: (note: string | undefined) => void;
  open: boolean;
};

export const JobRelevanceFeedbackNoteModal = ({
  onCancel,
  onOk,
  open,
}: JobRelevanceFeedbackNoteModalProps) => {
  const [note, setNote] = useState<string | undefined>(undefined);

  return (
    <Modal
      open={open}
      title="Why is this job not interesting for the user?"
      okText="Ok"
      onCancel={onCancel}
      onOk={() => onOk(note)}
      okButtonProps={{ disabled: note?.trim().length === 0 }}
    >
      <Input.TextArea
        required
        autoSize={{ minRows: 4, maxRows: 10 }}
        onChange={(e) => {
          setNote(e.target.value);
        }}
      />
    </Modal>
  );
};
