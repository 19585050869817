import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import {
  EJobQuestionAnswerType,
  EJobQuestionGoodNumberAnswerType,
  EJobQuestionType,
  JobQuestionsFormDef,
} from "@app/types/job-questions.types";

export const KnockoutNumberAnswer = ({
  form,
  fieldName,
}: {
  fieldName: number;
  form: FormInstance<JobQuestionsFormDef>;
}) => {
  const { t } = useTranslation();

  const answerType = Form.useWatch(["questions", fieldName, "answerType"], form);
  const type = Form.useWatch(["questions", fieldName, "type"], form);

  if (answerType !== EJobQuestionAnswerType.NUMBER || type !== EJobQuestionType.KNOCKOUT) {
    return null;
  }

  return (
    <Row gutter={[8, 8]}>
      <Col span={12}>
        <Form.Item
          name={[fieldName, "goodNumberAnswerType"]}
          rules={[{ required: true, message: t("Required") }]}
          label={t("preferred-answer-type")}
        >
          <Select
            options={Object.values(EJobQuestionGoodNumberAnswerType).map((item) => ({
              value: item,
              label: t(item),
            }))}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={[fieldName, "goodNumberAnswerValue"]}
          rules={[{ required: true, message: t("Required") }]}
          normalize={(value) => (value ? Number(value) : undefined)}
          label={t("preferred-answer-value")}
        >
          <Input type="number" />
        </Form.Item>
      </Col>
    </Row>
  );
};
