import { App, FormInstance } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getIntegrationStatusMapping } from "@app/api/integration-status.api";
import { getJobAd } from "@app/api/job-ads.api";
import { getJobQuestionsByJobAdId } from "@app/api/job-questions.api";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { ModelNames } from "@app/store/models/models";
import { RootState } from "@app/store/store";
import { EJobAdStatus, JobAdDef, JobAdFormDef } from "@app/types/job-ads.types";
import { JobQuestionsFormDef } from "@app/types/job-questions.types";

export const useFetchJobAdById = (
  id: string | undefined,
  jobAdForm: FormInstance<JobAdFormDef>,
  questionsForm: FormInstance<JobQuestionsFormDef>,
  isCreateNewJobAd: boolean,
  isDuplicateJobAd: boolean
) => {
  const { message } = App.useApp();
  const hasIntegrations = useSelector(
    (state: RootState) => state.companyInfo.companyInfo.hasIntegrations
  );
  const [jobAdData, setJobAdData] = useState<JobAdDef>();
  const [isFetchingData, setIsFetchingData] = useState(!isCreateNewJobAd || isDuplicateJobAd);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAndSetJobAdById = async (id: string) => {
      // Fetch job ad
      const fetchedJobAd = await getJobAd(id);
      if (isDuplicateJobAd) {
        fetchedJobAd.id = "";
        fetchedJobAd.slug = "";
        fetchedJobAd.unpublishedAt = null;
        fetchedJobAd.status = EJobAdStatus.DRAFT;
      }
      setJobAdData(fetchedJobAd);
      return fetchedJobAd;
    };

    const fetchJobQuestions = async (jobAdId: string) => {
      const fetchedJobQuestions = await getJobQuestionsByJobAdId(jobAdId);
      if (isDuplicateJobAd) {
        fetchedJobQuestions.forEach((item) => {
          item.id = undefined;

          item.options = item.options.map((option) => ({
            ...option,
            id: undefined,
          }));
        });
      }
      return fetchedJobQuestions;
    };

    const fetchAndSetATSIntegrationStatusMapping = async (jobAdId: string) => {
      // Fetch integration status mapping
      const integrationStatusMapping = await getIntegrationStatusMapping({
        jobAdId,
      });
      if (integrationStatusMapping?.length) {
        return integrationStatusMapping[0];
      }
      return undefined;
    };

    const fetchAndSetJobAdFormsByJobAdId = async (jobAdId: string) => {
      try {
        const [fetchedJobAdData, fetchedJobQuestions, fetchedIntegrationStatus] = await Promise.all(
          [
            fetchAndSetJobAdById(jobAdId),
            fetchJobQuestions(jobAdId),
            hasIntegrations ? fetchAndSetATSIntegrationStatusMapping(jobAdId) : undefined,
          ]
        );

        if (fetchedJobAdData) {
          jobAdForm.setFieldsValue({
            ...fetchedJobAdData,
            ...(fetchedIntegrationStatus
              ? {
                  integrationStatusMapId: fetchedIntegrationStatus.integrationStageId,
                  integrationStatusMap: fetchedIntegrationStatus,
                }
              : {}),
          });
          questionsForm.setFieldsValue({
            documentsRequired: fetchedJobAdData.documentsRequired,
            ...(fetchedJobQuestions ? { questions: fetchedJobQuestions } : {}),
          });
        }
      } catch {
        message.error({ content: t("Sorry, something went wrong."), key: ModelNames.JOB_ADS });
        navigate(RoutePaths.JOB_ADS, { replace: true });
      } finally {
        setIsFetchingData(false);
      }
    };
    if (typeof id === "string" && (!isCreateNewJobAd || isDuplicateJobAd)) {
      fetchAndSetJobAdFormsByJobAdId(id);
    }
  }, [id, isDuplicateJobAd]);

  return { jobAdData, setJobAdData, isFetchingData };
};
