import { Form, Input, Modal, Select } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { EJobUnpublishReason, JobUnpublishFormDef } from "@app/types/job-ads.types";

type UnpublishReasonModalProps = {
  open: boolean;
  onUnpublish: (data: JobUnpublishFormDef) => void;
  onCancel: () => void;
};

export const UnpublishReasonModal = ({
  open,
  onUnpublish,
  onCancel,
}: UnpublishReasonModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm<JobUnpublishFormDef>();
  const INITIAL_DATA: JobUnpublishFormDef = {
    unpublishReason: undefined,
    unpublishReasonDescription: undefined,
  };
  const currentUnpublishReason = useWatch("unpublishReason", form);
  const currentUnpublishReasonDescription = useWatch("unpublishReasonDescription", form);
  const canSave =
    currentUnpublishReason === EJobUnpublishReason.OTHER
      ? currentUnpublishReasonDescription != null &&
        currentUnpublishReasonDescription.trim().length > 0
      : currentUnpublishReason != null;

  const handleFinish = async (values: JobUnpublishFormDef) => {
    onUnpublish(values);
    form.resetFields();
  };

  const handleOnCancel = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      initialValues={INITIAL_DATA}
      name="unpublish-form"
      data-hs-do-not-collect="true"
    >
      <Modal
        title={t("Are you sure you want to unpublish the job ad?")}
        open={open}
        onOk={() => handleFinish(form.getFieldsValue())}
        onCancel={handleOnCancel}
        cancelText={t("Cancel")}
        okText={t("Unpublish")}
        okType="danger"
        okButtonProps={{ disabled: !canSave }}
      >
        <Form.Item
          label={t("unpublish-reason-select-title")}
          name="unpublishReason"
          style={{ marginBottom: 16 }}
          wrapperCol={{ span: 24 }}
          required
        >
          <SelectAutocomplete sortAlphabetically={false}>
            {Object.values(EJobUnpublishReason).map((reason) => {
              return (
                <Select.Option key={reason} value={reason} label={t(reason)}>
                  {t(reason)}
                </Select.Option>
              );
            })}
          </SelectAutocomplete>
        </Form.Item>
        {currentUnpublishReason === EJobUnpublishReason.OTHER && (
          <Form.Item
            label={t("unpublish-reason-other-title")}
            name="unpublishReasonDescription"
            style={{ marginBottom: 16 }}
            wrapperCol={{ span: 24 }}
            required
          >
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 4, maxRows: 4 }}
              style={{ width: "100%" }}
              required
            />
          </Form.Item>
        )}
      </Modal>
    </Form>
  );
};
