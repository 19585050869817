export enum EJobQuestionAnswerType {
  TEXT = "text",
  NUMBER = "number",
  OPTION = "option",
}
export enum EJobQuestionAnswerChoice {
  SINGLE = "single",
  MULTI = "multi",
}
export enum EJobQuestionType {
  OPTIONAL = "optional",
  REQUIRED = "required",
  KNOCKOUT = "knockout",
}
export enum EJobQuestionGoodNumberAnswerType {
  MORE_THAN = "more-than",
  LESS_THAN = "less-than",
}
export const EJobQuestionTypeTranslations = Object.freeze({
  [EJobQuestionType.OPTIONAL]: "job-question-type-optional",
  [EJobQuestionType.REQUIRED]: "job-question-type-required",
  [EJobQuestionType.KNOCKOUT]: "job-question-type-knockout",
});

export enum EJobQuestionOptionType {
  TEXT = "text",
  IMAGE = "image",
  BOOLEAN_TRUE = "true",
  BOOLEAN_FALSE = "false",
}

export type JobQuestionOptionDef = {
  id?: string;
  text: string;
  image?: string | null;
  type: EJobQuestionOptionType;
  order?: number;
  isGoodAnswer?: boolean | null;
};

export interface JobQuestionDef {
  id: string;
  jobAdId: string;
  crafthuntCompanyId: string;
  title: string;
  order: number;
  type: EJobQuestionType;
  answerType: EJobQuestionAnswerType;
  answerChoice: EJobQuestionAnswerChoice;
  options: JobQuestionOptionDef[];
  createdAt: string;
  updatedAt: string;
  goodNumberAnswerType?: EJobQuestionGoodNumberAnswerType | null;
  goodNumberAnswerValue?: number | null;
}

export type BulkUpdateJobQuestionsDef = {
  jobAdId: string;
  items: JobQuestionFormDef[];
  lang: string;
};

export type JobQuestionApiResponse = JobQuestionDef[];

/**
 * Internal mapping of answer types and choices
 */
export enum EJobQuestionAnswerTypeCombined {
  TEXT = "answer-type-text",
  BOOLEAN = "answer-type-boolean",
  NUMBER = "answer-type-number",
  OPTION_SINGLE = "answer-type-option-single",
  OPTION_MULTI = "answer-type-option-multi",
}
export const ANSWER_TYPE_MAP = Object.freeze({
  [EJobQuestionAnswerTypeCombined.TEXT]: EJobQuestionAnswerType.TEXT,
  [EJobQuestionAnswerTypeCombined.NUMBER]: EJobQuestionAnswerType.NUMBER,
  [EJobQuestionAnswerTypeCombined.BOOLEAN]: EJobQuestionAnswerType.OPTION,
  [EJobQuestionAnswerTypeCombined.OPTION_SINGLE]: EJobQuestionAnswerType.OPTION,
  [EJobQuestionAnswerTypeCombined.OPTION_MULTI]: EJobQuestionAnswerType.OPTION,
});
export const ANSWER_CHOICE_MAP = Object.freeze({
  [EJobQuestionAnswerTypeCombined.TEXT]: EJobQuestionAnswerChoice.SINGLE,
  [EJobQuestionAnswerTypeCombined.NUMBER]: EJobQuestionAnswerChoice.SINGLE,
  [EJobQuestionAnswerTypeCombined.BOOLEAN]: EJobQuestionAnswerChoice.SINGLE,
  [EJobQuestionAnswerTypeCombined.OPTION_SINGLE]: EJobQuestionAnswerChoice.SINGLE,
  [EJobQuestionAnswerTypeCombined.OPTION_MULTI]: EJobQuestionAnswerChoice.MULTI,
});

export type JobQuestionFormDef = Omit<JobQuestionDef, "id"> & {
  id?: string;
  answerTypeCombined: EJobQuestionAnswerTypeCombined;
};
export type JobQuestionsFormDef = {
  documentsRequired: boolean;
  questions: JobQuestionFormDef[];
};
