import { UniqueIdentifier } from "@dnd-kit/core";
import { orderBy, uniqueId } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { sendChatMessage } from "@app/api/chat-messages.api";
import { SpecialJobSelectValue } from "@app/components/pages/Candidates/Candidates";
import {
  CANDIDATE_DRAWER_TAB_KEYS,
  CandidateDrawer,
} from "@app/components/pages/Candidates/components/CandidateDrawer/CandidateDrawer";
import { FirstMessageModal } from "@app/components/pages/Candidates/components/FirstMessageModal/FirstMessageModal";
import { HiringModal } from "@app/components/pages/Candidates/components/HiringModal/HiringModal";
import { InterviewModal } from "@app/components/pages/Candidates/components/InterviewModal/InterviewModal";
import { RejectionModal } from "@app/components/pages/Candidates/components/RejectionModal/RejectionModal";
import { triggerEventAcceptRecommendedCandidate } from "@app/components/pages/Candidates/helpers/candidates-analytics.helper";
import { useGetCandidateName } from "@app/components/pages/Candidates/hooks/useGetCandidateName";
import { Kanban } from "@app/features/kanban/components/Kanban/Kanban";
import { RootState, store } from "@app/store/store";
import {
  CandidateDef,
  CandidateRejectionFormDef,
  CompanyCandidateStatusDef,
  ECandidateMethod,
  ECandidateRejectionReason,
  ECandidateStatus,
  ECandidateStatusType,
} from "@app/types/candidate.types";
import { CandidateCardContent } from "./components/CandidateCardContent/CandidateCardContent";
import { ContainerHeader } from "./components/ContainerHeader/ContainerHeader";

type CandidatesKanbanProps = {
  currentJobAdId?: string;
  searchValue: string;
};
export const CandidatesKanban = ({ currentJobAdId, searchValue }: CandidatesKanbanProps) => {
  const { getCandidateName } = useGetCandidateName();
  const candidateStatuses = useSelector((state: RootState) => state.candidates.candidateStatuses);
  const [dataKey, setDataKey] = useState(uniqueId);
  const candidates = useSelector((state: RootState) => state.candidates.candidates);
  const { dispatch } = store;
  const [rejectedCandidateIsFromRecommended, setRejectedCandidateIsFromRecommended] =
    useState(false);
  const [showModal, setShowModal] = useState<
    null | "rejection" | "message" | "hiring" | "interview"
  >(null);
  const [errorSendFirstMessage, setErrorSendFirstMessage] = useState(false);
  const [nextStatusId, setNextStatusId] = useState<string>();
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateDef | undefined>();
  const [showCandidateDrawer, setShowCandidateDrawer] = useState({
    visible: false,
    tab: CANDIDATE_DRAWER_TAB_KEYS.INFO,
  });

  const isAllJobSelected =
    currentJobAdId === SpecialJobSelectValue.ALL ||
    currentJobAdId === SpecialJobSelectValue.CONTACTED;

  const containers = useMemo(() => candidateStatuses.map((item) => item.id), [candidateStatuses]);
  const recommendedStatus = useMemo(
    () => candidateStatuses.find((item) => item.status === ECandidateStatus.RECOMMENDED),
    [candidateStatuses]
  );

  const allCandidatesSorted = useMemo(
    () => orderBy(candidates, (candidate) => candidate.createdAt, "desc"),
    [candidates]
  );

  const candidateStatusesMap = useMemo(
    () => new Map(candidateStatuses.map((status) => [status.id, status])),
    [candidateStatuses]
  );

  const candidatesMap = useMemo(
    () =>
      new Map<
        UniqueIdentifier,
        CandidateDef & {
          companyCandidateStatusType?: ECandidateStatusType;
        }
      >(
        allCandidatesSorted.map((candidate) => [
          candidate.id,
          {
            ...candidate,
            companyCandidateStatusType: candidateStatusesMap.get(
              candidate.companyCandidateStatus.id
            )?.type,
          },
        ])
      ),
    [candidates]
  );

  const candidateForDrawer = selectedCandidate
    ? candidatesMap.get(selectedCandidate.id)
    : undefined;

  const candidateIdsByStatus = useMemo(() => {
    return candidateStatuses.reduce((result, status) => {
      result[status.id] = allCandidatesSorted
        .filter((candidate) => {
          const userFullName = getCandidateName(candidate);
          const search = searchValue.toLowerCase();

          const candidateIsMatchingSearch =
            userFullName.toLowerCase().includes(search) ||
            candidate.workerProfile.email?.toLowerCase().includes(search) ||
            candidate.workerProfile.phone?.toLowerCase().includes(search);

          return candidate.companyCandidateStatus.id === status.id && candidateIsMatchingSearch;
        })
        .map((candidate) => candidate.id);
      return result;
    }, {} as Record<string, string[]>);
  }, [candidateStatuses, allCandidatesSorted, searchValue, dataKey]);

  const handleClickCandidate = useCallback((candidate: CandidateDef) => {
    setSelectedCandidate(candidate);
    setShowCandidateDrawer({
      visible: true,
      tab: CANDIDATE_DRAWER_TAB_KEYS.INFO,
    });
  }, []);

  const handleClickCandidateChat = useCallback((candidate: CandidateDef) => {
    setSelectedCandidate(candidate);
    setShowCandidateDrawer({
      visible: true,
      tab: CANDIDATE_DRAWER_TAB_KEYS.CHAT,
    });
  }, []);

  const sortFunction = (items: UniqueIdentifier[]) => {
    return allCandidatesSorted.map((candidate) => candidate.id).filter((id) => items.includes(id));
  };

  const saveCandidate = async (
    candidateId: string,
    newStatus: string,
    rejectionMessage?: string,
    rejectionReason?: ECandidateRejectionReason,
    rejectionReasonDescription?: string,
    handleRejection?: boolean
  ) => {
    await dispatch.candidates.updateCandidateAction({
      candidateId,
      candidateData: {
        companyCandidateStatusId: newStatus,
        rejectionMessage,
        rejectionReason,
        rejectionReasonDescription,
        handleRejection,
      },
    });
  };

  const handleOnHire = () => {
    const candidateStatus = candidateStatuses.find(
      (item) => item.status === ECandidateStatus.HIRED
    );
    if (candidateStatus && selectedCandidate) {
      saveCandidate(selectedCandidate.id, candidateStatus.id);
    }
    setShowModal(null);
  };

  const handleOnInterview = () => {
    if (nextStatusId && selectedCandidate) {
      saveCandidate(selectedCandidate.id, nextStatusId);
      setShowModal(null);
    }
  };

  const handleOnReject = (data: CandidateRejectionFormDef) => {
    const candidateStatus = candidateStatuses.find(
      (item) => item.status === ECandidateStatus.REJECTED
    );
    if (candidateStatus && selectedCandidate) {
      saveCandidate(
        selectedCandidate?.id,
        candidateStatus.id,
        data.rejectionMessage,
        data.rejectionReason,
        data.rejectionReasonDescription,
        !rejectedCandidateIsFromRecommended
      );
    }
    setShowModal(null);
  };

  const handleOnModalCancel = () => {
    setShowModal(null);
    setDataKey(uniqueId);
  };

  const handleSendFirstMessage = async (message: string) => {
    try {
      if (nextStatusId && selectedCandidate) {
        await saveCandidate(selectedCandidate?.id, nextStatusId);
        sendChatMessage(message, selectedCandidate.id);
        triggerEventAcceptRecommendedCandidate(selectedCandidate.id);
      }
      setShowModal(null);
    } catch (error) {
      console.error(error);
      setErrorSendFirstMessage(true);
    }
  };

  const handleOnDropped = (containerId: UniqueIdentifier, itemId: UniqueIdentifier) => {
    const candidateStatus = candidateStatuses.find((item) => item.id === containerId);
    const candidate = candidates.find((item) => item.id === itemId);

    if (candidate?.companyCandidateStatus.id === containerId || !candidateStatus || !candidate) {
      return;
    }

    if (
      candidateStatus.status === ECandidateStatus.RECOMMENDED &&
      candidate.companyCandidateStatus.status !== ECandidateStatus.RECOMMENDED
    ) {
      return;
    }

    setSelectedCandidate(candidate);
    if (
      candidateStatus.status !== ECandidateStatus.REJECTED &&
      candidate.companyCandidateStatus.status === ECandidateStatus.RECOMMENDED
    ) {
      setNextStatusId(candidateStatus.id);
      setShowModal("message");
    } else if (candidateStatus.status === ECandidateStatus.REJECTED) {
      setRejectedCandidateIsFromRecommended(
        candidate.companyCandidateStatus.status === ECandidateStatus.RECOMMENDED
      );
      setShowModal("rejection");
    } else if (candidateStatus.status === ECandidateStatus.HIRED) {
      setShowModal("hiring");
    } else if (candidateStatus.type === ECandidateStatusType.INTERVIEW) {
      setNextStatusId(candidateStatus.id);
      setShowModal("interview");
    } else {
      saveCandidate(candidate.id, candidateStatus.id);
    }
  };

  useEffect(() => {
    const loadCandidatesForStatus = async (candidateStatus: CompanyCandidateStatusDef) => {
      dispatch.candidates.setCandidateStatusLoading({
        id: candidateStatus.id,
        loading: true,
      });
      const jobAdId = !currentJobAdId || isAllJobSelected ? undefined : [currentJobAdId];
      const method =
        currentJobAdId === SpecialJobSelectValue.CONTACTED ? ECandidateMethod.CONTACTED : undefined;
      await dispatch.candidates.getAllCandidates({
        jobAdIds: jobAdId,
        companyCandidateStatusId: candidateStatus.id,
        method,
      });
      dispatch.candidates.setCandidateStatusLoading({
        id: candidateStatus.id,
        loading: false,
      });
    };

    const loadCandidates = async () => {
      await dispatch.candidates.clearAllCandidatesAction();
      for (const candidateStatus of candidateStatuses) {
        loadCandidatesForStatus(candidateStatus);
      }
    };
    loadCandidates();
  }, [currentJobAdId, isAllJobSelected, JSON.stringify(candidateStatuses), searchValue]);

  return (
    <>
      <div
        style={{
          height: "100%",
        }}
      >
        <Kanban
          onDropped={handleOnDropped}
          items={candidateIdsByStatus}
          containers={containers}
          sortFunction={sortFunction}
          canDrop={(sourceId, targetId, itemId) => {
            return (
              targetId !== recommendedStatus?.id ||
              candidateIdsByStatus[recommendedStatus.id]?.includes(itemId as string)
            );
          }}
          renderContainer={(containerId) => <ContainerHeader containerId={containerId} />}
          renderItem={(value) => {
            const candidate = candidatesMap.get(value);
            if (!candidate) {
              return <></>;
            }
            return (
              <CandidateCardContent
                candidate={candidate}
                companyCandidateStatusType={candidate.companyCandidateStatusType}
                showJobName={isAllJobSelected}
                onClick={handleClickCandidate}
                onChatClick={handleClickCandidateChat}
              />
            );
          }}
        />
      </div>
      {candidateForDrawer && (
        <CandidateDrawer
          key={candidateForDrawer.id}
          open={showCandidateDrawer.visible}
          defaultTab={showCandidateDrawer.tab}
          candidate={candidateForDrawer}
          companyCandidateStatusType={candidateForDrawer.companyCandidateStatusType}
          onInterviewEdit={() => {
            setSelectedCandidate(candidateForDrawer);
            setShowModal("interview");
          }}
          onClose={() =>
            setShowCandidateDrawer((state) => ({
              ...state,
              visible: false,
            }))
          }
        />
      )}
      <FirstMessageModal
        open={showModal === "message"}
        onCancel={handleOnModalCancel}
        onSend={handleSendFirstMessage}
        hasError={errorSendFirstMessage}
      />
      <RejectionModal
        open={showModal === "rejection"}
        isFromRecommendedStatus={rejectedCandidateIsFromRecommended}
        onCancel={handleOnModalCancel}
        onReject={handleOnReject}
      />
      <HiringModal
        open={showModal === "hiring"}
        onCancel={handleOnModalCancel}
        onHire={handleOnHire}
      />
      <InterviewModal
        candidateId={selectedCandidate?.id}
        open={showModal === "interview"}
        onCancel={handleOnModalCancel}
        onOk={handleOnInterview}
      />
    </>
  );
};
