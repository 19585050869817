import { UploadFileDef } from "@app/features/super-admin/types/super-admin.files.types";
import { ProfileDocumentDef } from "@app/types/candidate.types";

export const mapFilesToApi = (files: UploadFileDef[]): ProfileDocumentDef[] => {
  return files.map(
    (file) =>
      ({
        id: file.uid,
        url: file.url,
        filename: file.name,
        type: file.type,
      } as ProfileDocumentDef)
  );
};
export const mapApiFilesToForm = (files: ProfileDocumentDef[]): UploadFileDef[] => {
  return files.map((file) => ({
    uid: file.id,
    url: file.url,
    name: file.filename || file.url,
    type: file.type,
    createdAt: file.createdAt,
  }));
};
