import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Alert, App, Button, Spin } from "antd";
import { candidatesQueryKey } from "@app/features/super-admin/api/super-admin.candidate.api";
import { progressQueryKey } from "@app/features/super-admin/api/super-admin.progress.api";
import { updateUserSA, useUserSA } from "@app/features/super-admin/api/super-admin.user.api";

type UserUnqualifiedFormProps = {
  userId: string;
};

export const UserUnqualifiedButton = ({ userId }: UserUnqualifiedFormProps) => {
  const { modal } = App.useApp();
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  const userQuery = useUserSA({
    userId,
  });

  const isUnqualified = userQuery.data?.isUnqualified;

  const updateUser = useMutation({
    mutationFn: updateUserSA,
    onSuccess: () => {
      message.success("User marked as unqualified");
    },
    onSettled: () => {
      userQuery.refetch();
      queryClient.invalidateQueries({
        queryKey: progressQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: candidatesQueryKey,
      });
    },
  });

  if (userQuery.isLoading) {
    return <Spin />;
  }
  if (userQuery.error) {
    return <Alert type="error" message="Failed to load user data" />;
  }
  return (
    <Button
      loading={updateUser.isPending}
      onClick={() => {
        const mutation = () => {
          updateUser.mutate({
            id: userId,
            isUnqualified: !isUnqualified,
          });
        };

        if (isUnqualified) {
          // Un-mark user as unqualified
          mutation();
          return;
        }
        modal.confirm({
          title: "Mark user as unqualified",
          content: (
            <>
              <p>Are you sure you want to mark this user as unqualified?</p>
              <p>
                This action will delete all current applications and hide the user until they apply
                again to a job or a good match is found.
              </p>
            </>
          ),
          okText: "Yes",
          cancelText: "Cancel",
          onOk: mutation,
        });
      }}
    >
      {isUnqualified ? "❌ Unqualified" : "🚫 Mark as unqualified"}
    </Button>
  );
};
