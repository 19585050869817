import { PlusCircleOutlined, TranslationOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  Alert,
  App,
  Badge,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getJobAds, useJobById } from "@app/api/job-ads.api";
import { useWorkerProfile } from "@app/api/worker-profile.api";
import { MarkdownRenderer } from "@app/components/ui/Markdown/MarkdownRenderer";
import { Section } from "@app/components/ui/Section";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { getJobPageUrl } from "@app/helpers/job-ads.helper";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { CandidateDef, UpdateCandidateDef } from "@app/types/candidate.types";
import { EJobAdStatus, JobAdDef } from "@app/types/job-ads.types";
import { ScreeningQuestions } from "./ScreeningQuestions";

type JobsSelectProps = {
  candidate: CandidateDef;
  updateCandidate: (payload: UpdateCandidateDef) => void;
};

export const JobsList = ({ candidate, updateCandidate }: JobsSelectProps) => {
  const { t } = useTranslation();
  const app = App.useApp();
  const [allJobAds, setAllJobAds] = useState<JobAdDef[]>([]);

  const saveCandidate = async (newJobId: string) => {
    await updateCandidate({
      jobAdsId: candidate.jobAds.map((job) => job.id).concat(newJobId),
    });
    app.message.success(t("job-ad-assigned"));
  };

  useEffect(() => {
    const getAllJobAds = async () => {
      const response = await getJobAds({
        limit: 100,
      });
      if (response) {
        setAllJobAds(response.data);
      }
    };
    getAllJobAds();
  }, []);

  const options = useMemo(() => {
    const currentJobIdsSet = new Set(candidate.jobAds.map((job) => job.id));

    return allJobAds
      .filter((job) => !currentJobIdsSet.has(job.id))
      .map((item) => ({
        value: item.id,
        searchLabel: `${item.title} ${item.address?.city ?? ""}`,
        label: (
          <Space>
            <span>
              {item.title} {item.address?.city ? `(${item.address.city})` : ""}
            </span>
            <Tag>
              <Badge
                key="status"
                status={item.status === EJobAdStatus.DRAFT ? "warning" : "success"}
                text={t(item.status)}
              />
            </Tag>
          </Space>
        ),
      }));
  }, [allJobAds, candidate.jobAds]);

  return (
    <Section
      title={
        <>
          {t("assigned-job-ads")}{" "}
          <Typography.Text type="secondary">({candidate.jobAds.length})</Typography.Text>
        </>
      }
      Icon={UnorderedListOutlined}
      extraTitleContent={
        <SelectAutocomplete
          wrapOptions
          dropdownStyle={{
            maxWidth: "600px",
          }}
          placeholder={
            <Flex align="center" gap="small">
              <PlusCircleOutlined />
              {t("assign-job-ad")}
            </Flex>
          }
          options={options}
          value={null}
          onChange={saveCandidate}
          filterOption={(input, option) =>
            !input.trim() ||
            (option as (typeof options)[number]).searchLabel
              .toLowerCase()
              .includes(input.trim().toLowerCase())
          }
        />
      }
    >
      <Flex vertical gap="large">
        {candidate.jobAds.length === 0 && (
          <Alert showIcon type="info" message={t("no-ads-assigned")} />
        )}
        {candidate.jobAds.map((job) => {
          return (
            <JobCard
              key={job.id}
              candidate={candidate}
              job={job}
              onUnassign={() =>
                updateCandidate({
                  jobAdsId: candidate.jobAds.filter((j) => j.id !== job.id).map((j) => j.id),
                })
              }
            />
          );
        })}
      </Flex>
    </Section>
  );
};

const JobCard = ({
  candidate,
  job,
  onUnassign,
}: {
  candidate: CandidateDef;
  job: CandidateDef["jobAds"][0];
  onUnassign: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const [showOriginalDescription, setShowOriginalDescription] = useState(false);

  const jobQuery = useJobById({ id: job.id });
  const workerProfileQuery = useWorkerProfile(
    {
      idOrSlug: candidate.workerProfile.slug || candidate.workerProfile.anonymousSlug,
      lang: convertLangForBackend(i18n.language),
    },
    {
      enabled: !candidate.workerProfile.deletedAt,
    }
  );

  const liveURL = getJobPageUrl(job);

  return (
    <Card
      size="small"
      title={
        <a href={liveURL} target="_blank" rel="noreferrer">
          {job.title}
        </a>
      }
      extra={
        <Popconfirm
          title={t("Are you sure you want to delete this?")}
          okText={t("Yes")}
          cancelText={t("No")}
          onConfirm={onUnassign}
        >
          <Button size="small">{t("unassign")}</Button>
        </Popconfirm>
      }
    >
      <Flex
        vertical
        gap="middle"
        style={{
          overflow: "hidden",
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item layout="vertical" label={t("job-description")}>
              <div
                style={{
                  fontSize: "14px",
                  background: "#fff",
                  color: "black",
                  border: "1px solid #d9d9d9",
                  borderRadius: "10px",
                  padding: "6px 11px",
                  overflowY: "auto",
                  height: "200px",
                  resize: "vertical",
                  minHeight: "100px",
                }}
              >
                <MarkdownRenderer>{jobQuery.data?.description ?? ""}</MarkdownRenderer>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              layout="vertical"
              label={
                <Flex gap="small" align="center" justify="space-between">
                  {t("candidate-description")}
                  {workerProfileQuery.data?.description &&
                    workerProfileQuery.data.description_original &&
                    workerProfileQuery.data.description !==
                      workerProfileQuery.data.description_original && (
                      <Tooltip title={t("toggle-original-translated")}>
                        <Button
                          icon={<TranslationOutlined />}
                          size="small"
                          onClick={() => setShowOriginalDescription(!showOriginalDescription)}
                        />
                      </Tooltip>
                    )}
                </Flex>
              }
            >
              <Input.TextArea
                placeholder={t("empty")}
                size="large"
                autoSize={{
                  minRows: 1,
                  maxRows: 18,
                }}
                disabled
                style={{
                  fontSize: "14px",
                  background: "#fff",
                  color: "black",
                }}
                value={
                  showOriginalDescription
                    ? workerProfileQuery.data?.description_original ?? ""
                    : workerProfileQuery.data?.description ?? ""
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <ScreeningQuestions workerProfileId={candidate.workerProfile.id} jobAdId={job.id} />
      </Flex>
    </Card>
  );
};
