import { Flex, Form, FormInstance, Select, Typography } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";
import {
  EJobQuestionAnswerType,
  EJobQuestionType,
  EJobQuestionTypeTranslations,
  JobQuestionsFormDef,
} from "@app/types/job-questions.types";

export const TypeSelect = ({
  form,
  fieldName,
  onChange,
}: {
  form: FormInstance<JobQuestionsFormDef>;
  fieldName: number;
  onChange?: (index: number) => (value: EJobQuestionType) => void;
}) => {
  const { t } = useTranslation();

  const answerType = Form.useWatch(["questions", fieldName, "answerType"], form);

  const options: DefaultOptionType[] = Object.values(EJobQuestionType).map((item) => {
    const disabled =
      answerType === EJobQuestionAnswerType.TEXT && item === EJobQuestionType.KNOCKOUT;

    return {
      value: item,
      disabled,
      label: (
        <Flex vertical>
          {t(EJobQuestionTypeTranslations[item])}{" "}
          {disabled && <>({t("knockout-question-disabled")})</>}
          <Typography.Text type="secondary">
            {t(`${item}-question-select-description`)}
          </Typography.Text>
        </Flex>
      ),
      shortLabel: t(EJobQuestionTypeTranslations[item]),
    };
  });

  return (
    <Form.Item noStyle name={[fieldName, "type"]}>
      <Select
        popupMatchSelectWidth={false}
        optionLabelProp="shortLabel"
        options={options}
        onChange={onChange?.(fieldName)}
      />
    </Form.Item>
  );
};
