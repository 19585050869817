import { useQuery } from "@tanstack/react-query";
import {
  CandidateDef,
  CandidatesRequestDef,
  CandidatesResponseDef,
  CompanyCandidateStatusDef,
  UpdateCandidateDef,
} from "@app/types/candidate.types";
import { api } from "./api";

export const CANDIDATE_QUERY_KEY = "candidates";

export const getCandidates = async (
  params: CandidatesRequestDef
): Promise<CandidatesResponseDef> => {
  const response = await api.get<CandidatesResponseDef>(`/crafthunts/candidates`, {
    params,
  });
  return response.data;
};

export const useCandidates = (params: CandidatesRequestDef, options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: [CANDIDATE_QUERY_KEY, params] as const,
    queryFn: ({ queryKey }) => getCandidates(queryKey[1]),
    enabled: options?.enabled,
  });

export const getCandidateStatuses = async (): Promise<CompanyCandidateStatusDef[]> => {
  const response = await api.get<CompanyCandidateStatusDef[]>(
    `/crafthunts/company_candidate_statuses`
  );
  return response.data;
};

export const updateCandidateStatuses = async (
  payload: CompanyCandidateStatusDef[]
): Promise<CompanyCandidateStatusDef[]> => {
  const response = await api.put<CompanyCandidateStatusDef[]>(
    `/crafthunts/company_candidate_statuses/bulk`,
    { items: payload }
  );
  return response.data;
};

export const updateCandidate = async (
  candidateId: string,
  payload: UpdateCandidateDef
): Promise<CandidateDef> => {
  const response = await api.put<CandidateDef>(`/crafthunts/candidates/${candidateId}`, payload);
  return response.data;
};
