import { useQuery } from "@tanstack/react-query";
import { mapFormDataToApi, mapResponseToFormData } from "@app/helpers/job-ads.helper";
import {
  CreateJobAdDef,
  IJobAdApiResponse,
  JobAdDef,
  JobAdsRequestDef,
  JobAdsWithPaginationDef,
  JobAdsWithPaginationResponseDef,
  UpdateJobAdDef,
} from "@app/types/job-ads.types";
import { api } from "./api";

export const JOB_QUERY_KEY = "job-ads";

export const getJobAds = async (params: JobAdsRequestDef): Promise<JobAdsWithPaginationDef> => {
  const response = await api.get<JobAdsWithPaginationResponseDef>(`/crafthunts/job_ads`, {
    params,
  });
  return {
    ...response.data,
    data: response.data.data.map((ad) => mapResponseToFormData(ad)),
  };
};

export const useJobs = (params: JobAdsRequestDef, options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: [JOB_QUERY_KEY, params] as const,
    queryFn: ({ queryKey }) => getJobAds(queryKey[1]),
    enabled: options?.enabled,
  });

export const getJobAd = async (id: string): Promise<JobAdDef> => {
  const response = await api.get<IJobAdApiResponse>(`/crafthunts/job_ads/${id}`);
  return mapResponseToFormData(response.data);
};

export const useJobById = (params: { id: string }) => {
  return useQuery({
    queryKey: [JOB_QUERY_KEY, params.id],
    queryFn: () => getJobAd(params.id),
  });
};

export const postJobAd = async (payload: CreateJobAdDef): Promise<JobAdDef> => {
  const response = await api.post<IJobAdApiResponse>(
    `/crafthunts/job_ads`,
    mapFormDataToApi(payload)
  );
  return mapResponseToFormData(response.data);
};

export const updateJobAd = async (payload: UpdateJobAdDef): Promise<JobAdDef> => {
  const response = await api.put<IJobAdApiResponse>(
    `/crafthunts/job_ads/${payload.id}`,
    mapFormDataToApi(payload)
  );
  return mapResponseToFormData(response.data);
};

export const removeJobAd = (id: string) => {
  return api.delete<void>(`/crafthunts/job_ads/${id}`);
};
