import { PercentageOutlined, TranslationOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Popconfirm,
  Row,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScreeningQuestions } from "@app/components/pages/Candidates/components/CandidateDrawer/components/JobsList/ScreeningQuestions";
import { MarkdownRenderer } from "@app/components/ui/Markdown/MarkdownRenderer";
import { useCompanySA } from "@app/features/super-admin/api/super-admin.company.api";
import { useJobSA } from "@app/features/super-admin/api/super-admin.job.api";
import { useTagsSA } from "@app/features/super-admin/api/super-admin.tag.api";
import { useUserSA } from "@app/features/super-admin/api/super-admin.user.api";
import { CandidateSADef } from "@app/features/super-admin/types/super-admin.candidate.types";
import { JobAdSADef } from "@app/features/super-admin/types/super-admin.job.types";
import { UsersSAResponseDef } from "@app/features/super-admin/types/super-admin.user.types";
import { isDefined } from "@app/helpers/util.helper";
import { JobQuestionDef } from "@app/types/job-questions.types";
import { ETagType } from "@app/types/tags.types";

export const JobCard = ({
  jobId,
  title,
  candidate,
  onJobOpen,
  onUnassign,
  onQuestionLoaded,
  onQuestionEdit,
}: {
  jobId: string;
  title: string;
  candidate: CandidateSADef;
  onJobOpen: () => void;
  onUnassign: () => void;
  onQuestionLoaded?: (question: JobQuestionDef, answerIsWrong: boolean) => void;
  onQuestionEdit?: (question: JobQuestionDef) => void;
}) => {
  const { t, i18n } = useTranslation();
  const [showOriginalDescription, setShowOriginalDescription] = useState(false);
  const jobQuery = useJobSA({
    jobId,
    workerProfileId: candidate.workerProfile.id,
  });
  const tradesQuery = useTagsSA({
    target: ETagType.Crafthunt_Trade,
    withCount: true,
    withSimilarTags: true,
  });
  const rolesQuery = useTagsSA({
    target: ETagType.Crafthunt_JobTitle,
    withCount: true,
    withSimilarTags: true,
  });
  const industriesQuery = useTagsSA({
    target: ETagType.Crafthunt_Industry,
    withCount: true,
    withSimilarTags: true,
  });
  const userQuery = useUserSA({
    userId: candidate.workerProfile.id,
    lang: i18n.language,
  });
  const companyQuery = useCompanySA({
    companyId: jobQuery.data?.crafthuntCompany.id,
  });

  const { jobProjectTypes, userIndustries } = useMemo(() => {
    return {
      jobProjectTypes: jobQuery.data?.projectTypes,
      jobIndustries: (
        jobQuery.data?.matchScoreMetadata?.jobIndustries ??
        companyQuery.data?.companyIndustriesId ??
        []
      )
        .map((industryId) => industriesQuery.data?.find((industry) => industry.id === industryId))
        .filter(isDefined),
      userIndustries: (userQuery.data?.profileIndustriesId ?? [])
        .map((industryId) => industriesQuery.data?.find((industry) => industry.id === industryId))
        .filter(isDefined),
    };
  }, [industriesQuery.data, companyQuery.data, userQuery.data, jobQuery.data]);

  const columns: TableProps["columns"] = [
    {
      title: "Job",
      dataIndex: "jobAd",
      key: "jobAd",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Match",
      dataIndex: "match",
      key: "match",
    },
  ];

  const jobData = jobQuery.data;
  const matchScoreData = jobData?.matchScoreMetadata;

  const roleMatch =
    matchScoreData?.rolesScore || jobData?.role?.id == candidate.workerProfile.jobTitle?.id;
  const similarRole = rolesQuery.data
    ?.find((role) => role.id === jobData?.role?.id)
    ?.similarTagsId?.find((tagId) => tagId === candidate.workerProfile.jobTitle?.id);
  const tradeMatch =
    matchScoreData?.tradesScore ||
    candidate.workerProfile.profileTrades.some((trade) => trade.id === jobData?.trade?.id);
  const similarTrade = tradesQuery.data
    ?.find((trade) => trade.id === jobData?.trade?.id)
    ?.similarTagsId?.find((tagId) =>
      candidate.workerProfile.profileTrades.some((trade) => trade.id === tagId)
    );
  const industryMatch = matchScoreData?.industriesScore;

  const isUserAndJobCountryMatch = (
    user: UsersSAResponseDef | undefined,
    job: JobAdSADef | undefined
  ) => {
    if (!user || !job) {
      return "❌";
    }
    if (job.matchScoreMetadata?.distanceScore) {
      return `✅${
        job.matchScoreMetadata.distanceKm
          ? ` (${job.matchScoreMetadata.distanceKm.toFixed(1).replace(".0", "")} km)`
          : ""
      }`;
    }
    if (!job.requiredCountries?.length && user.geoSearchAddress?.isEU) {
      return <Tooltip title="Job has no required countries and User is in EU">✅</Tooltip>;
    }
    if (
      user.geoSearchAddress?.countryCode &&
      job.requiredCountries?.map((req) => req.value).includes(user.geoSearchAddress.countryCode)
    ) {
      return (
        <Tooltip
          title={`User is in required countries: ${job.requiredCountries
            .map((req) => req.value)
            .join(", ")}`}
        >
          ✅
        </Tooltip>
      );
    }
    return "❌";
  };

  const dataSource = [
    {
      key: "roles",
      jobAd: jobData?.role?.name && t(jobData.role.name),
      user: candidate.workerProfile.jobTitle ? t(candidate.workerProfile.jobTitle.name) : "",
      match: roleMatch || similarRole ? <>✅{similarRole && ` (Similar)`}</> : "❌",
    },
    {
      key: "trades",
      jobAd: jobData?.trade?.name && t(jobData.trade.name),
      user: candidate.workerProfile.profileTrades.map((trade) => t(trade.name)).join(", "),
      match: tradeMatch || similarTrade ? <>✅{similarTrade && ` (Similar)`}</> : "❌",
    },
    {
      key: "industries",
      jobAd: jobProjectTypes?.map((item) => t(item.name)).join(", ") || (
        <Typography.Text type="secondary">No project types</Typography.Text>
      ),
      user: userIndustries?.map((item) => t(item.name)).join(", ") || (
        <Typography.Text type="secondary">No industries</Typography.Text>
      ),
      match: industryMatch ? "✅" : "❌",
    },
    {
      key: "travel",
      jobAd: jobData?.travel ? (
        t(`${jobData.travel}-travel`)
      ) : (
        <Typography.Text type="secondary">Travel not set</Typography.Text>
      ),
      user: matchScoreData?.userTravel ? (
        t(matchScoreData.userTravel)
      ) : (
        <Typography.Text type="secondary">Travel not set</Typography.Text>
      ),
      match: matchScoreData?.travelScore ? "✅" : "❌",
    },
    {
      key: "location",
      jobAd: [jobData?.address?.city, jobData?.address?.country].filter(Boolean).join(", "),
      user: [
        userQuery.data?.geoSearchAddress?.city,
        userQuery.data?.geoSearchAddress?.country,
        matchScoreData?.distanceScore && !matchScoreData.distanceKm && "Flexible",
      ]
        .filter(Boolean)
        .join(", "),
      match: isUserAndJobCountryMatch(userQuery.data, jobData),
    },
  ];

  return (
    <Card
      size="small"
      extra={
        <>
          {jobData && jobData.score != null && (
            <Tag color={jobData.score > 0.5 ? "green" : "orange"}>
              Score: {Number(jobData.score) * 100}%
            </Tag>
          )}
          <Popconfirm
            title={t("Are you sure you want to delete this?")}
            okText={t("Yes")}
            cancelText={t("No")}
            onConfirm={onUnassign}
          >
            <Button size="small">{t("unassign")}</Button>
          </Popconfirm>
        </>
      }
      title={<a onClick={() => onJobOpen()}>{title}</a>}
    >
      {(jobQuery.isError || userQuery.isError) && (
        <Alert message="Sorry, something went wrong." type="error" />
      )}
      {(jobQuery.isLoading || userQuery.isLoading) && <Spin />}
      {jobData && userQuery.data && (
        <Flex
          vertical
          gap="middle"
          style={{
            overflow: "hidden",
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item layout="vertical" label="Job description">
                <div
                  style={{
                    fontSize: "14px",
                    background: "#fff",
                    color: "black",
                    border: "1px solid #d9d9d9",
                    borderRadius: "10px",
                    padding: "6px 11px",
                    maxHeight: "400px",
                    overflowY: "auto",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <MarkdownRenderer>{jobData.description}</MarkdownRenderer>
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                layout="vertical"
                label={
                  <Flex gap="small" align="center" justify="space-between">
                    User description
                    {userQuery.data.description &&
                      userQuery.data.description_original &&
                      userQuery.data.description !== userQuery.data.description_original && (
                        <Tooltip title="Toggle original/translated">
                          <Button
                            icon={<TranslationOutlined />}
                            size="small"
                            onClick={() => setShowOriginalDescription(!showOriginalDescription)}
                          />
                        </Tooltip>
                      )}
                  </Flex>
                }
              >
                <Input.TextArea
                  placeholder="Empty"
                  size="large"
                  autoSize={{
                    minRows: 1,
                    maxRows: 20,
                  }}
                  disabled
                  style={{
                    fontSize: "14px",
                    background: "#fff",
                    color: "black",
                  }}
                  value={
                    showOriginalDescription
                      ? userQuery.data.description_original ?? ""
                      : userQuery.data.description ?? ""
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Flex vertical gap="small">
            <Flex gap="small" align="center">
              <PercentageOutlined style={{ fontSize: 20 }} />
              <Typography.Text strong>Match score</Typography.Text>
            </Flex>
            <Table
              loading={jobQuery.isLoading}
              pagination={false}
              columns={columns}
              dataSource={dataSource}
            />
          </Flex>
          <ScreeningQuestions
            showQuestionType
            showQuestionValidation
            jobAdId={jobId}
            workerProfileId={candidate.workerProfile.id}
            onQuestionLoaded={onQuestionLoaded}
            onEdit={onQuestionEdit}
          />
        </Flex>
      )}
    </Card>
  );
};
