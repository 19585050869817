import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App, Empty, Space, Table, TableColumnsType, TablePaginationConfig } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@app/components/ui/Button/Button";
import { createJobRelevanceFeedbackSA } from "@app/features/super-admin/api/super-admin.job-relevance-feedback";
import {
  userRelevantJobsQueryKey,
  useUserRelevantJobsSA,
} from "@app/features/super-admin/api/super-admin.user.api";
import { useCompanyModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Companies/components/CompanyModal";
import { useJobModal } from "@app/features/super-admin/components/SuperAdminStuff/components/Jobs/JobModal/JobModal";
import { JobRelevanceFeedback } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/UserModal/components/JobRelevanceFeedback/JobRelevanceFeedback";
import { EJobRelevanceFeedbackType } from "@app/features/super-admin/types/super-admin.job-relevance-feedback.types";
import { UserFormDef, UserJobsDef } from "@app/features/super-admin/types/super-admin.user.types";
import { getTradeName } from "@app/helpers/tag.helper";
import { JobRelevanceFeedbackNoteModal } from "./components/JobRelevanceFeedbackNoteModal";

const PAGE_SIZE = 10;

type UserRelevantJobsProps = {
  selectedUser: UserFormDef;
};

export const UserRelevantJobs = ({ selectedUser }: UserRelevantJobsProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const app = App.useApp();
  const [currentPage, setCurrentPage] = useState(0);
  const [showFeedbackNoteModal, setShowFeedbackNoteModal] = useState(false);
  const [selectedJobAdId, setSelectedJobAdId] = useState<string | undefined>(undefined);
  const { setCompanyId } = useCompanyModal();
  const { setJobId } = useJobModal();

  const jobsQuery = useUserRelevantJobsSA({
    workerProfileId: selectedUser.id,
    limit: PAGE_SIZE,
    offset: currentPage,
  });

  const createJobRelevanceFeedback = useMutation({
    mutationFn: createJobRelevanceFeedbackSA,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: userRelevantJobsQueryKey,
      });
    },
  });

  const handlePageChange = async (pagination: TablePaginationConfig) => {
    if (typeof pagination.current === "number") {
      setCurrentPage(pagination.current - 1);
    }
  };

  const showDetails = (item: UserJobsDef) => {
    app.modal.confirm({
      title: "Details",
      content: <pre>{JSON.stringify(item.matchScoreMetadata, null, 2)}</pre>,
      width: 800,
    });
  };

  const generatedColumnData = (data: UserJobsDef[]) => {
    return data.map((item: UserJobsDef) => ({
      key: item.id,
      title: <a onClick={() => setJobId(item.id)}>{item.title}</a>,
      companyName: (
        <a onClick={() => setCompanyId(item.crafthuntCompany.id)}>{item.crafthuntCompany.name}</a>
      ),
      roleName: item.role ? t(item.role.name) : "",
      tradeName: item.trade ? t(getTradeName(item.trade.name, item.role?.category)) : "",
      location: !item.address
        ? null
        : item.address.countryCode === "DE"
        ? item.address.city
        : [item.address.city, item.address.country].filter(Boolean).join(", "),
      distance:
        typeof item.matchScoreMetadata?.distanceKm === "number"
          ? `${item.matchScoreMetadata.distanceKm.toFixed(1).replace(".0", "")} km`
          : "-",
      engagementType: item.engagementType,
      candidateStatus: item.candidateStatus,
      score: `${Math.ceil(item.score * 100)}%`,
      operation: (
        <Space>
          <Button type="link" onClick={() => showDetails(item)} size="small">
            Metadata
          </Button>
          {item.jobRelevanceFeedback?.type !== EJobRelevanceFeedbackType.NOT_RELEVANT ? (
            <Button
              size="small"
              type="primary"
              style={{
                lineHeight: 1,
              }}
              onClick={() => {
                setShowFeedbackNoteModal(true);
                setSelectedJobAdId(item.id);
              }}
            >
              Not interested
            </Button>
          ) : (
            <JobRelevanceFeedback jobRelevanceFeedback={item.jobRelevanceFeedback} />
          )}
        </Space>
      ),
    }));
  };

  const columns: TableColumnsType<ReturnType<typeof generatedColumnData>[number]> = [
    { title: t("Job title"), dataIndex: "title", key: "title", ellipsis: true, width: 250 },
    {
      title: t("Company"),
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
      width: 150,
    },
    { title: t("Role"), dataIndex: "roleName", key: "role", ellipsis: true, width: 150 },
    { title: t("Trade"), dataIndex: "tradeName", key: "trade", ellipsis: true, width: 150 },
    { title: t("Address"), dataIndex: "location", key: "location", ellipsis: true, width: 100 },
    {
      title: t("distance-label"),
      dataIndex: "distance",
      key: "distance",
      ellipsis: true,
      width: 125,
    },
    {
      title: t("Engagement type"),
      dataIndex: "engagementType",
      key: "engagementType",
      ellipsis: true,
      width: 150,
    },
    {
      title: t("Status"),
      dataIndex: "candidateStatus",
      key: "candidateStatus",
      ellipsis: true,
      width: 100,
    },
    { title: t("Score"), dataIndex: "score", key: "score", ellipsis: true, width: 75 },
    {
      title: t("Actions"),
      dataIndex: "operation",
      key: "operation",
      fixed: "right",
      width: 250,
    },
  ];

  const missingUserInfo = [
    !selectedUser.jobTitleId ? t("Role") : "",
    !selectedUser.profileTradesId?.length ? t("Trades") : "",
    !selectedUser.geoSearchAddress ? t("Looking for Work - Address") : "",
  ]
    .filter(Boolean)
    .join(" & ");

  return (
    <>
      <Table
        style={{ width: "100%" }}
        loading={jobsQuery.isLoading}
        columns={columns}
        scroll={{ x: 1270 }}
        dataSource={generatedColumnData(jobsQuery.data?.data || [])}
        pagination={{
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
          position: ["bottomLeft"],
          total: jobsQuery.data?.count,
        }}
        onChange={handlePageChange}
        locale={{
          emptyText: (
            <Empty
              style={{ color: "black" }}
              description={
                <span>
                  {missingUserInfo.length
                    ? `${t("Missing")}: ${missingUserInfo}`
                    : t("Looks like there are no relevant jobs for this user")}
                </span>
              }
            />
          ),
        }}
      />
      <JobRelevanceFeedbackNoteModal
        open={showFeedbackNoteModal}
        onCancel={() => setShowFeedbackNoteModal(false)}
        onOk={(note) => {
          setShowFeedbackNoteModal(false);
          if (!selectedJobAdId) return;
          createJobRelevanceFeedback.mutate({
            workerProfileId: selectedUser.id,
            jobAdId: selectedJobAdId,
            type: EJobRelevanceFeedbackType.NOT_RELEVANT,
            description: note,
          });
        }}
      />
    </>
  );
};
