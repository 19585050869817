export type ExternalLinkDef = {
  id?: string;
  value: string;
  type: ExternalLinkTypeEnum;
};

export enum ExternalLinkTypeEnum {
  DOMAIN = "domain",
  YOUTUBE = "youtube",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  LINKEDIN = "linkedin",
  TIKTOK = "tiktok",
  KUNUNU = "kununu",
  XING = "xing",
}
