import { useMutation } from "@tanstack/react-query";
import { Modal, Form, Input, Alert, Select, Tag, Space, App } from "antd";
import castArray from "lodash/castArray";
import { useTranslation } from "react-i18next";
import { useQuestionAnswer } from "@app/api/job-answers.api";
import {
  createScreeningAnswerSA,
  updateScreeningAnswerSA,
} from "@app/features/super-admin/api/super-admin.job-questions";
import { isDefined } from "@app/helpers/util.helper";
import {
  EJobQuestionAnswerChoice,
  EJobQuestionAnswerType,
  EJobQuestionType,
  JobQuestionDef,
} from "@app/types/job-questions.types";

export const EditAnswerModal = ({
  workerProfileId,
  question,
  onClose,
}: {
  workerProfileId: string;
  question: JobQuestionDef;
  onClose: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const app = App.useApp();

  type FormValues = {
    answer?: string;
    optionIds?: string | string[];
  };

  const [form] = Form.useForm<FormValues>();

  const answerQuery = useQuestionAnswer({
    lang: i18n.language,
    questionId: question.id,
    workerProfileId,
  });

  const onSuccess = () => {
    answerQuery.refetch();
    app.message.success("Answer saved successfully");
    onClose();
  };
  const onError = () => {
    app.message.error("Failed to save asnwer");
  };

  const createAnswer = useMutation({
    mutationFn: createScreeningAnswerSA,
    onSuccess,
    onError,
  });

  const updateAnswer = useMutation({
    mutationFn: updateScreeningAnswerSA,
    onSuccess,
    onError,
  });

  const onFinish = (values: FormValues) => {
    if (answerQuery.data) {
      updateAnswer.mutate({
        id: answerQuery.data.id,
        workerProfileId,
        answer: values.answer,
        optionIds: values.optionIds ? castArray(values.optionIds) : undefined,
      });
    } else {
      createAnswer.mutate({
        workerProfileId,
        jobQuestionId: question.id,
        answer: values.answer,
        optionIds: values.optionIds ? castArray(values.optionIds) : undefined,
      });
    }
  };

  const initialValues: FormValues = {
    answer: answerQuery.data?.answer ?? "",
    optionIds: answerQuery.data?.options.map((option) => option.id).filter(isDefined) ?? undefined,
  };

  return (
    <Modal
      open
      destroyOnClose
      title={
        <>
          Question: {question.title} {<Tag>{question.type}</Tag>}
        </>
      }
      loading={answerQuery.isLoading}
      confirmLoading={createAnswer.isPending}
      onOk={form.submit}
      onCancel={() => onClose()}
    >
      {!answerQuery.isLoading && (
        <Form
          clearOnDestroy
          initialValues={initialValues}
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          {(question.answerType === EJobQuestionAnswerType.NUMBER ||
            question.answerType === EJobQuestionAnswerType.TEXT) && (
            <Form.Item
              name="answer"
              label="Answer"
              rules={[{ required: true, message: "Please provide an answer" }]}
            >
              {question.answerType === EJobQuestionAnswerType.NUMBER && (
                <Input type="number" placeholder="Enter answer for this question" />
              )}
              {question.answerType === EJobQuestionAnswerType.TEXT && (
                <Input.TextArea rows={4} placeholder="Enter answer for this question" />
              )}
            </Form.Item>
          )}
          {question.answerType === EJobQuestionAnswerType.OPTION && (
            <Form.Item
              name="optionIds"
              label={
                <Space>
                  <span>
                    Select{" "}
                    {question.answerChoice === EJobQuestionAnswerChoice.MULTI
                      ? "options"
                      : "option"}
                  </span>{" "}
                  <Tag>{question.answerChoice}</Tag>
                </Space>
              }
              rules={[{ required: true, message: "Please select options" }]}
            >
              <Select
                placeholder="Select"
                mode={
                  question.answerChoice === EJobQuestionAnswerChoice.SINGLE ? undefined : "multiple"
                }
                options={question.options.map((option) => ({
                  label: option.text,
                  value: option.id,
                }))}
              />
            </Form.Item>
          )}
          {question.type === EJobQuestionType.KNOCKOUT && (
            <Alert
              message="This is a knockout question. Expected answer:"
              description={
                <>
                  {question.answerType === EJobQuestionAnswerType.NUMBER && (
                    <>
                      {t(question.goodNumberAnswerType ?? "")} {question.goodNumberAnswerValue}
                    </>
                  )}
                  {question.answerType === EJobQuestionAnswerType.OPTION && (
                    <ul>
                      {question.options
                        .filter((option) => option.isGoodAnswer)
                        .map((option) => (
                          <li key={option.id}>{option.text}</li>
                        ))}
                    </ul>
                  )}
                </>
              }
            />
          )}
        </Form>
      )}
    </Modal>
  );
};
