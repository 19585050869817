import { CheckOutlined, CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  App,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  theme,
  Tooltip,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdQueryStats, MdRocket, MdSearch, MdVerified } from "react-icons/md";
import { useSelector } from "react-redux";
import { checkBacklink } from "@app/api/backlinks.api";
import step3 from "@app/assets/step3_400x400.png";
import { Button } from "@app/components/ui/Button/Button";
import { triggerAnalyticsEvent } from "@app/helpers/analytics.helper";
import { convertLangForAnalytics } from "@app/helpers/language.helper";
import { useCopy } from "@app/hooks/useCopy";
import { useLiveURL } from "@app/hooks/useLiveUrl";
import { RootState, store } from "@app/store/store";
import { ExternalLinkTypeEnum } from "@app/types/external-link.types";

type EmailForm = {
  email: string;
};

type DomainForm = {
  domain: string;
};

type AddLinkToWebsiteModalProps = {
  open: boolean;
  close: () => void;
};

export const AddLinkToWebsiteModal = ({ open, close }: AddLinkToWebsiteModalProps) => {
  const { token } = theme.useToken();
  const { message } = App.useApp();
  const existingCompanyInfo = useSelector((state: RootState) => state.companyInfo.companyInfo);
  const userInfo = useSelector((state: RootState) => state.auth);
  const [emailForm] = useForm<EmailForm>();
  const [domainForm] = useForm<DomainForm>();
  const currentDomain = Form.useWatch("domain", domainForm);
  const { t, i18n } = useTranslation();
  const liveURL = useLiveURL();
  const { copyToClipboard } = useCopy();
  const [isLoading, setIsLoading] = useState(false);
  const [isDomainFormLoading, setIsDomainFormLoading] = useState(false);
  const { dispatch } = store;

  const domainFormInitialValues = {
    domain: existingCompanyInfo.externalLinks.find(
      (link) => link.type === ExternalLinkTypeEnum.DOMAIN
    )?.value,
  };

  const handleOnClose = () => {
    setIsLoading(false);
    close();
  };

  const handleOnDone = async () => {
    setIsLoading(true);
    if (domainFormInitialValues.domain) {
      try {
        await checkBacklink();
      } catch (e) {
        console.error(e);
      }
    }
    handleOnClose();
  };

  const onSendEmail = (values: EmailForm) => {
    triggerAnalyticsEvent("sendIconsToDeveloper", {
      fromFirstName: userInfo.firstName,
      fromLastName: userInfo.lastName,
      fromCompanyName: existingCompanyInfo.name,
      receiverEmail: values.email,
      linkToCompanyPage: liveURL,
      language: convertLangForAnalytics(i18n.language),
    });
    message.success(t("Information sent!"), 4);
    // using timeout so modal doesn't close instantly
    setTimeout(() => handleOnClose(), 350);
  };

  const onSaveDomain = async (values: DomainForm) => {
    setIsDomainFormLoading(true);
    await dispatch.companyInfo.updateCompanyInfoAction({
      info: {
        ...existingCompanyInfo,
        externalLinks: [
          ...existingCompanyInfo.externalLinks.filter(
            (link) => link.type !== ExternalLinkTypeEnum.DOMAIN
          ),
          {
            type: ExternalLinkTypeEnum.DOMAIN,
            value: values.domain,
          },
        ],
      },
    });
    domainFormInitialValues.domain = values.domain;
    setIsDomainFormLoading(false);
  };

  const downloadZips = () => {
    window.open("https://crafthunt.app/static/social_icons_Crafthunt.zip");
  };

  return (
    <Modal
      cancelButtonProps={{ style: { display: "none", pointerEvents: "none" } }}
      width={800}
      title={t("backlink-modal-title")}
      open={open}
      onOk={handleOnDone}
      onCancel={handleOnClose}
      okText={t("Done")}
      okButtonProps={{ size: "large" }}
      destroyOnClose
      confirmLoading={isLoading}
    >
      <div style={{ marginTop: 32, marginBottom: 32 }}>
        {/* Website Domain */}
        <Typography.Title level={5}>{t("domain-form-title")}</Typography.Title>
        <Row gutter={[24, 24]} style={{ marginTop: 32 }}>
          <Col span={24}>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Typography.Text style={{ whiteSpace: "pre-line" }}>
                {t("domain-form-description")}
              </Typography.Text>
              <Form
                form={domainForm}
                onFinish={onSaveDomain}
                data-hs-do-not-collect="true"
                initialValues={domainFormInitialValues}
                clearOnDestroy
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    style={{ flex: 1 }}
                    name="domain"
                    rules={[
                      {
                        type: "url",
                        required: true,
                        whitespace: true,
                        message: t("Invalid url"),
                      },
                    ]}
                  >
                    <Input placeholder={"https://www.example.com"} size="large" />
                  </Form.Item>
                  <Button
                    loading={isDomainFormLoading}
                    disabled={currentDomain === domainFormInitialValues.domain}
                    htmlType="submit"
                    type="primary"
                    icon={<CheckOutlined />}
                  ></Button>
                </Space.Compact>
              </Form>
            </Space>
          </Col>
        </Row>
        <Divider />
        {/* Benefits */}
        <Typography.Title level={5}>{t("backlink-benefits-title")}</Typography.Title>
        <Row gutter={[24, 24]} style={{ marginTop: 32 }}>
          <Col span={12}>
            <Space size="middle" style={{ width: "100%" }}>
              <MdSearch size={24} style={{ color: token.colorPrimary }} />
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text>{t("backlink-benefit-1-title")}</Typography.Text>
                <Typography.Text type="secondary">
                  {t("backlink-benefit-1-description")}
                </Typography.Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space size="middle" style={{ width: "100%" }}>
              <MdQueryStats size={24} style={{ color: token.colorPrimary }} />
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text>{t("backlink-benefit-2-title")}</Typography.Text>
                <Typography.Text type="secondary">
                  {t("backlink-benefit-2-description")}
                </Typography.Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space size="middle" style={{ width: "100%" }}>
              <MdVerified size={24} style={{ color: token.colorPrimary }} />
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text>{t("backlink-benefit-3-title")}</Typography.Text>
                <Typography.Text type="secondary">
                  {t("backlink-benefit-3-description")}
                </Typography.Text>
              </Space>
            </Space>
          </Col>
          <Col span={12}>
            <Space size="middle" style={{ width: "100%" }}>
              <MdRocket size={24} style={{ color: token.colorPrimary }} />
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text>{t("backlink-benefit-4-title")}</Typography.Text>
                <Typography.Text type="secondary">
                  {t("backlink-benefit-4-description")}
                </Typography.Text>
              </Space>
            </Space>
          </Col>
        </Row>
        <Divider />
        {/* Media */}
        <Typography.Title level={5}>{t("backlink-how-to-title")}</Typography.Title>
        <Row gutter={[24, 24]} style={{ marginTop: 32 }}>
          <Col span={12}>
            <Card cover={<img width={"100%"} src={step3} />}>
              <Typography.Text>{t("backlink-how-to-description")}</Typography.Text>
            </Card>
          </Col>
          <Col span={12}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text>{t("company-link-title")}</Typography.Text>
                <Space.Compact style={{ width: "100%" }}>
                  <Input defaultValue={liveURL} value={liveURL} size="large" />
                  <Tooltip title={t("Copy link")}>
                    <Button onClick={() => copyToClipboard(liveURL)} icon={<CopyOutlined />} />
                  </Tooltip>
                </Space.Compact>
              </Space>

              <Button onClick={() => downloadZips()} style={{ width: "100%" }}>
                <DownloadOutlined />
                {t("Download Crafthunt icons")}
              </Button>
            </Space>
          </Col>

          <Col span={24} style={{ marginTop: 16 }}>
            <Typography.Text style={{ whiteSpace: "pre-line" }}>
              {t(
                "Not sure how to do this, or is it not your responsibility? \nEnter the email of the person that can add this, and we will send them all they need."
              )}
            </Typography.Text>
            <Form
              form={emailForm}
              onFinish={onSendEmail}
              data-hs-do-not-collect="true"
              style={{ marginTop: 16 }}
              clearOnDestroy
            >
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item
                  style={{ flex: 1 }}
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      whitespace: true,
                      message: t("Invalid email"),
                    },
                  ]}
                >
                  <Input placeholder={"example@example.com"} size="large" />
                </Form.Item>
                <Button htmlType="submit" type="primary">
                  {t("Send")}
                </Button>
              </Space.Compact>
            </Form>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
