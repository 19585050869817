import { EJobRelevanceFeedbackType } from "@app/features/super-admin/types/super-admin.job-relevance-feedback.types";
import { superAdminApi } from "./super-admin.api";

export const createJobRelevanceFeedbackSA = (params: {
  workerProfileId: string;
  jobAdId: string;
  type: EJobRelevanceFeedbackType;
  description?: string;
}) => {
  return superAdminApi.put(`/super_admin/job_relevance_feedback`, params);
};
