import { Alert, Button, Card, Col, Row, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ContentLayout } from "@app/components/layouts/ContentLayout/ContentLayout";
import { OnboardingSteps } from "@app/components/pages/Dashboard/OnboardingSteps/OnboardingSteps";
import { useAccountSetupProgress } from "@app/hooks/useAccountSetupProgress";
import { RootState, store } from "@app/store/store";
import { ApprovalStatusEnum } from "@app/types/company-info.types";
import { AddLinkToWebsiteModal } from "./AddLinkToWebsiteModal/AddLinkToWebsiteModal";
import { AlertDashboardCard } from "./AlertDashboardCard/AlertDashboardCard";
import { DashboardStats } from "./DashboardStats/DashboardStats";
import { QuickActionsCard } from "./QuickActionsCard/QuickActionsCard";

export const Dashboard = () => {
  const { t } = useTranslation();
  const { dispatch } = store;
  const progress = useAccountSetupProgress();
  const readyForReview = !Object.values(progress.steps).includes(false);
  const [clickedResubmit, setClickedResubmit] = useState(false);
  const existingCompanyInfo = useSelector((state: RootState) => state.companyInfo.companyInfo);
  const [approvalStatus, setApprovalStatus] = useState(existingCompanyInfo.approvalStatus);
  const [addLinkToWebsiteModalOpen, setAddLinkToWebsiteModalOpen] = useState(false);

  const onSubmitForReview = async () => {
    try {
      await dispatch.companyInfo.updateApprovalStatusAction({
        id: existingCompanyInfo.id,
        approvalStatus: ApprovalStatusEnum.PENDING,
      });
      setApprovalStatus(ApprovalStatusEnum.PENDING);
    } catch (e) {
      console.error(e);
    }
  };

  const closeModals = () => {
    setAddLinkToWebsiteModalOpen(false);
  };

  return (
    <ContentLayout title={t("Welcome, {{0}}", { 0: existingCompanyInfo.name })}>
      <div style={{ marginTop: "-40px" }}>
        <Row gutter={[40, 40]}>
          <Col span={24}>
            {approvalStatus !== ApprovalStatusEnum.APPROVED && (
              <Card bordered={false}>
                <Space direction="vertical" size="large">
                  <Card.Meta
                    title={t("Fill out your information")}
                    description={t(
                      "First, we need some information from you. Click the links below to begin."
                    )}
                  />
                  <OnboardingSteps />
                  {readyForReview && approvalStatus !== ApprovalStatusEnum.PENDING && (
                    <Button type="primary" size="large" onClick={() => onSubmitForReview()}>
                      {t("Submit for review")}
                    </Button>
                  )}
                  {approvalStatus !== ApprovalStatusEnum.NOT_STARTED && (
                    <div>
                      <Button
                        disabled={!readyForReview || clickedResubmit}
                        type="primary"
                        size="large"
                        onClick={() => {
                          setClickedResubmit(true);
                          onSubmitForReview();
                        }}
                      >
                        {t("Resubmit")}
                      </Button>
                    </div>
                  )}
                  {approvalStatus === ApprovalStatusEnum.PENDING && (
                    <Alert
                      style={{
                        width: "100%",
                      }}
                      message={t("Pending review")}
                      description={t(
                        "Your information has been submitted and is pending approval. You will hear back from us within a few days, but typically within 24 hours. If you made some changes during the review process, you can press the resubmit button to submit again when you are ready."
                      )}
                      type="warning"
                      showIcon
                    />
                  )}
                  {approvalStatus === ApprovalStatusEnum.REJECTED && (
                    <Alert
                      style={{
                        width: "100%",
                      }}
                      message={t("Denied")}
                      description={t(
                        "We've decided not to display your company on Crafthunt yet. Try adding some more information and making sure your profile looks professional. You can always contact us in the chat in the bottom right corner to get help."
                      )}
                      type="error"
                      showIcon
                    />
                  )}
                </Space>
              </Card>
            )}
          </Col>
          <Col span={24}>
            <DashboardStats />
          </Col>
          <Col span={24} lg={12}>
            <QuickActionsCard />
          </Col>
          <Col span={24} lg={12}>
            <AlertDashboardCard />
          </Col>
        </Row>
      </div>
      <AddLinkToWebsiteModal close={closeModals} open={addLinkToWebsiteModalOpen} />
    </ContentLayout>
  );
};
