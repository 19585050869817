import { UserOutlined } from "@ant-design/icons";
import { Card, Checkbox, CheckboxChangeEvent, Col, Divider, Row, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "@app/components/ui/Button/Button";
import { LanguagePicker } from "@app/components/ui/LanguagePicker/LanguagePicker";
import { RootState, store } from "@app/store/store";
import { ECompanyAdvertisement } from "@app/types/company-info.types";
import { ContentLayout } from "@layouts/ContentLayout/ContentLayout";
import { CompanyIntegrationSettings } from "./components/CompanyIntegrationSettings/CompanyIntegrationSettings";
import PasswordChangeModal from "./components/PasswordChangeModal";

const { Meta } = Card;

export const Settings = () => {
  const fullName = useSelector((state: RootState) => state.auth.fullName);
  const email = useSelector((state: RootState) => state.auth.email);
  const company = useSelector((state: RootState) => state.companyInfo.companyInfo);
  const [innerAdvertisements, setInnerAdvertisements] = useState<ECompanyAdvertisement[]>(
    company.enabledAdvertisements
  );
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
  const { t } = useTranslation();
  const { dispatch } = store;

  const cardStyle = { height: "100%" };

  const handleOnLogout = () => {
    dispatch.auth.logout();
  };

  const handleChangePasswordClick = () => {
    setPasswordChangeModalOpen(true);
  };

  const onChangeAdCheckbox = async (e: CheckboxChangeEvent) => {
    const isEnabled = e.target.value;
    let newEnabledAdvertisements = innerAdvertisements;
    if (isEnabled) {
      newEnabledAdvertisements.push(ECompanyAdvertisement.ARBEITSAMT);
    } else {
      newEnabledAdvertisements = newEnabledAdvertisements.filter(
        (ad) => ad !== ECompanyAdvertisement.ARBEITSAMT
      );
    }
    setInnerAdvertisements(newEnabledAdvertisements);
    await dispatch.companyInfo.updateCompanyInfoAction({
      info: {
        ...company,
        enabledAdvertisements: newEnabledAdvertisements,
      },
    });
  };

  return (
    <ContentLayout title={t("Settings")}>
      <PasswordChangeModal open={passwordChangeModalOpen} setOpen={setPasswordChangeModalOpen} />
      <Row gutter={[16, 16]}>
        <Col>
          <Card style={cardStyle} title={t("User")}>
            <Space direction="vertical" size="large">
              <Meta
                avatar={
                  <UserOutlined
                    style={{
                      fontSize: "24px",
                    }}
                  />
                }
                title={fullName}
                description={<a href={`mailto:${email}`}>{email}</a>}
              />

              <Button type="primary" onClick={handleChangePasswordClick}>
                {t("Change Password")}
              </Button>
            </Space>
          </Card>
        </Col>
        <Col>
          <Card style={cardStyle} title={t("Language")}>
            <LanguagePicker />
          </Card>
        </Col>
        <Col>
          <Card style={cardStyle} title={t("Logout")}>
            <Button type="primary" danger onClick={handleOnLogout}>
              {t("Logout")}
            </Button>
          </Card>
        </Col>
        <Col>
          <Card style={cardStyle} title={t("arbeitsamt-setting-title")}>
            <Checkbox
              checked={innerAdvertisements.includes(ECompanyAdvertisement.ARBEITSAMT)}
              onChange={onChangeAdCheckbox}
            >
              {t("arbeitsamt-setting-description")}
            </Checkbox>
          </Card>
        </Col>
      </Row>
      <Divider />
      <CompanyIntegrationSettings />
    </ContentLayout>
  );
};
