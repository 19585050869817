import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CrafthuntChat } from "@app/components/ui/CrafthuntChat";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { isProd } from "@app/constants/env";
import { firebaseConfig } from "@app/firebaseConfig";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { RootState } from "@app/store/store";

export const ChatTab = ({ candidateId }: { candidateId: string }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const parsedToken = useSelector((state: RootState) => state.auth.parsedToken);
  const parsedSuperToken = useSelector((state: RootState) => state.superAdmin.parsedSuperToken);
  const { i18n } = useTranslation();
  const currentTranslations = i18n.getDataByLanguage(i18n.language);

  if (!token || !currentTranslations?.translation) {
    return <LoadingSpinner />;
  }
  if (
    parsedToken?.isSuperAdmin &&
    parsedToken?.isInternal &&
    parsedSuperToken &&
    parsedSuperToken?.email === parsedToken?.email &&
    parsedSuperToken?.company.id !== parsedToken?.company.id
  ) {
    return <Alert message="This company doesn't have a user, so there are no chats" />;
  }

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <CrafthuntChat
        isStaging={!isProd()}
        jwt={token}
        firebaseConfig={firebaseConfig}
        translations={currentTranslations.translation}
        candidateId={candidateId}
        hideLeftSidebar
        hideRightSidebarDefault
        showOnlySettingsRightSidebar
        lang={convertLangForBackend(i18n.language)}
      />
    </div>
  );
};
