import {
  ClockCircleOutlined,
  SolutionOutlined,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCandidates } from "@app/api/candidates.api";
import { useJobs } from "@app/api/job-ads.api";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { ECandidateStatus } from "@app/types/candidate.types";
import { EJobAdStatus } from "@app/types/job-ads.types";

export const DashboardStats = () => {
  const { t } = useTranslation();

  const jobsQuery = useJobs({
    status: EJobAdStatus.PUBLISHED,
    limit: 1,
    offset: 0,
  });
  const pendingCandidatesQuery = useCandidates({
    status: ECandidateStatus.PENDING,
    limit: 1,
    offset: 0,
  });
  const recommendedCandidatesQuery = useCandidates({
    status: ECandidateStatus.RECOMMENDED,
    limit: 1,
    offset: 0,
  });
  const allCandidatesQuery = useCandidates({
    limit: 1,
    offset: 0,
  });

  const totalPublishedJobs = jobsQuery.data?.count || 0;
  const totalPendingCandidates = pendingCandidatesQuery.data?.count || 0;
  const totalRecommendedCandidates = recommendedCandidatesQuery.data?.count || 0;
  const totalCandidates = allCandidatesQuery.data?.count || 0;

  return (
    <Row gutter={[40, 40]} justify="space-between">
      <Col span={12} lg={6}>
        <Link to={RoutePaths.JOB_ADS}>
          <Card
            title={t("total-jobs-stat-title")}
            bordered={false}
            extra={<SolutionOutlined style={{ fontSize: "22px" }} />}
            hoverable
          >
            <Row justify="space-between" align="bottom">
              <Typography.Title level={2} style={{ margin: 0 }}>
                {totalPublishedJobs}
              </Typography.Title>
              <Typography.Text type="secondary">{t("total-jobs-stat-description")}</Typography.Text>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col span={12} lg={6}>
        <Link to={RoutePaths.CANDIDATES}>
          <Card
            title={t("all-applications-stat-title")}
            bordered={false}
            extra={<UserOutlined style={{ fontSize: "22px" }} />}
            hoverable
          >
            <Row justify="space-between" align="bottom">
              <Typography.Title level={2} style={{ margin: 0 }}>
                {totalCandidates}
              </Typography.Title>
              <Typography.Text type="secondary">
                {t("all-applications-stat-description")}
              </Typography.Text>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col span={12} lg={6}>
        <Link to={RoutePaths.CANDIDATES}>
          <Card
            title={t("pending-applications-stat-title")}
            bordered={false}
            extra={<ClockCircleOutlined style={{ fontSize: "22px" }} />}
            hoverable
          >
            <Row justify="space-between" align="bottom">
              <Typography.Title level={2} style={{ margin: 0 }}>
                {totalPendingCandidates}
              </Typography.Title>
              <Typography.Text type="secondary">
                {t("pending-applications-stat-description")}
              </Typography.Text>
            </Row>
          </Card>
        </Link>
      </Col>
      <Col span={12} lg={6}>
        <Link to={RoutePaths.CANDIDATES}>
          <Card
            title={t("recommended-applications-stat-title")}
            bordered={false}
            extra={<StarOutlined style={{ fontSize: "22px" }} />}
            hoverable
          >
            <Row justify="space-between" align="bottom">
              <Typography.Title level={2} style={{ margin: 0 }}>
                {totalRecommendedCandidates}
              </Typography.Title>
              <Typography.Text type="secondary">
                {t("recommended-applications-stat-description")}
              </Typography.Text>
            </Row>
          </Card>
        </Link>
      </Col>
    </Row>
  );
};
