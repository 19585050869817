import { WarningOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import moment from "moment";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactMoment from "react-moment";
import { DateFormats } from "@app/constants/date.constants";
import { CandidateDef, ECandidateMethod, ECandidateStatus } from "@app/types/candidate.types";
import styles from "./CandidateUrgencyTag.module.scss";

type Props = {
  candidate: CandidateDef;
  showTooltip?: boolean;
};

export const CandidateUrgencyTag = memo(({ candidate, showTooltip }: Props) => {
  const { t } = useTranslation();

  const isInRecommendedStage =
    candidate.companyCandidateStatus?.status === ECandidateStatus.RECOMMENDED;
  const isActiveSourcedCandidate = candidate.method === ECandidateMethod.CONTACTED;
  const createdAtTextPrefix = isInRecommendedStage
    ? t("recommended-date-prefix")
    : isActiveSourcedCandidate
    ? t("contacted-date-prefix")
    : t("applied-date-prefix");

  const isRecommendedOrPending =
    isInRecommendedStage || candidate.companyCandidateStatus?.status === ECandidateStatus.PENDING;

  const dayDiff = useMemo(
    () => Math.abs(moment(candidate.createdAt).diff(new Date(), "days")),
    [candidate.createdAt]
  );

  const content = (
    <Tag
      className={styles.tag}
      icon={isRecommendedOrPending && dayDiff >= 28 && <WarningOutlined />}
      color={!isRecommendedOrPending || dayDiff < 7 ? "" : dayDiff < 14 ? "gold" : "orange"}
    >
      {createdAtTextPrefix}:{" "}
      <ReactMoment fromNow className={styles.capitalize}>
        {candidate.createdAt}
      </ReactMoment>
    </Tag>
  );

  return showTooltip ? (
    <Tooltip title={moment(candidate.createdAt).format(DateFormats.FULL_DATE_TIME)}>
      {content}
    </Tooltip>
  ) : (
    content
  );
});
