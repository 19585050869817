import { App, Col, Input, Row } from "antd";
import capitalize from "lodash/capitalize";
import uniq from "lodash/uniq";
import { useMemo, useState } from "react";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { useAdminsSA } from "@app/features/super-admin/api/super-admin.admin.api";
import {
  EUserProgressType,
  ONLY_UNASSIGNED_VALUE,
} from "@app/features/super-admin/api/super-admin.progress.api";
import { useTasksSA } from "@app/features/super-admin/api/super-admin.task.api";
import { extractCraftsmenIdFromUrl } from "@app/features/super-admin/helpers/super-admin.helper";

const progressTypeOptions: { value: EUserProgressType; label: string }[] = [
  {
    value: EUserProgressType.ACTIVE,
    label: "Active users",
  },
  {
    value: EUserProgressType.ACTIVE_WITHOUT_APPLICATIONS,
    label: "Without applications",
  },
  {
    value: EUserProgressType.WAITING_FOR_ACTION,
    label: "Users marked as done",
  },
  {
    value: EUserProgressType.NEED_APPROVAL,
    label: "Need approval",
  },
  {
    value: EUserProgressType.APPLIED_TO_UNPUBLISHED_JOB,
    label: "Applied to unpublished job",
  },
  {
    value: EUserProgressType.ALL,
    label: "All users",
  },
];

export const Filters = ({
  keyword,
  setKeyword,
  selectedTaskId,
  setSelectedTaskId,
  selectedAdminIds,
  setSelectedAdminIds,
  selectedProgressType,
  setSelectedProgressType,
}: {
  keyword: string;
  setKeyword: (value: string) => void;
  selectedTaskId?: string;
  setSelectedTaskId: (value?: string) => void;
  selectedAdminIds: string[];
  setSelectedAdminIds: (value: string[]) => void;
  selectedProgressType: EUserProgressType;
  setSelectedProgressType: (value: EUserProgressType) => void;
}) => {
  const app = App.useApp();
  const tasksQuery = useTasksSA();
  const adminsQuery = useAdminsSA();

  const [keywordValue, setKeywordValue] = useState(keyword);

  const trimmedKeyword = keyword.trim();
  const isKeywordSearchActive = trimmedKeyword.length > 0;

  const taskOptions = useMemo(() => {
    const goals = uniq(tasksQuery.data?.map((task) => task.goal));

    return goals.map((goal) => ({
      label: capitalize(goal.replace(/_/g, " ")),
      options:
        tasksQuery.data
          ?.filter((task) => task.goal === goal)
          .map((task) => ({
            value: task.id,
            label: task.name,
          })) ?? [],
    }));
  }, [tasksQuery.data]);

  return (
    <Row gutter={[16, 16]} align="middle">
      <Col flex="520px">
        <Input.Search
          allowClear
          placeholder={"Search for user id, name, email, phone or slug"}
          enterButton={"Search"}
          size="large"
          value={keywordValue}
          onChange={(e) => setKeywordValue(e.target.value)}
          onSearch={(value) => {
            setKeyword(value);
          }}
          onPaste={(e) => {
            const slug = extractCraftsmenIdFromUrl(e.clipboardData.getData("text"));

            if (slug) {
              e.preventDefault();
              setKeywordValue(slug);
              setKeyword(slug);
              app.message.success({ content: "Slug was extracted from link" });
            }
          }}
        />
      </Col>
      <Col
        flex="350px"
        style={{
          maxWidth: "350px",
        }}
      >
        <SelectAutocomplete
          allowClear
          disabled={isKeywordSearchActive}
          sortAlphabetically={false}
          loading={tasksQuery.isLoading}
          value={selectedTaskId}
          placeholder="Search by task"
          popupMatchSelectWidth
          options={taskOptions}
          onChange={(value) => {
            setSelectedTaskId(value);
          }}
        />
      </Col>
      <Col flex="200px">
        <SelectAutocomplete
          allowClear
          mode="multiple"
          disabled={isKeywordSearchActive}
          loading={adminsQuery.isLoading}
          value={selectedAdminIds}
          placeholder="Assigned Admin"
          maxTagCount="responsive"
          options={[
            ...(adminsQuery.data?.map((admin) => ({
              value: admin.id,
              label: admin.firstName,
            })) ?? []),
            { value: ONLY_UNASSIGNED_VALUE, label: "Unassigned" },
          ]}
          onChange={(value) => {
            setSelectedAdminIds(value);
          }}
        />
      </Col>
      <Col flex="180px">
        <SelectAutocomplete
          disabled={isKeywordSearchActive}
          placeholder="Progress Type"
          sortAlphabetically={false}
          value={selectedProgressType}
          options={progressTypeOptions}
          onChange={(value) => {
            setSelectedProgressType(value);
          }}
        />
      </Col>
    </Row>
  );
};
