import { CheckOutlined, CloseOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  Card,
  App,
} from "antd";
import { Rule } from "antd/lib/form";
import { FormInstance, useWatch } from "antd/lib/form/Form";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { MomentDatePicker } from "@app/components/ui/DatePicker";
import { LoadingSpinner } from "@app/components/ui/LoadingSpinner/LoadingSpinner";
import { PhoneNumberInput } from "@app/components/ui/PhoneNumberInput/PhoneNumberInput";
import { SelectAutocomplete } from "@app/components/ui/SelectAutocomplete/SelectAutocomplete";
import { SimpleAddressPicker } from "@app/components/ui/SimpleAddressPicker/SimpleAddressPicker";
import { DateFormats } from "@app/constants/date.constants";
import { ENV } from "@app/constants/env";
import { MagicLinkButton } from "@app/features/super-admin/components/SuperAdminStuff/components/Users/components/MagicLinkButton";
import { mapUserToApi } from "@app/features/super-admin/helpers/super-admin.helper";
import { UserFormDef } from "@app/features/super-admin/types/super-admin.user.types";
import { triggerAnalyticsEvent } from "@app/helpers/analytics.helper";
import { ApiFormErrorDef, getErrorMessages } from "@app/helpers/error.helper";
import { convertLangForBackend } from "@app/helpers/language.helper";
import { useCopy } from "@app/hooks/useCopy";
import { RootState, store } from "@app/store/store";
import { ExternalLinkTypeEnum } from "@app/types/external-link.types";
import { UserFiles } from "./components/UserFiles/UserFiles";
import { UserJobAvailability } from "./components/UserJobAvailability/UserJobAvailability";

type UserInfoFormProps = {
  form: FormInstance<UserFormDef>;
  selectedUser?: UserFormDef;
  renderButtons?: boolean;
  changeCandidateSettings?: () => void;
  onSave: () => void;
};

export const UserInfoForm = ({
  form,
  selectedUser,
  renderButtons,
  changeCandidateSettings,
  onSave,
}: UserInfoFormProps) => {
  const { t, i18n } = useTranslation();
  const { message } = App.useApp();
  const { copyToClipboard } = useCopy();
  const { dispatch } = store;
  const jobTitles = useSelector((state: RootState) => state.tags.jobTitles);
  const availableTradesTags = useSelector((state: RootState) => state.tags.trades);
  const availableIndustriesTags = useSelector((state: RootState) => state.tags.industries);
  // loading states
  const loading = useSelector((state: RootState) => state.loading.effects.superAdmin.updateUserSA);
  // data
  const emailValue = useWatch("email", form);
  const phoneValue = useWatch("phone", form);
  const medias = useWatch("medias", form);
  const roleRequired = Boolean(selectedUser?.jobTitleId);
  const tradesRequired = Boolean(selectedUser && selectedUser.profileTradesId.length !== 0);
  const industriesRequired = Boolean(selectedUser && selectedUser.profileIndustriesId.length !== 0);
  // choices
  const jobTitleChoices = jobTitles.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const tradesChoices = availableTradesTags.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const industriesChoices = availableIndustriesTags.map((i) => ({
    value: i.id,
    label: t(i.name),
  }));
  const requiredRule: Rule = { required: true, whitespace: true, message: t("required") };
  const deletedUser = Boolean(selectedUser?.deletedAt);
  const canEditPhone = !deletedUser && !selectedUser?.phone;
  const canEditEmail = !deletedUser && !selectedUser?.email;

  const onSubmitForm = async (values: UserFormDef) => {
    if (selectedUser) {
      try {
        const userWasReviewed = selectedUser.isReviewed;
        const isReviewed = values.isReviewed;
        const originalNumberOfDocuments = selectedUser.medias?.length || 0;
        const numberOfDocuments = values.medias?.length || 0;
        await dispatch.superAdmin.updateUserSA({
          ...mapUserToApi(values),
          id: selectedUser.id,
          lang: convertLangForBackend(i18n.language),
          email: canEditEmail ? values.email : undefined,
          phone: canEditPhone ? values.phone : undefined,
        });

        // Events
        if (userWasReviewed && !isReviewed) {
          triggerAnalyticsEvent("user_unreviewed", { userId: selectedUser.id });
        } else if (!userWasReviewed && isReviewed) {
          triggerAnalyticsEvent("user_reviewed", { userId: selectedUser.id });
        }
        if (numberOfDocuments > originalNumberOfDocuments) {
          triggerAnalyticsEvent("user_upload_document_adminpanel", { userId: selectedUser.id });
        }

        onSave();
        message.success({ content: t("Saved!"), key: "user-info" });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          message.error({
            content: getErrorMessages(error as ApiFormErrorDef),
            key: "user-info",
            duration: 10,
          });
        }
      }
    }
  };

  const onFormFailed = () => {
    message.error({ content: t("Please fix invalid fields"), key: "user-info" });
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      labelAlign="right"
      form={form}
      initialValues={selectedUser}
      onFinish={onSubmitForm}
      onFinishFailed={onFormFailed}
      data-hs-do-not-collect="true"
      disabled={deletedUser}
    >
      {loading && <LoadingSpinner />}
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Space split={<Divider type="vertical" />}>
            {selectedUser && (
              <MagicLinkButton style={{ padding: 0 }} userId={selectedUser.id}>
                Magic link
              </MagicLinkButton>
            )}
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => copyToClipboard(`${ENV.WEB_URL}/craftsmen/${selectedUser?.slug}`)}
            >
              Profile link
            </Button>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() =>
                copyToClipboard(`${ENV.WEB_URL}/craftsmen/${selectedUser?.anonymousSlug}`)
              }
            >
              Anonymous link
            </Button>
          </Space>
        </Col>
        <Col xs={12}>
          <Form.Item label={t("First name")} name={"firstName"} rules={[requiredRule]}>
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={t("Last name")}
            name={"lastName"}
            rules={[{ ...requiredRule, whitespace: false }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={t("Email")}
            name={"email"}
            help={!canEditEmail ? "Contact tech team if you want to change" : ""}
            rules={[
              {
                type: "email",
                required: !phoneValue,
                whitespace: true,
                message: t("Required"),
              },
            ]}
          >
            <Input size="large" disabled={!canEditEmail} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            label={t("Mobile phone")}
            name={"phone"}
            help={!canEditPhone ? "Contact tech team if you want to change" : ""}
            rules={[
              {
                type: "string",
                required: !emailValue,
                whitespace: true,
                message: t("Required"),
              },
              {
                validator: (_, value) => {
                  if (value && !isPossiblePhoneNumber(value)) {
                    return Promise.reject(t("Not valid phone number"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneNumberInput placeholder={t("Enter phone number")} disabled={!canEditPhone} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            label={"Indeed Emails"}
            name={"indeedEmails"}
            help={"Contact tech team if you want to change"}
          >
            <Select mode="multiple" size="large" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            rules={roleRequired ? [requiredRule] : undefined}
            label={t("Role")}
            name="jobTitleId"
            required={roleRequired}
          >
            <SelectAutocomplete placeholder={t("Select")} options={jobTitleChoices} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            rules={tradesRequired ? [{ ...requiredRule, type: "array" as const }] : undefined}
            label={t("Trades")}
            name="profileTradesId"
            required={tradesRequired}
          >
            <SelectAutocomplete mode="multiple" placeholder={t("Select")} options={tradesChoices} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            rules={industriesRequired ? [{ ...requiredRule, type: "array" as const }] : undefined}
            label={t("Industries")}
            name="profileIndustriesId"
            required={industriesRequired}
          >
            <SelectAutocomplete
              mode="multiple"
              placeholder={t("Select")}
              options={industriesChoices}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Birthday")} name="birthday">
            <MomentDatePicker
              size="large"
              format={DateFormats.D_M_Y}
              allowClear
              placeholder={t("DD/MM/YYYY")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("About me")} name={"description"}>
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 4, maxRows: 8 }}
              style={{ width: "100%" }}
              placeholder={t("Enter...")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Current Address")} name="address">
            <SimpleAddressPicker />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("Looking for Work - Address")} name="geoSearchAddress">
            <SimpleAddressPicker needsCity={false} />
          </Form.Item>
        </Col>
        <Divider />
        <Form.Item
          label={t("job-availability-title")}
          name="jobAvailability"
          help="Turning this off will stop the user from receiving scheduled job recommendations per email/phone/whatsapp."
        >
          <UserJobAvailability />
        </Form.Item>
        <Divider />
        <Col span={24}>
          <Form.Item
            label={t("Anonymous")}
            name={"isAnonymous"}
            valuePropName="checked"
            help={t(
              "This determines whether the user's data is anonymized (turned on) or revealed (turned off)."
            )}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            label={t("Verified")}
            name={"isVerified"}
            valuePropName="checked"
            help={t(
              "This will show a 'Verified' badge on the user when turned on. This will also automatically turn 'Reviewed' on or off."
            )}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(checked) => form.setFieldValue("isReviewed", checked)}
            />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Form.Item
            label={t("Reviewed")}
            name={"isReviewed"}
            valuePropName="checked"
            help={t(
              "This determines whether the user is shown in explore page and will see jobs (turned on), or hidden in explore page and no jobs (turned off)."
            )}
          >
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24}>
          <Typography.Title level={5}>{t("Internal stuff")}</Typography.Title>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t("Comment")}
            name={"internalComment"}
            help={t("Internal stuff, only shown for us")}
          >
            <Input.TextArea
              size="large"
              autoSize={{ minRows: 4, maxRows: 8 }}
              style={{ width: "100%" }}
              placeholder={t("Enter...")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Referral Link" name="referralLink">
            <Input disabled />
          </Form.Item>
        </Col>
        {selectedUser && (
          <Col xs={24}>
            <UserFiles user={selectedUser} medias={medias} />
          </Col>
        )}
        <Col span={24} md={12}>
          <Form.Item label={t("External Links")} name={"externalLinks"}>
            <Form.List name="externalLinks">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[{ required: true, whitespace: true, message: "Missing link" }]}
                      >
                        <Input placeholder="Link" size="large" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        rules={[{ required: true, message: "Missing type" }]}
                      >
                        <SelectAutocomplete
                          placeholder={t("Type")}
                          options={Object.values(ExternalLinkTypeEnum).map((item) => ({
                            value: item,
                            label: item,
                          }))}
                          sortAlphabetically={false}
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t("Add")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </Col>
      </Row>
      {renderButtons && changeCandidateSettings && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            pointerEvents: "none",
          }}
        >
          <Card
            size="small"
            style={{
              pointerEvents: "auto",
            }}
          >
            <Space>
              <Button
                type="default"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
              <Button onClick={changeCandidateSettings} type="primary">
                Save
              </Button>
            </Space>
          </Card>
        </div>
      )}
    </Form>
  );
};
