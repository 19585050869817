import { InputStatus } from "antd/lib/_util/statusUtils";
import { AddressDef, CoordinateDef } from "./address.types";
import { ExternalLinkDef } from "./external-link.types";

export interface CompanyInfoType {
  id: string;
  name: string;
  description: string;
  address: AddressDef;
  coverImage: string | null;
  coordinates: CoordinateDef;
  logo: string | null;
  approvalStatus: ApprovalStatusEnum;
  hasPaidForJobAds: boolean;
  hasIntegrations: boolean;
  lang: string;
  companyIndustriesId: string[];
  enabledAdvertisements: ECompanyAdvertisement[];
  embeddedCrafthuntLinkStatus: EEmbeddedCrafthuntLinkStatus;
  externalLinks: ExternalLinkDef[];
}

export type InfoFieldsStatusType = Record<string, InputStatus>;
export interface UpdateApprovalStatus {
  id: string;
  approvalStatus: ApprovalStatusEnum;
}

export enum ApprovalStatusEnum {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  NOT_STARTED = "not-started",
}

export enum ECompanyAdvertisement {
  EBAY = "ebay-kleinanzeigen",
  STEPSTONE = "stepstone",
  INDEED = "indeed",
  CURATO = "curato",
  OLX = "olx",
  TIKTOK = "tiktok",
  GOOGLE_JOBS = "google-jobs",
  META = "meta",
  ARBEITSAMT = "arbeitsamt",
}

export enum EEmbeddedCrafthuntLinkStatus {
  NONE = "none",
  HAS_CRAFTHUNT_LINK = "has-crafthunt-link",
  HAS_CRAFTHUNT_COMPANY_LINK = "has-crafthunt-company-link",
}

export interface ApiCompanyInfoResponse {
  id: string;
  slug: string;
  name: string;
  description: string;
  lang: string;
  image: string | null;
  coverImage: string | null;
  address: AddressDef | null;
  coordinate: CoordinateDef | null;
  approvalStatus: ApprovalStatusEnum;
  hasPaidForJobAds: boolean;
  hasIntegrations: boolean;
  companyIndustriesId: string[];
  enabledAdvertisements: ECompanyAdvertisement[];
  embeddedCrafthuntLinkStatus: EEmbeddedCrafthuntLinkStatus;
  externalLinks: ExternalLinkDef[];
  createdAt: string;
  updatedAt: string;
}

export interface CompanyBioInputType {
  type: string;
  sourceLang: string;
}

export interface GenerateCompanyBioResponse {
  data: string;
}
