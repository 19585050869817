import {
  CarOutlined,
  EnvironmentOutlined,
  ShareAltOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, theme } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RoutePaths } from "@app/features/routes/types/routes.types";
import { useCopy } from "@app/hooks/useCopy";
import { useLiveURL } from "@app/hooks/useLiveUrl";

export const QuickActionsCard = () => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const { copyToClipboard } = useCopy();
  const liveURL = useLiveURL();

  return (
    <Card title={t("quick-actions-title")} bordered={false}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Link to={RoutePaths.JOB_AD_NEW}>
            <Card hoverable style={{ textAlign: "center" }}>
              <SolutionOutlined style={{ fontSize: "28px", color: token.colorPrimary }} />
              <h3>{t("create-job-action")}</h3>
            </Card>
          </Link>
        </Col>
        <Col span={12}>
          <Card hoverable style={{ textAlign: "center" }} onClick={() => copyToClipboard(liveURL)}>
            <ShareAltOutlined style={{ fontSize: "28px", color: token.colorPrimary }} />
            <h3>{t("share-action")}</h3>
          </Card>
        </Col>
        <Col span={12}>
          <Link to={RoutePaths.CONSTRUCTION_PROJECTS}>
            <Card hoverable style={{ textAlign: "center" }}>
              <EnvironmentOutlined style={{ fontSize: "28px", color: token.colorPrimary }} />
              <h3>{t("create-project-action")}</h3>
            </Card>
          </Link>
        </Col>
        <Col span={12}>
          <Link to={RoutePaths.EQUIPMENT_AND_MACHINERY}>
            <Card hoverable style={{ textAlign: "center" }}>
              <CarOutlined style={{ fontSize: "28px", color: token.colorPrimary }} />
              <h3>{t("create-equipment-action")}</h3>
            </Card>
          </Link>
        </Col>
      </Row>
    </Card>
  );
};
