import { Checkbox, Form } from "antd";
import { ECompanyAdvertisement } from "@app/types/company-info.types";

export const CompanyAdvertisements = () => {
  return (
    <>
      {Object.values(ECompanyAdvertisement).map((ad) => (
        <Form.Item
          key={ad}
          name={["enabledAdvertisements", ad]}
          valuePropName="checked"
          style={{ marginBottom: 8 }}
        >
          <Checkbox>{ad}</Checkbox>
        </Form.Item>
      ))}
    </>
  );
};
