import { UserStatsDef } from "@app/features/super-admin/types/super-admin.user.types";
import { PaginationRequestDef, PaginationResponseDef } from "./pagination.types";
import { ETagCategory, TradeTagDef } from "./tags.types";

export enum ECandidateStatus {
  NEEDS_APPROVAL = "needs-approval",
  RECOMMENDED = "recommended",
  PENDING = "pending",
  ACCEPTED = "accepted",
  CUSTOM = "custom",
  HIRED = "hired",
  REJECTED = "rejected",
}

export enum ECandidateMethod {
  APPLIED = "applied",
  CONTACTED = "contacted",
}

export const ECandidateRejectionReason = {
  NOT_QUALIFIED: "rejection-reason-not-qualified",
  GERMAN_NOT_GOOD: "rejection-reason-german-not-good",
  NOT_CONTACTABLE: "rejection-reason-not-contactable",
  CANDIDATE_DECLINED: "rejection-reason-candidate-declined",
  LOCATION_NOT_FIT: "rejection-reason-location-not-fit",
  NOT_FIT_JOB_REQUIREMENTS: "rejection-reason-not-fit-job-requirements",
  OTHER: "rejection-reason-other",
};
export type ECandidateRejectionReason =
  (typeof ECandidateRejectionReason)[keyof typeof ECandidateRejectionReason];

export interface IGeoSearchAddress {
  country: string;
  /**
   * @format ISO-3166 Alpha-2
   * @example DE represent Germany
   */
  countryCode?: string;
  city?: string;
  /**
   * @format ISO-3166 Alpha-2
   * @example BE represent Berlin
   */
  cityCode?: string;
  street?: string;
  postalCode?: string;
  region?: string;
  adminRegion?: string;
  fullName?: string;
  lng?: number;
  lat?: number;
  isEU?: boolean;
}

export interface IProfile {
  id: string;
  jobTitleId: string | null;
  geoSearchAddress: IGeoSearchAddress | null;
  profileTrades: TradeTagDef[];
  isAnonymous: boolean;
  firstName: string;
  lastName: string;
  picture: string | null;
  slug: string | null;
  anonymousSlug: string | null;
}

export interface IReviewedByUser {
  id: string;
  firstName: string;
  lastName: string;
  picture: string | null;
}

export enum DocumentTypeEnum {
  CV = "cv",
  VISA = "visa",
  IMAGE = "image",
}

export interface ProfileDocumentDef {
  id: string;
  url: string;
  filename: string;
  type: DocumentTypeEnum;
  createdAt: string;
}

export interface CompanyCandidateStatusDef {
  id: string;
  name: string;
  status: ECandidateStatus;
  type: ECandidateStatusType;
}

export interface KanbanSettingsFormDef {
  statuses: CompanyCandidateStatusDef[];
}

export type CandidateDef = {
  id: string;
  internalNote: string;
  status: ECandidateStatus;
  companyCandidateStatus: CompanyCandidateStatusDef;
  method: ECandidateMethod;
  workerProfileId: string;
  reviewedById: string;
  crafthuntCompanyId: string;
  revealIdentity: boolean;
  createdAt: string;
  updatedAt: string;
  reviewedBy: {
    id: string;
    firstName: string;
    lastName: string | null;
    picture: string | null;
  };
  workerProfile: {
    id: string;
    jobTitle?: {
      id: string;
      name: string;
      category: ETagCategory;
      categoryId: string;
    };
    geoSearchAddress: {
      lat: number;
      lng: number;
      city: string;
      radius: number;
      country: string;
    };
    address: {
      id: string;
      postalCode: string | null;
      country: string;
      countryCode: string;
      city: string;
      region: string | null;
      municipality: string | null;
      isEU: boolean;
    };
    profileTrades: {
      id: string;
      name: string;
    }[];
    medias: ProfileDocumentDef[];
    isAnonymous: boolean;
    isVerified: boolean;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    picture: string | null;
    slug: string | null;
    anonymousSlug: string;
    stats: UserStatsDef;
    deletedAt: string | null;
  };
  jobAds: {
    id: string;
    title: string | null;
    title_original: string;
    slug: string;
    fullSlug: string;
  }[];
  jobQuestionsCount: number;
  jobQuestionAnswersCount: number;
  isCrafthuntRecommended?: boolean;
  candidateApprovals: {
    id: string;
    description: string;
    action: ECandidateApprovalAction;
    createdAt: string;
  }[];
  AIDescriptionSummary?: {
    "con-arguments"?: string[];
    "pro-arguments"?: string[];
    job_match_confidence?: number;
    prime_candidate_score?: number;
    is_recommended_candidate?: boolean;
    prime_candidate_score_reason?: string;
  } | null;
};

export type UpdateCandidateDef = {
  internalNote?: string | null;
  companyCandidateStatusId?: string | null;
  rejectionMessage?: string | null;
  rejectionReason?: ECandidateRejectionReason | null;
  rejectionReasonDescription?: string | null;
  handleRejection?: boolean;
  jobAdsId?: string[] | null;
};

export type CandidatesRequestDef = PaginationRequestDef & {
  jobAdIds?: string[];
  status?: ECandidateStatus;
  companyCandidateStatusId?: string;
  method?: ECandidateMethod;
};
export type CandidatesResponseDef = PaginationResponseDef<CandidateDef>;

export type CandidateRejectionFormDef = {
  rejectionMessage?: string;
  rejectionReason?: ECandidateRejectionReason;
  rejectionReasonDescription?: string;
};

export enum ECandidateApprovalAction {
  APPROVE = "approve",
  REJECT = "reject",
}

export enum ECandidateStatusType {
  INTERVIEW = "interview",
  ASSESSMENT = "assessment",
  CALL = "call",
  TRIAL_WORK = "trial-work",
  OTHER = "other",
}
